import {
  DeleteOutlined as DeleteOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
} from "@mui/icons-material";
import { Button, TableCell, TableRow } from "@mui/material";
import { format } from "date-fns";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { PublishedShowcase } from "../../../../../components";
import { useGlobalStyles } from "../../../../../utils/theme";
import { IMapPin } from "../../../../../apollo/queries";
// import { useStyles } from "./ClassicTourItem.styles";

interface IProps {
  index: number;
  data: IMapPin;
  handleDelete: () => void;
}

export const MapPinItem: FC<IProps> = (props) => {
  const { id, locale, created, published, mapId, label } = props.data;
  const { handleDelete, index } = props;

  // const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();

  const getMapName = () => {
    switch (mapId) {
      case "0": {
        return "Podrum";
      }
      case "1": {
        return "Prizemlje";
      }
      case "2": {
        return "Prvi sprat";
      }
      case "3": {
        return "Drugi sprat";
      }
      case "4": {
        return "Treći sprat";
      }
      case "5": {
        return "Četvrti sprat";
      }
      case "6": {
        return "Peti sprat";
      }
      default: {
        return "Not specified";
      }
    }
  };

  const getLabelName = () => {
    switch (label) {
      case "WC": {
        return "WC";
      }
      case "LIFT": {
        return "LIFT";
      }
      case "WARDROBE": {
        return "GARDEROBA";
      }
      case "TICKET_OFFICE": {
        return "BLAGAJNA";
      }
      case "LIFT_ACCESSABILITY": {
        return "PRISTUP ZA INVALIDE";
      }
      case "EASTER_EGG": {
        return "SKRIVENO IZNENAĐENJE";
      }

      default: {
        return label;
      }
    }
  };

  return (
    <TableRow onClick={() => {}}>
      <TableCell>{index}</TableCell>
      <TableCell>{getMapName()}</TableCell>
      <TableCell>
        <strong>{locale?.name}</strong>
      </TableCell>
      <TableCell>{getLabelName()}</TableCell>
      <TableCell>{format(new Date(created), "dd.MM.yyyy. HH:mm'h'")}</TableCell>
      <TableCell>
        <PublishedShowcase published={published} />
      </TableCell>

      <TableCell>
        <div className={globalClasses.tableActionButtons}>
          <NavLink to={id} className={globalClasses.disableNavlinkDecoration}>
            <Button startIcon={<EditOutlinedIcon />} size="small">
              Uredi
            </Button>
          </NavLink>
          <Button
            startIcon={<DeleteOutlinedIcon />}
            onClick={handleDelete}
            size="small"
          >
            Obriši
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};
