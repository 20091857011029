import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { lighten } from "@mui/material";
import createPalette from "@mui/material/styles/createPalette";

declare module "@mui/material/styles" {
  interface PaletteColor {
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
  }
}

const palette = createPalette({
  primary: {
    dark: "#091F40",
    main: "#162585",
    lighter: lighten("#6D79E0", 0.95),
  },
  secondary: {
    main: "#F66871",
    lighter: lighten("#F66871", 0.95),
  },
  info: {
    main: "#1671C6",
  },
  background: {
    default: "#F6F5F4",
  },
  text: {
    primary: "#333333",
    secondary: "#808287",
  },
});

export const theme = responsiveFontSizes(
  createTheme({
    palette: palette,
    //TODO: change?
    typography: {
      fontFamily: ["Atkinson", "Roboto"].join(","),
      button: {
        textTransform: "capitalize",
        fontWeight: "500",
      },
    },

    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            width: 164,
            // backgroundColor: palette.grey[200],
            // "&.Mui-selected": {
            //   background: "#ffffff",
            // },
          },
        },
      },
      //TODO: Figma change?
      MuiTabs: {
        styleOverrides: {
          root: {
            // backgroundColor: palette.grey[200],
            background: "#ffffff",
            marginBottom: 16,
            borderRadius: 4,
          },
        },
      },
      // MuiButton: {
      //   styleOverrides: {
      //     root: {
      //       lineHeight: 0,
      //     },
      //   },
      // },
    },
  })
);

export const useGlobalStyles = makeStyles()((theme) => ({
  //*Paper
  paperRoot: {
    width: 1024,
    minWidth: 600,
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: "max(60vh, 400px)",
    marginBottom: theme.spacing(6),
    maxHeight: `calc(100vh - ${theme.spacing(4)} - ${theme.spacing(12)})`,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("md")]: {
      marginLeft: 64,
      // width: "calc(100% - 96px)",
      width: "calc(100% - 64px)",
      minWidth: "50%",
      maxHeight: `calc(100vh - ${theme.spacing(16)})`,
      minHeight: `calc(100vh - ${theme.spacing(16)})`,
    },
  },
  paperTitle: {
    paddingRight: theme.spacing(3),
  },
  paperContainer: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    overflow: "scroll",
    paddingRight: theme.spacing(3),
    flex: 1,
  },
  paperButtons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  paperButtonsLeft: {
    "& button": {
      marginRight: theme.spacing(1),
    },
  },
  paperButtonsRight: {
    "& button": {
      marginLeft: theme.spacing(1),
    },
  },
  justifySpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  disableNavlinkDecoration: {
    textDecoration: "none",
  },
  centerItems: {
    alignItems: "center",
  },
  fullWidth: {
    width: "100%",
  },
  flex: {
    flex: 1,
  },

  //*Tabs
  tabPanel: {
    padding: `${theme.spacing(1)} 0 ${theme.spacing(2)} 0`,
    flex: 1,
  },

  //*Table
  tableContainer: {
    // marginTop: theme.spacing(2),
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 1em",
    "& th": {
      backgroundColor: theme.palette.background.paper,
    },

    "& tbody": {
      "& > tr:nth-of-type(odd)": {
        backgroundColor: theme.palette.secondary.lighter,
      },
      "& > tr": {
        borderRadius: theme.shape.borderRadius,

        "& > td": {
          borderTopColor: theme.palette.grey[200],
          borderTopWidth: 1,
          borderTopStyle: "solid",
          borderBottomColor: theme.palette.grey[200],
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
        },
        "& > td:first-of-type": {
          borderLeftColor: theme.palette.grey[200],
          borderLeftWidth: 1,
          borderLeftStyle: "solid",
          borderTopLeftRadius: theme.spacing(1),
          borderBottomLeftRadius: theme.spacing(1),
        },
        "& > td:last-of-type": {
          overflow: "hidden",
          borderRightColor: theme.palette.grey[200],
          borderRightWidth: 1,
          borderRightStyle: "solid",
          borderTopRightRadius: theme.spacing(1),
          borderBottomRightRadius: theme.spacing(1),
        },

        "&:hover": {
          backgroundColor: theme.palette.primary.lighter,
        },
      },
    },

    "& td": {
      border: "none",
    },
  },

  //Rows
  //TODO: FIX?
  tableActionButtons: {
    display: "flex",
    justifyContent: "space-between",
  },
  inputSmallFlex: {
    flexBasis: `calc(50% - ${theme.spacing(2)})`,
  },
  flexWrapContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  inputHasChanged: {
    // "& label.Mui-focused": {
    //   color: theme.palette.secondary.main,
    // },

    // "& .MuiInput-underline:after": {
    //   borderBottomColor: "yellow",
    // },
    "& label": {
      color: theme.palette.secondary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.secondary.main,
        // borderColor: "white",
      },
      // "&:hover fieldset": {
      //   borderColor: "white",
      // },
      // "&.Mui-focused fieldset": {
      //   borderColor: "yellow",
      // },
    },
  },
}));
