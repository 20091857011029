import { gql } from "@apollo/client";

export interface IRedeemRewardReception {
  id: string;
}

export interface IRedeemRewardReceptionData {
  redeemRewardReception?: IRedeemRewardReception;
}

export interface IRedeemRewardReceptionVars {
  code: string;
}

export const REDEEM_REWARD_RECEPTION = gql`
  mutation redeemRewardReception($code: String!) {
    redeemRewardReception(code: $code) {
      id
    }
  }
`;
