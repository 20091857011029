import { createTypedDynamicFormInputs } from "../../../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  weekday: {
    inputProps: {
      label: "Dan u nedelji",
      required: true,
      name: "weekDay",
    },
  },
  startHour: {
    inputProps: {
      label: "Vreme otvaranja",
      required: true,
      name: "startHour",
    },
  },
  endHour: {
    inputProps: {
      label: "Vreme zatvaranja",
      required: true,
      name: "endHour",
    },
  },
});
