import { Typography } from "@mui/material";
import { FC } from "react";
import { useStyles } from "./PublishedShowcase.styles";

interface IProps {
  published: boolean;
  sent?: boolean;
  className?: string;
}

export const PublishedShowcase: FC<IProps> = (props) => {
  const { published, className, sent } = props;
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(
        className ? className : "",
        classes.root,
        published ? classes.published : classes.draft
      )}
    >
      <Typography variant="button">
        {sent
          ? published
            ? "Poslato"
            : "Nije poslato"
          : published
          ? "Objavljeno"
          : "Nacrt"}
      </Typography>
    </div>
  );
};
