import { ERoleAccess, ERouterPaths } from "../../utils";
import {
  PermMediaOutlined as PermMediaOutlinedIcon,
  PersonOutline as PersonOutlineIcon,
  SubtitlesOutlined as SubtitlesOutlinedIcon,
  TourOutlined as TourOutlinedIcon,
  SportsEsportsOutlined as SportsEsportsOutlinedIcon,
  NotificationsOutlined as NotificationsOutlinedIcon,
  EmojiEventsOutlined as EmojiEventsOutlinedIcon,
  MapOutlined as MapOutlinedIcon,
  ShowChartOutlined as ShowChartOutlinedIcon,
} from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";

export interface INavbarData {
  id: string;
  title: string;
  route: ERouterPaths;
  icon: OverridableComponent<SvgIconTypeMap>;
  access: ERoleAccess[];
  external?: boolean;
  group: string;
}

export const navbarData: INavbarData[] = [
  {
    id: "0",
    title: "Interaktivne ture",
    route: ERouterPaths.INTERACTIVE_TOURS,
    icon: SportsEsportsOutlinedIcon,
    access: [
      ERoleAccess.SUPER_ADMIN,
      ERoleAccess.OWNER,
      ERoleAccess.MANAGER,
      ERoleAccess.ADMIN,
      ERoleAccess.ADMIN_PLUS_RECEPTION,
    ],
    group: "tours",
  },
  {
    id: "1",
    title: "Audio ture",
    route: ERouterPaths.CLASSIC_TOURS,
    icon: TourOutlinedIcon,
    access: [
      ERoleAccess.SUPER_ADMIN,
      ERoleAccess.OWNER,
      ERoleAccess.MANAGER,
      ERoleAccess.ADMIN,
      ERoleAccess.ADMIN_PLUS_RECEPTION,
    ],
    group: "tours",
  },
  {
    id: "2",
    title: "O nama",
    route: ERouterPaths.ABOUT_US,
    icon: SubtitlesOutlinedIcon,
    access: [ERoleAccess.SUPER_ADMIN, ERoleAccess.OWNER, ERoleAccess.MANAGER],
    group: "tours",
  },
  {
    id: "3",
    title: "Mediji",
    route: ERouterPaths.MEDIA,
    icon: PermMediaOutlinedIcon,
    access: [
      ERoleAccess.SUPER_ADMIN,
      ERoleAccess.OWNER,
      ERoleAccess.MANAGER,
      ERoleAccess.ADMIN,
      ERoleAccess.ADMIN_PLUS_RECEPTION,
    ],
    group: "tours",
  },

  {
    id: "4",
    title: "Obaveštenja",
    route: ERouterPaths.NOTIFICATIONS,
    icon: NotificationsOutlinedIcon,
    access: [ERoleAccess.SUPER_ADMIN, ERoleAccess.OWNER, ERoleAccess.MANAGER],
    group: "other",
  },
  {
    id: "5",
    title: "Recepcija",
    route: ERouterPaths.RECEPTION,
    icon: EmojiEventsOutlinedIcon,
    access: [ERoleAccess.SUPER_ADMIN, ERoleAccess.OWNER, ERoleAccess.MANAGER],
    group: "other",
  },
  {
    id: "6",
    title: "Mape",
    route: ERouterPaths.MAPS,
    icon: MapOutlinedIcon,
    access: [
      ERoleAccess.SUPER_ADMIN,
      ERoleAccess.OWNER,
      ERoleAccess.MANAGER,
      ERoleAccess.ADMIN,
      ERoleAccess.ADMIN_PLUS_RECEPTION,
    ],
    group: "other",
  },
  {
    id: "7",
    title: "Statistika",
    route: ERouterPaths.STATISTICS,
    icon: ShowChartOutlinedIcon,
    access: [
      ERoleAccess.SUPER_ADMIN,
      ERoleAccess.OWNER,
      ERoleAccess.MANAGER,

      ERoleAccess.ADMIN,
    ],
    group: "settings",
  },
  {
    id: "8",
    title: "Administratori",
    route: ERouterPaths.ADMINISTRATORS,
    icon: PersonOutlineIcon,
    access: [ERoleAccess.SUPER_ADMIN, ERoleAccess.OWNER, ERoleAccess.MANAGER],
    group: "settings",
  },
  // {
  //   id: "10",
  //   title: "FAQ",
  //   route: ERouterPaths.FAQ,
  //   icon: HelpOutlineOutlinedIcon,
  //   access: [
  //     ERoleAccess.SUPER_ADMIN,
  //     ERoleAccess.OWNER,
  //     ERoleAccess.MANAGER,

  //     ERoleAccess.ADMIN,
  //   ],
  //   group: "help",
  //   external: true,
  // },
  // {
  //   id: "11",
  //   title: "Guidelines",
  //   route: ERouterPaths.GUIDELINES,
  //   icon: PlagiarismOutlinedIcon,
  //   access: [
  //     ERoleAccess.SUPER_ADMIN,
  //     ERoleAccess.OWNER,
  //     ERoleAccess.MANAGER,

  //     ERoleAccess.ADMIN,
  //   ],
  //   group: "help",
  // },
];
