import { gql } from "@apollo/client";

//*-------Update other
export interface IUpdateMapPinOther {
  id: string;
  label: string | null;
  posX: string | null;
  posY: string | null;
  mapId: string | null;
  skipLegend: boolean | null;
}

export interface IUpdateMapPinOtherData {
  updateMapPinOther: IUpdateMapPinOther;
}
export interface IUpdateMapPinOtherVars {
  id: number;
  data: {
    label: string | null | undefined;
    posX: string | null | undefined;
    posY: string | null | undefined;
    mapId: number | null | undefined;
    skipLegend: boolean | null | undefined;
  };
}

export const UPDATE_MAP_PIN_OTHER = gql`
  mutation updateMapPinOther($id: ID!, $data: MapPinOtherUpdateInput!) {
    updateMapPinOther(id: $id, data: $data) {
      id
      label
      posX
      posY
      mapId
      skipLegend
    }
  }
`;

//*-------Update locale
export interface IUpdateMapPinLocale {
  id: string;
  name: string | null;
}

export interface IUpdateMapPinLocaleData {
  updateMapPinLocale: IUpdateMapPinLocale;
}
export interface IUpdateMapPinLocaleVars {
  id: number;
  data: {
    name: string | undefined | null;
  };
}

export const UPDATE_MAP_PIN_LOCALE = gql`
  mutation updateMapPinLocale($id: ID!, $data: MapPinLocaleUpdateInput!) {
    updateMapPinLocale(id: $id, data: $data) {
      id
      name
    }
  }
`;

//*-------Create
export interface ICreateMapPinLocale {
  id: string;
  name: string | null;

  languageFlag: {
    id: string;
    isoLanguageCode: string;
  };
}

export interface ICreateMapPinLocaleData {
  createMapPinLocale: ICreateMapPinLocale;
}
export interface ICreateMapPinLocaleVars {
  mapPinId: number;
  data: {
    name: string | undefined;
    isoLanguageCode: string;
  };
}

export const CREATE_MAP_PIN_LOCALE = gql`
  mutation createMapPinLocale($mapPinId: ID!, $data: MapPinLocaleCreateInput!) {
    createMapPinLocale(mapPinId: $mapPinId, data: $data) {
      id
      name
      languageFlag {
        id
        isoLanguageCode
      }
    }
  }
`;

//*------ UPDATE PUBLISHED
export interface IUpdateMapPinPublished {
  id: string;
  published: boolean;
}

export interface IUpdateMapPinPublishedData {
  updateMapPinPublished: IUpdateMapPinPublished | null;
}
export interface IUpdateMapPinPublishedVars {
  id: number;
  publish: boolean;
}

export const UPDATE_MAP_PIN_PUBLISHED = gql`
  mutation updateMapPinPublished($id: ID!, $publish: Boolean!) {
    updateMapPinPublished(id: $id, publish: $publish) {
      id
      published
    }
  }
`;
