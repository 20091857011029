import { gql } from "@apollo/client";

//*-------Update locale
export interface IUpdateNotificationLocale {
  id: string;
  title: string | null;
  body: string | undefined;
  links: string | undefined;
  titlePush: string | null;
  subtitlePush: string | null;
  bodyPush: string | null;
  gallery:
    | {
        id: string;
      }[]
    | null;
}

export interface IUpdateNotificationLocaleData {
  updateNotificationLocale: IUpdateNotificationLocale;
}
export interface IUpdateNotificationLocaleVars {
  id: number;
  data: {
    title: string | undefined | null;
    body: string | undefined | null;
    links: string | undefined | null;
    titlePush: string | undefined | null;
    subtitlePush: string | undefined | null;
    bodyPush: string | undefined | null;
    gallery: number[] | undefined | null;
  };
}

export const UPDATE_NOTIFICATION_LOCALE = gql`
  mutation updateNotificationLocale(
    $id: ID!
    $data: NotificationLocaleUpdateInput!
  ) {
    updateNotificationLocale(id: $id, data: $data) {
      id
      title
      body
      links
      titlePush
      subtitlePush
      bodyPush
      gallery(localeSpecific: true) {
        id
      }
    }
  }
`;

//*-------Create
export interface ICreateNotificationLocale {
  id: string;
  title: string | null;
  body: string | null;
  links: string | null;
  titlePush: string | null;
  subtitlePush: string | null;
  bodyPush: string | null;
  gallery: number[] | null;
  languageFlag: {
    id: string;
    isoLanguageCode: string;
  };
}

export interface ICreateNotificationLocaleData {
  createNotificationLocale: ICreateNotificationLocale;
}
export interface ICreateNotificationLocaleVars {
  notificationId: number;
  data: {
    title: string | undefined;
    body: string | undefined;
    links: string | undefined;
    titlePush: string | undefined;
    subtitlePush: string | undefined;
    bodyPush: string | undefined;
    gallery: number[] | undefined;
    isoLanguageCode: string;
  };
}

export const CREATE_NOTIFICATION_LOCALE = gql`
  mutation createNotificationLocale(
    $notificationId: ID!
    $data: NotificationLocaleCreateInput!
  ) {
    createNotificationLocale(notificationId: $notificationId, data: $data) {
      id
      title
      body
      links
      titlePush
      subtitlePush
      bodyPush
      gallery(localeSpecific: true) {
        id
        fileName
        bucketPath
        byteSize
        mimeType
        extension
      }
      languageFlag {
        id
        isoLanguageCode
      }
    }
  }
`;

//*------ UPDATE PUBLISHED
export interface IUpdateNotificationPublished {
  id: string;
  published: boolean;
}

export interface IUpdateNotificationPublishedData {
  updateNotificationPublished: IUpdateNotificationPublished | null;
}
export interface IUpdateNotificationPublishedVars {
  id: number;
  publish: boolean;
}

export const UPDATE_NOTIFICATION_PUBLISHED = gql`
  mutation updateNotificationPublished($id: ID!, $publish: Boolean!) {
    updateNotificationPublished(id: $id, publish: $publish) {
      id
      published
    }
  }
`;

//*------ UPDATE SEND
export interface IUpdateNotificationSend {
  id: string;
  sent: boolean;
}

export interface IUpdateNotificationSendData {
  updateNotificationSend: IUpdateNotificationSend | null;
}
export interface IUpdateNotificationSendVars {
  id: number;
}

export const UPDATE_NOTIFICATION_SEND = gql`
  mutation updateNotificationSend($id: ID!) {
    updateNotificationSend(id: $id) {
      id
      sent
    }
  }
`;
