import { gql } from "@apollo/client";

export interface IPersonInvitation {
  id: string;
  email: string;
  role: {
    id: string;
    locale: {
      id: string;
      name: string;
    } | null;
  };
  created: string;
  expired: string | null;
}

export interface IPersonInvitationsData {
  allPersonInvitations: IPersonInvitation[];
}

export const ALL_PERSON_INVITATIONS = gql`
  query allPersonInvitations {
    allPersonInvitations {
      id
      email
      role {
        id
        locale {
          id
          name
        }
      }
      created
      expired
    }
  }
`;
