import { gql } from "@apollo/client";

export interface IStatisticsUsersData {
  getStatisticsUsers: number;
}

export interface IStatisticsUsersVars {
  endDate: Date;
  startDate: Date;
}

export const GET_STATISTICS_USERS = gql`
  query getStatisticsUsers($startDate: DateTime!, $endDate: DateTime!) {
    getStatisticsUsers(startDate: $startDate, endDate: $endDate)
  }
`;
