import { ErrorOutline as ErrorOutlineIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { FC } from "react";
import { useStyles } from "./ErrorComponent.styles";

interface IProps {
  small?: boolean;
}

export const ErrorComponent: FC<IProps> = (props) => {
  const { small } = props;
  const { classes } = useStyles();

  return (
    <div className={small ? classes.rootSmall : classes.root}>
      <ErrorOutlineIcon color="error" fontSize="large" />
      <Typography component="p" color="error" variant="h6">
        Došlo je do greške!
      </Typography>
      <Typography>Pokušajte ponovo kasnije...</Typography>
    </div>
  );
};
