import { PermMediaOutlined as PermMediaOutlinedIcon } from "@mui/icons-material";
import { Button, FormHelperText, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import { IMedia } from "../../../../apollo/queries";
import {
  IMediaSelectDialogContext,
  MAX_AUDIO_UPLOAD_SIZE,
  MAX_IMAGE_UPLOAD_SIZE,
} from "../../../../utils";
import { MediaItem } from "../mediaItem/MediaItem";
import { useStyles } from "./MediaSelectButton.styles";
import byteSize from "byte-size";

interface IProps {
  onChange?: (event: any) => void;
  setMediaSelectDialogOpen: Dispatch<SetStateAction<IMediaSelectDialogContext>>;
  pickType?: "image" | "audio";
  max?: number;
  value?: IMedia[];
  label: string;
  error?: boolean | undefined;
  required?: boolean | undefined;
  helperText?: string | undefined;
}

export const MediaSelectButton: FC<IProps> = (props) => {
  const {
    onChange,
    label,
    pickType,
    setMediaSelectDialogOpen,
    max,
    value,
    error,
    required,
    helperText,
  } = props;

  const handleOnClick = () => {
    setMediaSelectDialogOpen({
      open: true,
      type: pickType,
      max: max || 1,
      onChange: onChange,
      selectedMedia: value || [],
    });
  };

  const { classes } = useStyles();

  return (
    <div>
      <Button
        variant="outlined"
        className={classes.uploadButton}
        fullWidth
        onClick={handleOnClick}
        color={error ? "error" : "primary"}
      >
        <Typography className={classes.uploadAreaText}>
          {label}
          {required ? " *" : ""}
        </Typography>
        <PermMediaOutlinedIcon className={classes.uploadAreaIcon} />
        <Typography>
          Izaberite
          {max !== undefined && max > 1 ? ` do ${max} ` : " jedan "}
          {/* {pickType === "audio" ? "audio" : "slika"} */}
          {max !== undefined && max > 1 ? " fajlova" : " fajl"}
        </Typography>
        {pickType === "audio" ? (
          <Typography>
            Max: {byteSize(MAX_AUDIO_UPLOAD_SIZE).toString()} | Format: .mp3
          </Typography>
        ) : (
          <Typography>
            Idealno: 16:9 | Max: {byteSize(MAX_IMAGE_UPLOAD_SIZE).toString()} |
            Ne više od: 1024x576px | Format: .jpeg, .jpg, .png
          </Typography>
        )}
      </Button>
      {value?.length ? (
        <div className={classes.mediaContainer}>
          {value.map((item) => {
            return <MediaItem key={item.id} item={item} />;
          })}
        </div>
      ) : null}
      <FormHelperText variant="outlined" required={required} error={error}>
        {helperText}
      </FormHelperText>
    </div>
  );
};
