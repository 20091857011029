import { gql } from "@apollo/client";
export interface IDeleteMapPin {
  id: string;
}
export interface IDeleteMapPinData {
  deleteMapPin: IDeleteMapPin;
}
export interface IDeleteMapPinVars {
  id: number;
}
export const DELETE_MAP_PIN = gql`
  mutation deleteMapPin($id: ID!) {
    deleteMapPin(id: $id) {
      id
    }
  }
`;

//*-------Delete locale
export interface IDeleteMapPinLocale {
  id: string;
  localeId: string;
}

export interface IDeleteMapPinLocaleData {
  deleteMapPinLocale: IDeleteMapPinLocale;
}
export interface IDeleteMapPinLocaleVars {
  id: number;
}

export const DELETE_MAP_PIN_LOCALE = gql`
  mutation deleteMapPinLocale($id: ID!) {
    deleteMapPinLocale(id: $id) {
      id
      localeId
    }
  }
`;
