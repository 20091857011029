import {
  DeleteOutlined as DeleteOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
} from "@mui/icons-material";
import { Button, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { IOpeningHour } from "../../../../../apollo/queries";
import { useGlobalStyles } from "../../../../../utils/theme";
// import { useStyles } from "./OpeningHourItem.styles";

interface IProps {
  index: number;
  data: IOpeningHour;
  handleDelete: () => void;
  handleUpdate: () => void;
}

export const OpeningHourItem: FC<IProps> = (props) => {
  const { weekday, startHour, endHour } = props.data;
  const { handleDelete, handleUpdate, index } = props;

  // const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();

  const getDay = (day: number) => {
    switch (day) {
      case 1:
        return "Ponedeljak";
      case 2:
        return "Utorak";
      case 3:
        return "Sreda";
      case 4:
        return "Četvrtak";
      case 5:
        return "Petak";
      case 6:
        return "Subota";
      case 7:
        return "Nedelja";
      default:
        return "Wrong day!";
    }
  };

  const currentDay = getDay(weekday);

  return (
    <TableRow>
      <TableCell>{index}</TableCell>
      <TableCell>
        <strong>{currentDay}</strong>
      </TableCell>
      <TableCell>{startHour}</TableCell>
      <TableCell>{endHour}</TableCell>

      <TableCell>
        <div className={globalClasses.tableActionButtons}>
          <Button
            startIcon={<EditOutlinedIcon />}
            onClick={handleUpdate}
            size="small"
          >
            Uredi
          </Button>
          <Button
            startIcon={<DeleteOutlinedIcon />}
            onClick={handleDelete}
            size="small"
          >
            Obriši
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};
