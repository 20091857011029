import { Reference, useMutation, useQuery } from "@apollo/client";
import { AddOutlined as AddOutlinedIcon } from "@mui/icons-material";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import {
  DELETE_INTERACTIVE_TOUR,
  IDeleteInteractiveTourData,
  IDeleteInteractiveTourVars,
} from "../../../apollo/mutations";
import {
  ALL_INTERACTIVE_TOURS,
  IInteractiveToursData,
} from "../../../apollo/queries";
import {
  PageLayout,
  DataHandlerComponent,
  LoadingBackdrop,
  Pagination,
} from "../../../components";
import { DeleteDialog } from "../../../components";
import { IDialogContext } from "../../../utils";
import { useGlobalStyles } from "../../../utils/theme";
// import { useStyles } from "./InteractiveToursListPage.styles";
import { NavLink } from "react-router-dom";
import { InteractiveTourItem } from "./components";

export const InteractiveToursListPage: FC = () => {
  // const { cx } = useStyles();
  const { cx, classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [currentIndexes, setCurrentIndexes] = useState<{
    start: number;
    end: number;
  }>({ start: 0, end: 0 });

  // const myVal: number | number[] = 0;

  // let [minVal, maxVal] = typeof myVal == "object" ? myVal : [myVal, myVal];

  const [openDialog, setOpenDialog] = useState<IDialogContext>({
    open: false,
    id: undefined,
    type: undefined,
  });

  const handleCloseDialog = () => {
    setOpenDialog((prevState) => ({
      ...prevState,
      open: false,
      id: undefined,
    }));
  };

  const { data, loading, error } = useQuery<IInteractiveToursData, null>(
    ALL_INTERACTIVE_TOURS,
    {
      onError: (err) => {
        enqueueSnackbar(`Nije uspelo učitavanje podataka: ${err.message}`, {
          variant: "error",
        });
      },
    }
  );

  const [deleteTourMutation, { loading: loadingDeleteMutation }] = useMutation<
    IDeleteInteractiveTourData,
    IDeleteInteractiveTourVars
  >(DELETE_INTERACTIVE_TOUR, {
    onCompleted: (res) => {
      enqueueSnackbar("Interaktivna tura je obrisana!", { variant: "success" });
      handleCloseDialog();
    },
    onError: (error) => {
      // console.log({ error });
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    update(cache, { data }) {
      cache.modify({
        fields: {
          allInteractiveTours(existingTours: Array<Reference>, { readField }) {
            if (data) {
              return existingTours.filter(
                (taskRef) =>
                  data.deleteInteractiveTour.id !== readField("id", taskRef)
              );
            }
          },
        },
      });
    },
  });

  const handleDeleteTour = () => {
    if (openDialog.id && openDialog.type === "delete") {
      deleteTourMutation({ variables: { id: +openDialog.id } });
    } else {
      enqueueSnackbar("Poziv za brisanje nije uspeo");
    }
  };

  const getCurrentItems = () => {
    if (data?.allInteractiveTours?.length) {
      const elements = [];

      const condition =
        currentIndexes.end < data.allInteractiveTours.length
          ? currentIndexes.end
          : data.allInteractiveTours.length;

      // console.log(currentIndexes, data.allTours.length, condition);
      for (let i = currentIndexes.start; i < condition; i++) {
        const handleOpenDeleteDialog = () => {
          setOpenDialog({
            open: true,
            id: data.allInteractiveTours[i].id,
            type: "delete",
          });
        };

        elements.push(
          <InteractiveTourItem
            key={data.allInteractiveTours[i].id}
            index={i + 1}
            data={data.allInteractiveTours[i]}
            handleDelete={handleOpenDeleteDialog}
          />
        );
      }
      return elements;
    }
    return null;
  };

  return (
    <PageLayout justifyCenter>
      <Paper className={globalClasses.paperRoot}>
        <div
          className={cx(
            globalClasses.paperTitle,
            globalClasses.justifySpaceBetween
          )}
        >
          <Typography component="h1" variant="h5">
            Interaktivne ture
          </Typography>
          <NavLink className={globalClasses.disableNavlinkDecoration} to="new">
            <Button startIcon={<AddOutlinedIcon />} variant="outlined">
              Dodaj novo
            </Button>
          </NavLink>
        </div>
        <div className={globalClasses.paperContainer}>
          <TableContainer className={globalClasses.tableContainer}>
            <Table
              className={globalClasses.table}
              // sx={{ minWidth: 650 }}
              aria-label="List of tours"
            >
              <TableHead>
                <TableRow
                // className={classes.tableRow}
                >
                  <TableCell width={32} align="left">
                    #
                  </TableCell>
                  <TableCell align="left">Naziv</TableCell>
                  <TableCell width={64} align="left">
                    Trajanje
                  </TableCell>
                  <TableCell width={160} align="left">
                    Kreirano
                  </TableCell>
                  <TableCell width={64} align="left">
                    Objavljeno
                  </TableCell>
                  <TableCell width={180} align="right">
                    Akcije
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{getCurrentItems()}</TableBody>
            </Table>
          </TableContainer>

          <DataHandlerComponent
            error={Boolean(error)}
            loading={loading}
            hasData={Boolean(data?.allInteractiveTours?.length)}
            skeletonHeight={72}
            skeletonNum={4}
          />
        </div>
        <Pagination
          data={data?.allInteractiveTours}
          setCurrentIndexes={setCurrentIndexes}
        />
      </Paper>

      <DeleteDialog
        open={openDialog.type === "delete" && openDialog.open}
        title="Obrisati ovu interaktivnu turu?"
        description="Ovo će takođe obrisati povezane tačke interesa. Ova akcija je trajna!"
        onClose={handleCloseDialog}
        mutation={handleDeleteTour}
      />
      <LoadingBackdrop loading={loadingDeleteMutation} />
    </PageLayout>
  );
};
