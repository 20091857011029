import { gql } from "@apollo/client";

export interface ISubjectLocale {
  id: string;
  name: string;
  about: string | null;
  extraInfo: string | null;
  openingHoursInfo: string | null;
  languageFlag: {
    id: string;
    isoLanguageCode: string;
  };
}

export interface ISubjectLocalesData {
  allSubjectLocales?: ISubjectLocale[];
}

export const ALL_SUBJECT_LOCALES = gql`
  query allSubjectLocales {
    allSubjectLocales {
      id
      name
      about
      extraInfo
      openingHoursInfo
      gallery(localeSpecific: true) {
        id
        fileName
        bucketPath
        byteSize
        mimeType
        extension
      }
      languageFlag {
        id
        isoLanguageCode
      }
    }
  }
`;
