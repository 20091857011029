import { gql } from "@apollo/client";

export interface IOpeningHour {
  id: string;
  weekday: number;
  startHour: string;
  endHour: string;
}

export interface IOpeningHourData {
  oneOpeningHour: IOpeningHour;
}

export interface IOpeningHourVars {
  id: number;
}

export const ONE_OPENING_HOUR = gql`
  query oneOpeningHour($id: ID!) {
    oneOpeningHour(id: $id) {
      id
      weekday
      startHour
      endHour
    }
  }
`;

export interface IOpeningHoursData {
  allOpeningHours: IOpeningHour[];
}

export interface IOpeningHoursVars {}

export const ALL_OPENING_HOURS = gql`
  query allOpeningHours {
    allOpeningHours {
      id
      weekday
      startHour
      endHour
    }
  }
`;
