import { gql } from "@apollo/client";

//*-------Update locale

export interface IUpdateSubjectLocale {
  id: string;
  name: string | null;
  about: string | null;
  extraInfo: string | null;
  openingHoursInfo: string | null;
  gallery: {
    id: string;
    fileName: string;
    bucketPath: string;
    byteSize: number;
    mimeType: string;
    extension: string;
  }[];
}

export interface IUpdateSubjectLocaleData {
  updateSubjectLocale: IUpdateSubjectLocale;
}
export interface IUpdateSubjectLocaleVars {
  id: number;
  data: {
    name: string | undefined | null;
    about: string | undefined | null;
    extraInfo: string | undefined | null;
    openingHoursInfo: string | undefined | null;
    gallery: number[] | null | undefined;
  };
}

export const UPDATE_SUBJECT_LOCALE = gql`
  mutation updateSubjectLocale($id: ID!, $data: SubjectLocaleUpdateInput!) {
    updateSubjectLocale(id: $id, data: $data) {
      id
      name
      about
      extraInfo
      openingHoursInfo
      gallery(localeSpecific: true) {
        id
      }
    }
  }
`;

//*-------Create locale

export interface ICreateSubjectLocale {
  id: string;
  name: string;
  about: string;
  extraInfo: string | null;
  openingHoursInfo: string | null;
  gallery:
    | {
        id: string;
      }[]
    | null;
  languageFlag: {
    id: string;
    isoLanguageCode: string;
  };
}

export interface ICreateSubjectLocaleData {
  createSubjectLocale: ICreateSubjectLocale;
}
export interface ICreateSubjectLocaleVars {
  data: {
    name: string | undefined;
    about: string | undefined;
    extraInfo: string | undefined;
    openingHoursInfo: string | undefined;
    gallery: number[] | undefined;
    isoLanguageCode: string;
  };
}

export const CREATE_SUBJECT_LOCALE = gql`
  mutation createSubjectLocale($data: SubjectLocaleCreateInput!) {
    createSubjectLocale(data: $data) {
      id
      name
      about
      extraInfo
      openingHoursInfo
      gallery(localeSpecific: true) {
        id
      }
      languageFlag {
        id
        isoLanguageCode
      }
    }
  }
`;
