import { FC, useLayoutEffect } from "react";
import { Button, CircularProgress, Paper, Typography } from "@mui/material";
import { useStyles } from "./ValidatePersonPage.styles";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import {
  VALIDATE_ACCOUNT,
  IValidateAccountVars,
  IValidateAccountData,
} from "../../../apollo/mutations";
import { useMutation } from "@apollo/client";

export const ValidatePersonPage: FC = () => {
  const { token } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();

  const [validateAccountMutation, { loading, data }] = useMutation<
    IValidateAccountData,
    IValidateAccountVars
  >(VALIDATE_ACCOUNT, {
    onCompleted: () => {
      enqueueSnackbar("Vaš nalog je verifikovan!", {
        variant: "success",
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const handleValidateAccount = () => {
    if (token) {
      validateAccountMutation({
        variables: {
          token: token,
        },
      });
    } else {
      enqueueSnackbar("Token nedostaje", { variant: "error" });
    }
  };

  useLayoutEffect(() => {
    handleValidateAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className={cx(classes.root)}>
      <Paper className={cx(classes.paper)}>
        <div>
          <Typography paragraph component="h1" variant="h5">
            {!data?.validateAccount ? "Confirm account" : "Account confirmed!"}
          </Typography>
          {loading ? (
            <>
              <Typography paragraph>Checking...</Typography>
              <div className={cx(classes.loadingDiv)}>
                <CircularProgress color="primary" />
              </div>
            </>
          ) : (
            <Typography paragraph>
              {!data?.validateAccount
                ? "Kliknite na dugme ispod da biste verifikovali svoj nalog!"
                : "Vaš nalog je verifikovan!"}
            </Typography>
          )}
        </div>
        {!data?.validateAccount && !loading ? (
          <Button
            className={cx(classes.button)}
            variant="contained"
            fullWidth
            disabled={loading}
            onClick={handleValidateAccount}
          >
            Validate account
          </Button>
        ) : null}
      </Paper>
    </main>
  );
};
