import { gql } from "@apollo/client";

export interface IReview {
  id: string;
  stars: number;
  message: string | null;
  created: string;
}

export interface IReviewsData {
  allReviews: IReview[] | null;
}

export interface IReviewsVars {
  endDate: Date;
  startDate: Date;
  classicTourId: number | undefined;
  interactiveTourId: number | undefined;
}

export const ALL_REVIEWS = gql`
  query allReviews(
    $startDate: DateTime!
    $endDate: DateTime!
    $classicTourId: ID
    $interactiveTourId: ID
  ) {
    allReviews(
      startDate: $startDate
      endDate: $endDate
      classicTourId: $classicTourId
      interactiveTourId: $interactiveTourId
    ) {
      id
      stars
      message
      created
    }
  }
`;
