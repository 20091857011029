import { gql } from "@apollo/client";

export const F_PERSON = gql`
  fragment FPerson on Person {
    id
    name
    email
    role {
      id
      access
      locale {
        id
        name
      }
    }
  }
`;
