import { gql } from "@apollo/client";
import { IMedia } from "../../queries";

export interface ICreateNotification {
  id: string;
  published: boolean;
  sent: boolean;
  locale: {
    id: string;
    title: string | null;
    body: string | null;
    links: string | null;
    titlePush: string | null;
    subtitlePush: string | null;
    bodyPush: string | null;
    gallery: IMedia[] | null;
  };
  created: string;
}

export interface ICreateNotificationData {
  createNotification: ICreateNotification;
}
export interface ICreateNotificationVars {
  dataLocale: {
    title: string | undefined;
    body: string | undefined;
    links: string | undefined;
    titlePush: string | undefined;
    subtitlePush: string | undefined;
    bodyPush: string | undefined;
    gallery: number[] | undefined;
  };
}

export const CREATE_NOTIFICATION = gql`
  mutation createNotification(
    $dataLocale: NotificationLocaleCreateInitialInput!
  ) {
    createNotification(dataLocale: $dataLocale) {
      id
      published
      sent
      locale {
        id
        title
        body
        links
        titlePush
        subtitlePush
        bodyPush
        gallery(localeSpecific: true) {
          id
          fileName
          bucketPath
          byteSize
          mimeType
          extension
        }
      }
      created
    }
  }
`;
