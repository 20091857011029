import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  title: {
    inputProps: {
      label: "Naslov",
      name: "title",
      placeholder: "Ime vaše audio ture",
      required: true,
    },
    localise: true,
    mainHelperText:
      "Naslov treba da bude kratak i dovoljno zanimljiv da privuče pažnju posetioca kako bi obišli audio turu. Ne uključujte numeraciju, ona se dodaje automatski.",
  },
  description: {
    localise: true,
    inputProps: {
      name: "description",
      label: "Opis",
      placeholder: "Kratak opis ove audio ture",
      required: true,
    },
    mainHelperText:
      "Ovaj kratak opis treba da pruži posetiocima dovoljno informacija o čemu se radi u ovoj audio turi.",
  },
  duration: {
    inputProps: {
      name: "duration",
      label: "Trajanje",
      placeholder: "30",
      required: true,
    },
    regexPattern: /^(?:[1-9][0-9]{0,2})?$/,
  },
  gallery: {
    inputProps: {
      label: "Galerija",
      name: "gallery",
    },
    rule: "media",
    required: true,
    localise: true,
    mainLocaleRequiredOnly: true,
  },
});
