import { gql } from "@apollo/client";

export interface IMapPin {
  id: string;
  published: boolean;
  label: string | null;
  mapId: string | null;
  locale?: {
    id: string;
    name: string | null;
  };
  created: string;
}

export interface IMapPinsData {
  allMapPins: IMapPin[];
}

export const ALL_MAP_PINS = gql`
  query allMapPins {
    allMapPins {
      id
      published
      mapId
      label
      locale(lang: "sr") {
        id
        name
      }
      created
    }
  }
`;

export interface IMapPinDetails {
  id: string;
  published: boolean;
  label: string | null;
  posX: string | null;
  posY: string | null;
  mapId: string | null;
  skipLegend: boolean | null;
  allLocales?: {
    id: string;
    name: string | null;
    languageFlag: {
      id: string;
      isoLanguageCode: string;
    };
  }[];
  created: string;
}

export interface IMapPinDetailsData {
  oneMapPinDetails: IMapPinDetails;
}

export interface IMapPinDetailsVars {
  id: number;
}

export const ONE_MAP_PIN_DETAILS = gql`
  query oneMapPinDetails($id: ID!) {
    oneMapPinDetails(id: $id) {
      id
      published
      label
      posX
      posY
      mapId
      skipLegend
      allLocales {
        id
        name
        languageFlag {
          id
          isoLanguageCode
        }
      }
      created
    }
  }
`;
