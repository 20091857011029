import { gql } from "@apollo/client";

export interface IDifficulty {
  id: string;
  level: string;
  locale: {
    id: string;
    name: string;
  };
}

export interface IDifficultiesData {
  allDifficulties: IDifficulty[];
}

export const ALL_DIFFICULTIES = gql`
  query allDifficulties {
    allDifficulties {
      id
      level
      locale(lang: "sr") {
        id
        name
      }
    }
  }
`;
