import { createTypedDynamicFormInputs } from "../../utils/formUtils";

export const initialInputData = createTypedDynamicFormInputs({
  languageFlag: {
    inputProps: {
      label: "Jezik",
      name: "language",
      required: true,
    },
  },
});
