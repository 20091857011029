import { gql } from "@apollo/client";
import { TAgeAppropriate } from "../../../utils";

//*-------Update other
export interface IUpdateInteractiveTourOther {
  id: string;
  duration: number | null;
  ageAppropriate: TAgeAppropriate | null;
  difficulty: {
    id: string;
  } | null;
}

export interface IUpdateInteractiveTourOtherData {
  updateInteractiveTourOther: IUpdateInteractiveTourOther;
}
export interface IUpdateInteractiveTourOtherVars {
  id: number;
  data: {
    duration: number | undefined | null;
    difficultyId: number | undefined | null;
    ageAppropriate: string | undefined | null;
  };
}

export const UPDATE_INTERACTIVE_TOUR_OTHER = gql`
  mutation updateInteractiveTourOther(
    $id: ID!
    $data: InteractiveTourOtherUpdateInput!
  ) {
    updateInteractiveTourOther(id: $id, data: $data) {
      id
      duration
      ageAppropriate
      difficulty {
        id
      }
    }
  }
`;

//*-------Update locale

export interface IUpdateInteractiveTourLocale {
  id: string;
  title: string | null;
  description: string | null;
  introduction: string | null;
  conclusion: string | null;
  startingPoint: string | null;
  importantInfo: string | null;
}

export interface IUpdateInteractiveTourLocaleData {
  updateInteractiveTourLocale: IUpdateInteractiveTourLocale;
}
export interface IUpdateInteractiveTourLocaleVars {
  id: number;
  data: {
    title: string | undefined | null;
    description: string | undefined | null;
    introduction: string | undefined | null;
    conclusion: string | undefined | null;
    startingPoint: string | undefined | null;
    importantInfo: string | undefined | null;
    gallery: number[] | undefined | null;
  };
}

export const UPDATE_INTERACTIVE_TOUR_LOCALE = gql`
  mutation updateInteractiveTourLocale(
    $id: ID!
    $data: InteractiveTourLocaleUpdateInput!
  ) {
    updateInteractiveTourLocale(id: $id, data: $data) {
      id
      title
      description
      introduction
      startingPoint
      importantInfo
      conclusion
      gallery(localeSpecific: true) {
        id
      }
    }
  }
`;

//*-------Create

export interface ICreateInteractiveTourLocale {
  id: string;
  title: string | null;
  description: string | null;
  introduction: string | null;
  conclusion: string | null;
  startingPoint: string | null;
  importantInfo: string | null;
  languageFlag: {
    id: string;
    isoLanguageCode: string;
  };
}

export interface ICreateInteractiveTourLocaleData {
  createInteractiveTourLocale: ICreateInteractiveTourLocale;
}
export interface ICreateInteractiveTourLocaleVars {
  interactiveTourId: number;
  data: {
    title: string | undefined;
    description: string | undefined;
    introduction: string | undefined;
    conclusion: string | undefined;
    startingPoint: string | undefined;
    importantInfo: string | undefined;
    gallery: number[] | undefined;
    isoLanguageCode: string;
  };
}

export const CREATE_INTERACTIVE_TOUR_LOCALE = gql`
  mutation createInteractiveTourLocale(
    $interactiveTourId: ID!
    $data: InteractiveTourLocaleCreateInput!
  ) {
    createInteractiveTourLocale(
      interactiveTourId: $interactiveTourId
      data: $data
    ) {
      id
      title
      description
      introduction
      conclusion
      importantInfo
      startingPoint
      gallery(localeSpecific: true) {
        id
      }
      languageFlag {
        id
        isoLanguageCode
      }
    }
  }
`;

//*------ SET TOUR_STATION

// export interface IUpdateInteractiveTourStation {
//   id: string;
//   allStations: {
//     id: string;
//   }[];
// }

// export interface IUpdateInteractiveTourStationData {
//   updateInteractiveTourStation: IUpdateInteractiveTourStation;
// }
// export interface IUpdateInteractiveTourStationVars {
//   id: number;
//   data: {
//     stations: { id: number; order: number; parentId?: number | undefined }[];
//   };
// }

// export const UPDATE_INTERACTIVE_TOUR_STATION = gql`
//   mutation updateInteractiveTourStation($id: ID!, $data: InteractiveTourStationUpdateInput!) {
//     updateInteractiveTourStation(id: $id, data: $data) {
//       id
//       allStations {
//         id
//       }
//     }
//   }
// `;

//*------ UPDATE PUBLISHED

export interface IUpdateInteractiveTourPublished {
  id: string;
  published: boolean;
}

export interface IUpdateInteractiveTourPublishedData {
  updateInteractiveTourPublished: IUpdateInteractiveTourPublished | null;
}
export interface IUpdateInteractiveTourPublishedVars {
  id: number;
  publish: boolean;
  checkOnly: boolean;
}

export const UPDATE_INTERACTIVE_TOUR_PUBLISHED = gql`
  mutation updateInteractiveTourPublished(
    $id: ID!
    $checkOnly: Boolean!
    $publish: Boolean!
  ) {
    updateInteractiveTourPublished(
      id: $id
      checkOnly: $checkOnly
      publish: $publish
    ) {
      id
      published
    }
  }
`;
