import { createTypedDynamicFormInputs } from "../../../utils/formUtils";

export const initialInputData = createTypedDynamicFormInputs({
  title: {
    inputProps: {
      label: "Naslov stanice",
      name: "stationTitle",
      placeholder: "Ime vaše stanice",
      required: true,
    },
    localise: true,
    mainHelperText:
      "Naslov treba da bude kratak i dovoljno zanimljiv da privuče pažnju posetioca kako bi obišli ovu stanicu. Ne uključujte numeraciju, ona se dodaje automatski.",
  },
  description: {
    inputProps: {
      label: "Opis stanice",
      placeholder: "Kratak opis ove stanice",
      required: true,
      name: "stationDescription",
    },
    localise: true,
    mainHelperText:
      "Ovaj kratak opis treba da pruži posetiocima dovoljno informacija o čemu se radi u ovoj stanici.",
  },
  audioGuideText: {
    inputProps: {
      label: "Tekst audio vodiča",
      placeholder: "Puna tekstualna verzija audio vodiča.",
      required: true,
      name: "audioGuideText",
    },
    localise: true,
    mainHelperText:
      "U ovo polje treba da unesete tekst koji se govori u audio vodiču.",
  },
  gallery: {
    inputProps: {
      label: "Galerija",
      required: true,
      name: "gallery",
    },
    rule: "media",
    mainLocaleRequiredOnly: true,
    localise: true,
  },
  audioGuide: {
    inputProps: {
      label: "Audio vodič",
      name: "audioGuide",
      required: true,
    },
    rule: "media",
    localise: true,
  },
  textCode: {
    mainHelperText: "Kod je osetljiv na velika i mala slova!",
    inputProps: {
      label: "Tekstualni kod",
      name: "textCode",
      placeholder: "a-10",
    },
  },
});
