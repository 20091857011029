import { gql } from "@apollo/client";
import { ERoleAccess } from "../../../utils";

export interface IAdminRole {
  id: string;
  access: ERoleAccess;
  locale?: {
    id: string;
    name: string;
    description: string | null;
  };
}

export interface IAdminRolesData {
  allAdminRoles?: IAdminRole[];
}

export const ALL_ADMIN_ROLES = gql`
  query allAdminRoles {
    allAdminRoles {
      id
      access
      locale(lang: "sr") {
        id
        name
        description
      }
    }
  }
`;
