import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { FC, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useStyles } from "./ReceptionConfirmationDialog.styles";
import { LockOpenOutlined as LockOpenOutlinedIcon } from "@mui/icons-material";
import { useLazyQuery } from "@apollo/client";
import {
  IRewardReceptionData,
  IRewardReceptionVars,
  ONE_REWARD_RECEPTION,
} from "../../apollo/queries/rewardReception/rewardReception.query";
import { DataHandlerComponent } from "../dataHandlerComponent/DataHandlerComponent";

type TDialogProps = {
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  code: string;
  callMutation: () => void;
};

type TProps = DialogProps & TDialogProps;

export const ReceptionConfirmationDialog: FC<TProps> = (props) => {
  const { callMutation, code, open, onClose, ...rest } = props;

  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [callOneRewardReception, { data, loading, error, refetch }] =
    useLazyQuery<IRewardReceptionData, IRewardReceptionVars>(
      ONE_REWARD_RECEPTION,
      {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
          code: code,
        },

        onError: (err) => {
          enqueueSnackbar(err.message, { variant: "error" });
        },
      }
    );

  useEffect(() => {
    if (open && code) {
      callOneRewardReception({
        variables: {
          code: code,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const refresh = () => {
    refetch();
  };

  return (
    <Dialog {...rest} open={open} onClose={onClose}>
      <DialogTitle>Potvrda</DialogTitle>
      <DialogContent>
        <DataHandlerComponent
          noDataMessage="Nema nagrade povezane sa ovim kodom."
          retryFunction={refresh}
          hasData={Boolean(data?.oneRewardReception?.id)}
          loading={loading}
          error={Boolean(error)}
        >
          {data?.oneRewardReception?.id ? (
            <>
              <Typography variant="h6">Informacije o nagradi:</Typography>
              <Divider className={classes.marginBottom} />
              <Typography>{data.oneRewardReception.locale.title}</Typography>

              <Typography className={classes.marginBottom}>
                {data.oneRewardReception.locale.description}
              </Typography>

              <Typography variant="h6">Kod:</Typography>
              <Divider className={classes.marginBottom} />
              <Typography>{code}</Typography>
            </>
          ) : null}
        </DataHandlerComponent>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Zatvori
        </Button>
        <Button
          variant="contained"
          onClick={callMutation}
          disabled={
            Boolean(error) || loading || !Boolean(data?.oneRewardReception?.id)
          }
          startIcon={<LockOpenOutlinedIcon />}
        >
          Iskoristite nagradu
        </Button>
      </DialogActions>
    </Dialog>
  );
};
