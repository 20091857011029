import { gql } from "@apollo/client";

export interface IStatisticsInteractiveTourData {
  getStatisticsInteractiveTour: number;
}

export interface IStatisticsInteractiveTourVars {
  endDate: Date;
  startDate: Date;
  interactiveTourId: number;
}

export const GET_STATISTICS_INTERACTIVE_TOUR = gql`
  query getStatisticsInteractiveTour(
    $startDate: DateTime!
    $endDate: DateTime!
    $interactiveTourId: ID!
  ) {
    getStatisticsInteractiveTour(
      startDate: $startDate
      endDate: $endDate
      interactiveTourId: $interactiveTourId
    )
  }
`;

export interface IStatisticsInteractiveTourCompleteness {
  id: string;
  completed: number;
  skipped: number;
  ignored: number;
  startedAt: string;
  completedAt: string;
  created: string;
}

export interface IStatisticsInteractiveTourCompletenessData {
  allStatisticsInteractiveTourCompleteness: IStatisticsInteractiveTourCompleteness[];
}

export interface IStatisticsInteractiveTourCompletenessVars {
  endDate: Date;
  startDate: Date;
  interactiveTourId: number;
}

export const GET_STATISTICS_INTERACTIVE_TOUR_COMPLETENESS = gql`
  query allStatisticsInteractiveTourCompleteness(
    $startDate: DateTime!
    $endDate: DateTime!
    $interactiveTourId: ID!
  ) {
    allStatisticsInteractiveTourCompleteness(
      startDate: $startDate
      endDate: $endDate
      interactiveTourId: $interactiveTourId
    ) {
      id
      completed
      skipped
      ignored
      startedAt
      completedAt
      created
    }
  }
`;
