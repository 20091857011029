import { gql } from "@apollo/client";

export interface ICreatePersonInvitation {
  id: string;
  email: string;
  role: {
    id: string;
    locale: {
      id: string;
      name: string;
    } | null;
  };
  created: string;
  expired: string | null;
}

export interface ICreatePersonInvitationData {
  inviteAdmin: ICreatePersonInvitation;
}
export interface ICreatePersonInvitationVars {
  data: {
    email: string;
    roleId: number;
  };
}

export const CREATE_PERSON_INVITATION = gql`
  mutation inviteAdmin($data: PersonInvitationCreateInput!) {
    inviteAdmin(data: $data) {
      id
      email
      role {
        id
        locale {
          id
          name
        }
      }
      created
      expired
    }
  }
`;
