import { createTypedDynamicFormInputs } from "../../../utils/formUtils";

export const initialInputData = createTypedDynamicFormInputs({
  title: {
    inputProps: {
      name: "checkpointTitle",
      label: "Naslov tačke interesa",
      placeholder: "Naslov za ovu tačku",
      required: true,
    },
    localise: true,
  },
  directions: {
    inputProps: {
      name: "directions",
      label: "Uputstva",
      placeholder: "Uputstva do izazovnog područja",
      required: true,
    },
    localise: true,
    mainHelperText:
      "U ovom polju posetioci dobijaju niz uputstava kako da se kreću od jedne tačke interesa do druge.",
  },
  directionsHint: {
    inputProps: {
      name: "directionsHint",
      label: "Savet za uputstva",
      placeholder: "Jasna i precizna uputstva do izazovnog područja.",
      required: true,
    },
    localise: true,
    mainHelperText:
      "Tekstualni saveti pomažu posetiocima da pronađu sledeću tačku interesa.",
  },
  opening: {
    inputProps: {
      name: "checkpointOpening",
      label: "Otvaranje tačke interesa",
      required: true,
    },
    localise: true,
    mainHelperText:
      "Otvaranje treba da obavesti posetioca da je stigao do određene tačke interesa i da ga usmeri ka izazovu.",
  },
  challenge: {
    inputProps: {
      name: "checkpointChallenge",
      label: "Izazov tačke interesa",
      required: true,
    },
    localise: true,
    mainHelperText:
      "Izazov može biti zagonetka, kod, ili zagonetka, a najbolje je da se težina postepeno povećava od jedne lokacije do druge.",
  },
  challengeHint: {
    inputProps: {
      name: "challengeHint",
      label: "Savet za izazov",
      required: true,
    },
    localise: true,
    mainHelperText:
      "Tekstualni saveti pomažu posetiocima da pronađu rešenje, ali ne pružaju samo rešenje.",
  },
  closing: {
    inputProps: {
      name: "checkpointClosing",
      label: "Zatvaranje tačke interesa",
      required: true,
    },
    localise: true,
    mainHelperText:
      "Zatvaranje treba da uključi rešenje izazova i da spomene da igrač prelazi na sledeću lokaciju.",
  },
  facts: {
    inputProps: {
      name: "interestingFact",
      label: "Zanimljiva činjenica",
      required: true,
      placeholder: "Zanimljiva činjenica vezana za ovu tačku",
    },
    localise: true,
    mainHelperText:
      "Deo sa zanimljivim činjenicama sadrži informacije o određenoj tački interesa. Zanimljive činjenice treba da budu povezane sa glavnom temom, zanimljive i vredne prenošenja drugima.",
  },
  factsGallery: {
    inputProps: {
      name: "facts",
      label: "Galerija činjenica",
      placeholder: "",
    },
    rule: "media",
    localise: true,
  },
  directionsHintGallery: {
    inputProps: {
      name: "facts",
      label: "Galerija saveta za uputstva",
      placeholder: "",
      required: true,
    },
    rule: "media",
    localise: true,
    mainLocaleRequiredOnly: true,
  },
  challengeHintGallery: {
    inputProps: {
      name: "challengeHintGallery",
      label: "Galerija saveta za izazov",
      placeholder: "",
    },
    rule: "media",
    localise: true,
  },

  solution: {
    mainHelperText:
      "Rešenja treba da budu kratka, sastavljena od jedne do dve reči. Više rešenja se može dodati razdvajanjem zarezima (,). Imajte na umu da su razmaci takođe uključeni u rešenje.",
    inputProps: {
      name: "solution",
      label: "Rešenje",
      placeholder: "5.12.1998.,05.12.1998.",
      required: true,
    },
    localise: true,
  },

  solutionHelperMedia: {
    inputProps: {
      name: "solutionHelperMedia",
      label: "Medijski fajlovi za izazov",
    },
    rule: "media",
    localise: true,
    mainHelperText:
      "Prikazuje se ispod izazova. Služi kao deo izazova, na primer mapa ili šifra koja pomaže korisniku u rešavanju zadatka.",
  },

  puzzleType: {
    inputProps: {
      name: "puzzleType",
      label: "Vrsta izazova",
    },
    mainHelperText:
      "Umesto polja za unos rešenja u izazovu, prikaži: interaktivni lokot sa brojevima, interaktivni lokot sa slovima, numeričku tastaturu i svetla koja svetle u binarnoj kombinaciji.",
  },
});
