import { createTypedDynamicFormInputs } from "../../../utils/formUtils";

export const initialInputData = createTypedDynamicFormInputs({
  mapId: {
    inputProps: {
      label: "ID mape",
      placeholder: "",
      required: true,
      name: "mapId",
    },
    mainHelperText: "Na kojem spratu će se prikazati ova oznaka?",
  },
  label: {
    inputProps: {
      label: "Vrsta oznake na mapi",
      placeholder: "1",
      required: true,
      name: "displayLabel",
    },
    mainHelperText: "Prikaži broj ili posebnu ikonu na mapi.",
  },
  posX: {
    inputProps: {
      label: "X pozicija",
      placeholder: "50",
      required: true,
      name: "posX",
    },
    // regexPattern: /^(?:[1-9][0-9]{0,2})?$/,
    mainHelperText:
      "X pozicija u procentima od 0 do 100 (0 = levo | 100 = desno).",
  },
  posY: {
    inputProps: {
      label: "Y pozicija",
      placeholder: "50",
      required: true,
      name: "posY",
    },
    // regexPattern: /^(?:[1-9][0-9]{0,2})?$/,
    mainHelperText:
      "Y pozicija u procentima od 0 do 100 (0 = dole | 100 = gore).",
  },
  name: {
    inputProps: {
      label: "Ime",
      name: "name",
      placeholder: "Ime vaše oznake na mapi",
      required: true,
    },
    localise: true,
  },
  skipLegend: {
    inputProps: {
      label: "Preskoči legendu",
      name: "skipLegend",
    },
    isCheckbox: true,
    mainHelperText:
      "Da li ova oznaka na mapi treba da bude sakrivena u legendi? Koristite ako postoji više oznaka sa istom oznakom i imenom kako biste sprečili duplikate",
  },
});
