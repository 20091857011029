import { gql } from "@apollo/client";
import { IMedia } from "../media/media.query";

export interface INotification {
  id: string;
  published: boolean;
  sent: boolean;
  locale?: {
    id: string;
    title: string | null;
  };
  created: string;
}

export interface INotificationsData {
  allNotifications: INotification[];
}

export const ALL_NOTIFICATIONS = gql`
  query allNotifications {
    allNotifications {
      id
      published
      sent
      locale(lang: "sr") {
        id
        title
      }
      created
    }
  }
`;

export interface INotificationDetails {
  id: string;
  published: boolean;
  sent: boolean;
  allLocales?: {
    id: string;
    title: string | null;
    body: string | null;
    links: string | null;
    titlePush: string | null;
    subtitlePush: string | null;
    bodyPush: string | null;
    gallery: IMedia[];
    languageFlag: {
      id: string;
      isoLanguageCode: string;
    };
  }[];
  created: string;
}

export interface INotificationDetailsData {
  oneNotificationDetails: INotificationDetails;
}

export interface INotificationDetailsVars {
  id: number;
}

export const ONE_NOTIFICATION_DETAILS = gql`
  query oneNotificationDetails($id: ID!) {
    oneNotificationDetails(id: $id) {
      id
      published
      sent
      allLocales {
        id
        title
        body
        links
        titlePush
        subtitlePush
        bodyPush
        gallery(localeSpecific: true) {
          id
          fileName
          bucketPath
          byteSize
          mimeType
          extension
        }
        languageFlag {
          id
          isoLanguageCode
        }
      }
      created
    }
  }
`;
