import { createContext, Dispatch, SetStateAction } from "react";
import { IPerson, ILanguageFlag } from "../apollo/queries";

export interface IContextProvider {
  user: IPerson | undefined;
  setUser: Dispatch<SetStateAction<IPerson | undefined>>;
  languageFlags: ILanguageFlag[];
  setLanguageFlags: Dispatch<SetStateAction<ILanguageFlag[]>>;
}

export const ContextProvider = createContext<IContextProvider>({
  user: undefined,
  setUser: () => {},
  languageFlags: [],
  setLanguageFlags: () => {},
});
