import {
  CancelOutlined as CancelOutlinedIcon,
  SendOutlined as SendOutlinedIcon,
} from "@mui/icons-material";
import { Button, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { IPersonInvitation } from "../../../../../apollo/queries";
import { useGlobalStyles } from "../../../../../utils/theme";
import { format } from "date-fns";

interface IProps {
  index: number;
  data: IPersonInvitation;
  handleRevoke: () => void;
  handleResend: () => void;
}

export const InvitationItem: FC<IProps> = (props) => {
  const { created, email, expired, role } = props.data;
  const { handleRevoke, handleResend, index } = props;

  // const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();

  return (
    <TableRow onClick={() => {}}>
      <TableCell>{index}</TableCell>
      <TableCell>
        <strong>{email}</strong>
      </TableCell>
      <TableCell>{role.locale?.name}</TableCell>
      <TableCell>{format(new Date(created), "dd.MM.yyyy. HH:mm'h'")}</TableCell>
      <TableCell>
        {expired ? format(new Date(expired), "dd.MM.yyyy. HH:mm'h'") : null}
      </TableCell>

      <TableCell>
        <div className={globalClasses.tableActionButtons}>
          {expired ? null : (
            <>
              <Button
                startIcon={<SendOutlinedIcon />}
                onClick={handleResend}
                size="small"
              >
                Ponovno pošaljite
              </Button>
              <Button
                startIcon={<CancelOutlinedIcon />}
                onClick={handleRevoke}
                size="small"
              >
                Poništite
              </Button>
            </>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};
