import { gql } from "@apollo/client";
import { IPerson } from "../../queries";
import { F_PERSON } from "../../queries/fragments";

export interface ILoginCmsData {
  loginCms: IPerson;
}

export interface ILoginCmsVars {
  data: {
    email: string;
    password: string;
    remember: boolean;
  };
}

export const LOGIN = gql`
  ${F_PERSON}
  mutation loginCms($data: LoginCmsInput!) {
    loginCms(data: $data) {
      ...FPerson
    }
  }
`;
