import { gql } from "@apollo/client";
import { IMedia } from "../../queries";
import { TAgeAppropriate } from "../../../utils";

export interface ICreateInteractiveTour {
  id: string;
  duration: number | null;
  ageAppropriate: TAgeAppropriate | null;
  difficulty: {
    id: string;
  } | null;
  published: boolean;
  gallery: IMedia[] | undefined;
  locale: {
    id: string;
    title: string | null;
    description: string | null;
    introduction: string | null;
    conclusion: string | null;
    startingPoint: string | null;
    importantInfo: string | null;
  };
  created: string;
}

export interface ICreateInteractiveTourData {
  createInteractiveTour: ICreateInteractiveTour;
}
export interface ICreateInteractiveTourVars {
  data: {
    duration: number | undefined;
    difficultyId: number | undefined;
    ageAppropriate: string | undefined;
  };
  dataLocale: {
    title: string | undefined;
    description: string | undefined;
    introduction: string | undefined;
    conclusion: string | undefined;
    startingPoint: string | undefined;
    importantInfo: string | undefined;
    gallery: number[] | undefined;
  };
}

export const CREATE_INTERACTIVE_TOUR = gql`
  mutation createInteractiveTour(
    $data: InteractiveTourCreateInput!
    $dataLocale: InteractiveTourLocaleCreateInitialInput!
  ) {
    createInteractiveTour(data: $data, dataLocale: $dataLocale) {
      id
      duration
      ageAppropriate
      difficulty {
        id
      }
      published
      locale {
        id
        title
        description
        introduction
        conclusion
        startingPoint
        importantInfo
        gallery(localeSpecific: true) {
          id
          fileName
          bucketPath
          byteSize
          mimeType
          extension
        }
      }
      created
    }
  }
`;
