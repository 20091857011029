import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  name: {
    localise: true,
    inputProps: {
      required: true,
      label: "Naziv organizacije",
      placeholder: "Ime vašeg subjekta.",
      name: "subjectName",
    },
  },
  about: {
    localise: true,
    inputProps: {
      label: "O vašoj organizaciji",
      name: "about",
      required: true,
    },
    mainHelperText: "Kratak opis vaše organizacije i njenih aktivnosti.",
  },
  extraInfo: {
    localise: true,
    inputProps: {
      label: "Dodatne informacije",
      name: "extraInfo",
    },
    mainHelperText:
      "Tekst vezan za dodatne informacije koje treba dati posetiocu pre dolaska na lokaciju. Na primer, informacije mogu uključivati: mere bezbednosti, vreme dolaska posetioca na lokaciju, uputstva o ponašanju ili oblačenju na lokaciji, informacije o postojanju garderobe na lokaciji, dozvoljene stvari na lokaciji (ranac, torbe).",
  },
  openingHoursInfo: {
    localise: true,
    inputProps: {
      label: "Informacije o radnom vremenu",
      name: "openingHoursInfo",
    },
    mainHelperText:
      "Sve dodatne informacije o radnom vremenu. Na primer, da li organizacija radi tokom praznika?",
  },
  gallery: {
    inputProps: {
      label: "Galerija",
      required: true,
      name: "gallery",
    },
    rule: "media",
    mainLocaleRequiredOnly: true,
    localise: true,
  },
});
