import { gql } from "@apollo/client";
import { F_PERSON } from "../fragments";
import { IAdminRole } from "./role.query";

export interface IPerson {
  id: string;
  name: string;
  email: string;
  role: IAdminRole;
}

export interface IPersonData {
  currentUser: IPerson;
}

export const CURRENT_USER = gql`
  ${F_PERSON}
  query currentUser {
    currentUser {
      ...FPerson
    }
  }
`;
