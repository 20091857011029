import { TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { IStatisticsInteractiveTourCompleteness } from "../../apollo/queries";
import { format, intervalToDuration } from "date-fns";
import { minutesToHoursAndMinutes } from "../../utils";

interface IProps {
  item: IStatisticsInteractiveTourCompleteness;
  index: number;
}

export const CompletenessItem: FC<IProps> = (props) => {
  const { item, index } = props;

  return (
    <TableRow onClick={() => {}}>
      <TableCell>{index}</TableCell>
      <TableCell>{item.completed}</TableCell>
      <TableCell>{item.skipped}</TableCell>
      <TableCell>{item.ignored}</TableCell>
      <TableCell>
        {minutesToHoursAndMinutes(
          intervalToDuration({
            start: new Date(item.startedAt),
            end: new Date(item.completedAt),
          }).minutes
        )}
      </TableCell>
      <TableCell>
        {format(new Date(item.created), "dd.MM.yyyy. HH:mm'h'")}
      </TableCell>
    </TableRow>
  );
};
