import {
  CheckOutlined as CheckOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  PlayCircleFilledOutlined as PlayCircleFilledOutlinedIcon,
  PreviewOutlined as PreviewOutlinedIcon,
} from "@mui/icons-material";
import {
  ButtonBase,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { IMedia } from "../../../../apollo/queries";
import { ALLOWED_IMAGE_TYPES } from "../../../../utils";
import { useStyles } from "./MediaItem.styles";

interface IProps {
  isSelected?: number;
  onClick?: (event: any) => void;
  handleOpenDeleteDialog?: (event: any) => void;
  handleOpenPreviewDialog?: (event: any) => void;
  max?: number;
  item: IMedia;
}

export const MediaItem: FC<IProps> = (props) => {
  const {
    onClick,
    isSelected,
    handleOpenDeleteDialog,
    handleOpenPreviewDialog,
  } = props;
  const { bucketPath, fileName, mimeType } = props.item;
  const { classes } = useStyles();

  return (
    <Paper className={classes.mediaContainer}>
      <ButtonBase className={classes.button} focusRipple onClick={onClick}>
        {(ALLOWED_IMAGE_TYPES as string[]).includes(mimeType) ? (
          <img
            src={`${process.env.REACT_APP_BUCKET_ENDPOINT}${bucketPath}`}
            alt=""
            className={classes.image}
          />
        ) : (
          <div className={classes.center}>
            <PlayCircleFilledOutlinedIcon
              className={classes.audioIcon}
              color="secondary"
            />
          </div>
        )}
      </ButtonBase>
      <Typography className={classes.imageTitle}>{fileName}</Typography>
      {onClick ? (
        <>
          <Tooltip title="Izbriši medije">
            <IconButton
              size="small"
              color="secondary"
              className={classes.deleteIcon}
              onClick={handleOpenDeleteDialog}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Pregled">
            <IconButton
              size="small"
              color="secondary"
              className={classes.expandIcon}
              onClick={handleOpenPreviewDialog}
            >
              <PreviewOutlinedIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : null}

      {/* {onClick && _count.StationMedia + _count.TourMedia < 1 ? (
        <Tooltip title="This image is not used anywhere!">
          <IconButton
            size="small"
            color="warning"
            className={classes.warningIcon}
          >
            <WarningOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : null} */}
      {onClick && isSelected ? (
        <IconButton
          size="small"
          color="secondary"
          className={classes.selectedIcon}
        >
          <Typography>{isSelected}</Typography>
          <CheckOutlinedIcon fontSize="small" />
        </IconButton>
      ) : null}
    </Paper>
  );
};
