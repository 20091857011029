import { gql } from "@apollo/client";
import { IPerson } from "../person/currentUser.query";
import { F_PERSON } from "../fragments";

export interface IAdminData {
  oneAdministrator: IPerson;
}

export interface IAdminsData {
  allAdministrators: IPerson[];
}

export interface IAdminVars {
  id: number;
}

export const ONE_ADMIN = gql`
  ${F_PERSON}
  query oneAdministrator($id: ID!) {
    oneAdministrator(id: $id) {
      ...FPerson
    }
  }
`;

export const ALL_ADMINISTRATORS = gql`
  ${F_PERSON}
  query allAdministrators {
    allAdministrators {
      ...FPerson
    }
  }
`;
