import { FC } from "react";
import { Button, Paper, TextField, Typography } from "@mui/material";
import { useStyles } from "./ForgotPasswordPage.styles";
import { useSnackbar } from "notistack";
import { initialInputData } from "./ForgotPasswordPage.inputs";
import { useNavigate } from "react-router";
import {
  FORGOT_PASSWORD,
  IForgotPasswordData,
  IForgotPasswordVars,
} from "../../../apollo/mutations";
import { useMutation } from "@apollo/client";
import { LoadingBackdrop } from "../../../components";
import { useForm } from "../../../utils";

export const ForgotPasswordPage: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();
  const navigation = useNavigate();

  const { inputFields, inputFieldNames, validateForm } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const [forgotPasswordMutation, { loading }] = useMutation<
    IForgotPasswordData,
    IForgotPasswordVars
  >(FORGOT_PASSWORD, {
    onCompleted: ({ forgotPassword }) => {
      if (forgotPassword) {
        enqueueSnackbar(
          "Uspeh! Proverite email za link za resetovanje lozinke",
          {
            variant: "success",
          }
        );
        navigation("/login");
      } else {
        enqueueSnackbar("Greška! Molimo pokušajte ponovo", {
          variant: "error",
        });
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const handleSendPorgotPasswordEmail = () => {
    if (validateForm(inputFieldNames)) {
      forgotPasswordMutation({
        variables: { data: { email: inputFields.email.inputProps.value } },
      });
    }
  };

  const handleNavigateToLogin = () => {
    navigation("/login");
  };

  return (
    <main className={cx(classes.root)}>
      <Paper className={cx(classes.paper)}>
        <div>
          <Typography paragraph component="h1" variant="h5">
            Zaboravili ste lozinku?
          </Typography>
          <Typography paragraph>
            Molimo vas, unesite svoj email kako bismo vam poslali link za
            resetovanje lozinke.
          </Typography>
        </div>
        <TextField
          {...inputFields.email.inputProps}
          autoFocus
          margin="normal"
          autoComplete="username"
          fullWidth
        />
        <Button
          className={cx(classes.button)}
          variant="outlined"
          fullWidth
          onClick={handleNavigateToLogin}
        >
          Nazad na prijavu
        </Button>
        <Button
          variant="contained"
          fullWidth
          className={cx(classes.button)}
          onClick={handleSendPorgotPasswordEmail}
        >
          Pošaljite
        </Button>
      </Paper>
      <LoadingBackdrop loading={loading} />
    </main>
  );
};
