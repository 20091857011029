import {
  AddOutlined as AddOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
  InfoOutlined as InfoOutlinedIcon,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { ILanguageFlag } from "../../apollo/queries";
import {
  ContextProvider,
  ERROR_MAIN_LOCALE_NOT_FOUND,
  MAIN_ISO_LANGUAGE_CODE,
  useForm,
} from "../../utils";
import { DeleteDialog } from "../";
import { initialInputData } from "./FlagList.inputs";
import { useStyles } from "./FlagList.styles";

interface IProps {
  canSelectFlags?: boolean;
  deleteLocaleFlagMutation: () => void;
  activeLocale: string;
  setActiveLocale: Dispatch<SetStateAction<string>>;
  currentLanguageFlags: ILanguageFlag[];
  setCurrentLanguageFlags: Dispatch<SetStateAction<ILanguageFlag[]>>;
  type:
    | "Audio tura"
    | "Oznaka na mapi"
    | "Stanica"
    | "Organizacija"
    | "Interaktivna tura"
    | "Točka interesa"
    | "Obaveštenje";
  tooltip: string;
}

export const FlagList: FC<IProps> = (props) => {
  const {
    canSelectFlags,
    activeLocale,
    setActiveLocale,
    currentLanguageFlags,
    setCurrentLanguageFlags,
    deleteLocaleFlagMutation,
    type,
    tooltip,
  } = props;
  const { languageFlags } = useContext(ContextProvider);
  const { classes, cx } = useStyles();

  const {
    resetFields,
    inputFields,
    setInputField,
    inputFieldNames,
    validateForm,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [deleteFlagDialogOpen, setDeleteFlagDialogOpen] = useState(false);
  const [addNewLanguageDialogOpen, setAddNewLanguageDialogOpen] =
    useState(false);

  const handleOpenAddNewLanguageDialog = () => {
    setAddNewLanguageDialogOpen(true);
  };
  const handleCloseAddNewLanguageDialog = () => {
    setAddNewLanguageDialogOpen(false);
  };

  const handleOpenDeleteFlagDialog = () => {
    setDeleteFlagDialogOpen(true);
  };

  const handleCloseDeleteFlagDialog = () => {
    setDeleteFlagDialogOpen(false);
  };

  const handleDeleteLocaleFlag = () => {
    deleteLocaleFlagMutation();
    handleCloseDeleteFlagDialog();
  };

  useEffect(() => {
    if (!currentLanguageFlags.length) {
      const filteredFlags = languageFlags.filter(
        (flag) => flag.isoLanguageCode === MAIN_ISO_LANGUAGE_CODE
      );
      setCurrentLanguageFlags(filteredFlags);
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageFlags]);

  const handleAddLanguage = () => {
    const newLanguage = languageFlags.find(
      (x) => x.id === inputFields.languageFlag.inputProps.value
    );
    if (validateForm(inputFieldNames) && newLanguage) {
      setCurrentLanguageFlags([...currentLanguageFlags, newLanguage]);
      setActiveLocale(newLanguage.isoLanguageCode);
      handleCloseAddNewLanguageDialog();
      setInputField("languageFlag", "");
    }
  };

  const selectFilteredFlags = languageFlags.filter(
    ({ id }) => !currentLanguageFlags.some(({ id: id2 }) => id2 === id)
  );

  const handleResetFields = {
    onExited: () => resetFields(),
  };

  return (
    <div className={classes.root}>
      <div className={classes.flagsDiv}>
        {currentLanguageFlags.map((item) => {
          const handleSelectLocale = () => {
            setActiveLocale(item.isoLanguageCode);
          };
          return (
            <Tooltip key={item.id} title={item.locale?.name || ""}>
              <img
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${item.isoCountryCode}.svg`}
                alt={item.locale?.name || ERROR_MAIN_LOCALE_NOT_FOUND}
                width={32}
                className={cx(
                  canSelectFlags ? classes.flag : classes.mainFlag,
                  item.isoLanguageCode === activeLocale
                    ? classes.selectedFlag
                    : ""
                )}
                onClick={canSelectFlags ? handleSelectLocale : undefined}
              />
            </Tooltip>
          );
        })}
      </div>
      {canSelectFlags ? (
        <>
          {activeLocale !== MAIN_ISO_LANGUAGE_CODE ? (
            <Button
              onClick={handleOpenDeleteFlagDialog}
              size="small"
              variant="outlined"
              startIcon={<DeleteOutlineIcon />}
            >
              Ukloni | {activeLocale} |
            </Button>
          ) : null}
          <Button
            onClick={handleOpenAddNewLanguageDialog}
            size="small"
            className={classes.deleteButton}
            variant="outlined"
            startIcon={<AddOutlinedIcon />}
          >
            Dodaj jezik
          </Button>
          <Tooltip title={tooltip}>
            <InfoOutlinedIcon color="primary" />
          </Tooltip>
        </>
      ) : (
        <Typography color="textSecondary">
          Vaša {type} će biti kreirana na srpskom, ali ćete moći dodati nove
          jezike nakon kreiranja.
        </Typography>
      )}
      <Dialog
        open={addNewLanguageDialogOpen}
        onClose={handleCloseAddNewLanguageDialog}
        maxWidth="sm"
        fullWidth
        TransitionProps={handleResetFields}
      >
        <DialogTitle>Dodajte novi jezik u {type}</DialogTitle>
        <DialogContent>
          <TextField
            {...inputFields.languageFlag.inputProps}
            margin="normal"
            fullWidth
            SelectProps={{
              renderValue: (value) => {
                const getFlag = selectFilteredFlags.find((x) => x.id === value);
                if (!getFlag) {
                  return <></>;
                }
                return (
                  <MenuItem dense>
                    <ListItemIcon>
                      <img
                        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${getFlag.isoCountryCode}.svg`}
                        alt={
                          getFlag.locale?.name || ERROR_MAIN_LOCALE_NOT_FOUND
                        }
                        width={32}
                        className={classes.mainFlag}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      {getFlag.locale?.name} - {getFlag.isoLanguageCode}
                    </ListItemText>
                  </MenuItem>
                );
              },
            }}
            select
          >
            {selectFilteredFlags.length ? (
              selectFilteredFlags.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  <ListItemIcon>
                    <img
                      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${item.isoCountryCode}.svg`}
                      alt={item.locale?.name || ERROR_MAIN_LOCALE_NOT_FOUND}
                      width={32}
                      className={classes.mainFlag}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    {item.locale?.name} - {item.isoLanguageCode}
                  </ListItemText>
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                Nema više dostupnih jezika
              </MenuItem>
            )}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddNewLanguageDialog}>Otkaži</Button>
          <Button variant="outlined" onClick={handleAddLanguage}>
            Dodaj
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteDialog
        description="Da li ste sigurni da želite da uklonite izabrani jezik?"
        title="Izbriši izabrani jezik"
        open={deleteFlagDialogOpen}
        onClose={handleCloseDeleteFlagDialog}
        mutation={handleDeleteLocaleFlag}
      />
    </div>
  );
};
