import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  root: {
    paddingTop: theme.spacing(12),
    minHeight: "100vh",
    maxHeight: "100vh",
    width: "100%",
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    overflow: "hidden",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  displayFlex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center",
  },
}));
