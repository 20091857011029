import { FC, useState } from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useStyles } from "./ResetPasswordPage.styles";
import { useSnackbar } from "notistack";
import { initialInputData } from "./ResetPasswordPage.inputs";
import { useParams } from "react-router";
import {
  RESET_PASSWORD,
  IResetPasswordData,
  IResetPasswordVars,
} from "../../../apollo/mutations";
import { useMutation } from "@apollo/client";
import { LoadingBackdrop } from "../../../components";
import { useForm } from "../../../utils/hooks/useForm";
import {
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from "@mui/icons-material";

export const ResetPasswordPage: FC = () => {
  const { token } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();
  const [seePassword, setSeePassword] = useState(false);
  const [seePasswordRepeat, setSeePasswordRepeat] = useState(false);

  const handleToggleSeePassword = () => {
    setSeePassword(!seePassword);
  };

  const handleToggleSeePasswordRepeat = () => {
    setSeePasswordRepeat(!seePasswordRepeat);
  };

  const { inputFields, inputFieldNames, validateForm } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const [resetPasswordMutation, { loading, data }] = useMutation<
    IResetPasswordData,
    IResetPasswordVars
  >(RESET_PASSWORD, {
    onCompleted: ({ resetPassword }) => {
      if (resetPassword) {
        enqueueSnackbar("Lozinka je resetovana.", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Greška! Molimo pokušajte ponovo", {
          variant: "error",
        });
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const handleResetPassword = () => {
    if (validateForm(inputFieldNames)) {
      if (!token) {
        enqueueSnackbar("Token nije pronađen!", { variant: "error" });
        return;
      }
      resetPasswordMutation({
        variables: {
          data: {
            token: token,
            password: inputFields.password.inputProps.value,
            passwordRepeat: inputFields.passwordRepeat.inputProps.value,
          },
        },
      });
    }
  };

  // const handleNavigateToForgotPassword = () => {
  //   navigation("/forgot-password");
  // };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <main className={cx(classes.root)}>
      <Paper className={cx(classes.paper)}>
        <div>
          <Typography paragraph component="h1" variant="h5">
            Resetujte lozinku
          </Typography>

          <Typography paragraph>
            {data?.resetPassword
              ? "Vaša lozinka je uspešno resetovana!"
              : "Unesite novu lozinku."}
          </Typography>
        </div>
        {data?.resetPassword ? null : (
          <>
            <TextField
              {...inputFields.password.inputProps}
              autoFocus
              margin="normal"
              fullWidth
              type={seePassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleToggleSeePassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {seePassword ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              {...inputFields.passwordRepeat.inputProps}
              margin="normal"
              fullWidth
              type={seePasswordRepeat ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleToggleSeePasswordRepeat}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {seePasswordRepeat ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <Button
          className={cx(classes.button)}
          variant="outlined"
          fullWidth
          onClick={handleNavigateToForgotPassword}
        >
          Resend email
        </Button> */}
            <Button
              variant="contained"
              fullWidth
              className={cx(classes.button)}
              onClick={handleResetPassword}
            >
              Reset Password
            </Button>
          </>
        )}
      </Paper>
      <LoadingBackdrop loading={loading} />
    </main>
  );
};
