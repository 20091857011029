import { FC, useEffect, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Dialog,
  MenuItem,
} from "@mui/material";
// import { useStyles } from "./UpsertOpeningHourDialog.styles";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import { initialInputData } from "./UpsertOpeningHourDialog.inputs";
import { IDialogContext, useForm } from "../../../../../utils";
import {
  ALL_OPENING_HOURS,
  IOpeningHourData,
  IOpeningHourVars,
  IOpeningHoursData,
  ONE_OPENING_HOUR,
} from "../../../../../apollo/queries";
import {
  CREATE_OPENING_HOUR,
  ICreateOpeningHourData,
  ICreateOpeningHourVars,
  IUpdateOpeningHourData,
  IUpdateOpeningHourVars,
  UPDATE_OPENING_HOUR,
} from "../../../../../apollo/mutations";
import {
  DataHandlerComponent,
  LoadingBackdrop,
} from "../../../../../components";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { format, isDate } from "date-fns";

interface IProps {
  onClose: () => void;
  open: boolean;
  type: IDialogContext["type"];
  id: undefined | string;
  refetch: any;
}

export const UpsertOpeningHourDialog: FC<IProps> = (props) => {
  const { onClose, open, type, id, refetch } = props;
  // const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    inputFields,
    resetFields,
    setInputField,
    getFormValuesFromFetchedData,
    inputFieldNames,
    validateForm,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [startHourValue, setStartHourValue] = useState<Date | null>(null);
  const [endHourValue, setEndHourValue] = useState<Date | null>(null);

  const handleChangeStart = (value: Date | null) => {
    setStartHourValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = format(value, "HH:mm");
        if (formatedTime) {
          setInputField("startHour", formatedTime);
        } else {
          setInputField("startHour", "");
        }
      } else setInputField("startHour", "");
    } catch (err) {
      // console.log(err);
    }
  };

  const handleChangeEnd = (value: Date | null) => {
    setEndHourValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = format(value, "HH:mm");
        if (formatedTime) {
          setInputField("endHour", formatedTime);
        } else {
          setInputField("endHour", "");
        }
      } else setInputField("endHour", "");
    } catch (err) {
      // console.log(err);
    }
  };

  const [queryOneOpeningHour, { loading, data, error }] = useLazyQuery<
    IOpeningHourData,
    IOpeningHourVars
  >(ONE_OPENING_HOUR);

  const [
    updateOpeningHourMutation,
    { loading: loadingUpdateOpeningHourMutation },
  ] = useMutation<IUpdateOpeningHourData, IUpdateOpeningHourVars>(
    UPDATE_OPENING_HOUR,
    {
      onCompleted: () => {
        enqueueSnackbar("Radno vreme je ažurirano.", {
          variant: "success",
        });
        onClose();
      },
      onError: (err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
    }
  );

  useEffect(() => {
    if (open && id && type === "update") {
      queryOneOpeningHour({ variables: { id: +id } });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id, type]);

  const clearFields = () => {
    setStartHourValue(null);
    setEndHourValue(null);
    resetFields();
  };

  const [
    createOpeningHourMutation,
    { loading: loadingCreateOpeningHourMutation },
  ] = useMutation<ICreateOpeningHourData, ICreateOpeningHourVars>(
    CREATE_OPENING_HOUR,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Kreirano novo radno vreme", {
          variant: "success",
        });
        refetch?.();
        onClose();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update: (cache, { data }) => {
        const existingListData: IOpeningHoursData | null = cache.readQuery({
          query: ALL_OPENING_HOURS,
        });
        if (data?.createOpeningHour) {
          const newTourData: any = {
            ...data.createOpeningHour,
          };
          cache.writeQuery({
            query: ALL_OPENING_HOURS,
            data: {
              allOpeningHours: existingListData?.allOpeningHours
                ? [newTourData, ...existingListData.allOpeningHours]
                : [newTourData],
            },
          });
        }
      },
    }
  );

  const handleUpdateOpeningHour = () => {
    if (!validateForm(inputFieldNames)) {
      return;
    }
    if (id && type === "update") {
      updateOpeningHourMutation({
        variables: {
          id: +id,
          data: {
            startHour: inputFields.startHour.inputProps.value,
            endHour: inputFields.endHour.inputProps.value,
            weekday: +inputFields.weekday.inputProps.value,
          },
        },
      });
    } else {
      enqueueSnackbar("Nešto je pošlo po zlu!", {
        variant: "error",
      });
    }
  };

  const handleCreateOpeningHour = () => {
    if (!validateForm(inputFieldNames)) {
      return;
    }
    if (type === "create") {
      createOpeningHourMutation({
        variables: {
          data: {
            startHour: inputFields.startHour.inputProps.value,
            endHour: inputFields.endHour.inputProps.value,
            weekday: +inputFields.weekday.inputProps.value,
          },
        },
      });
    } else {
      enqueueSnackbar("Nešto je pošlo po zlu!", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (data?.oneOpeningHour && open && type === "update") {
      getFormValuesFromFetchedData(
        data.oneOpeningHour,
        [
          {
            fromDataProperty: "weekday",
            toFormProperty: "weekday",
          },
          {
            fromDataProperty: "startHour",
            toFormProperty: "startHour",
          },
          {
            fromDataProperty: "endHour",
            toFormProperty: "endHour",
          },
        ],
        false,
        true
      );

      // console.log("getUpdatedInputs: ", getUpdatedInputs);
      setStartHourValue(
        new Date(`01/01/1970 ${data?.oneOpeningHour.startHour}`)
      );
      setEndHourValue(new Date(`01/01/1970 ${data?.oneOpeningHour.endHour}`));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    // formReady,
    open,
  ]);

  // console.log({ inputFields });

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited: clearFields }}
    >
      <DialogTitle>
        {type === "update" ? "Ažuriraj radno vreme" : "Dodaj novo radno vreme"}
      </DialogTitle>
      <DialogContent>
        <DataHandlerComponent
          loading={loading}
          error={Boolean(error)}
          skeletonHeight={60}
          skeletonNum={2}
          hasData={Boolean(type === "update" ? data?.oneOpeningHour : true)}
        >
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label={inputFields.startHour.inputProps.label}
                value={startHourValue}
                onChange={handleChangeStart}
                minutesStep={15}
                closeOnSelect
                ampm={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={inputFields.startHour.inputProps.error}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />
              <TimePicker
                label={inputFields.endHour.inputProps.label}
                value={endHourValue}
                onChange={handleChangeEnd}
                minutesStep={15}
                closeOnSelect
                ampm={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={inputFields.endHour.inputProps.error}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />
            </LocalizationProvider>

            <TextField
              {...inputFields.weekday.inputProps}
              margin="normal"
              fullWidth
              select
            >
              <MenuItem value={"1"}>Ponedeljak</MenuItem>
              <MenuItem value={"2"}>Utorak</MenuItem>
              <MenuItem value={"3"}>Sreda</MenuItem>
              <MenuItem value={"4"}>Četvrtak</MenuItem>
              <MenuItem value={"5"}>Petak</MenuItem>
              <MenuItem value={"6"}>Subota</MenuItem>
              <MenuItem value={"7"}>Nedelja</MenuItem>
            </TextField>
          </>
        </DataHandlerComponent>
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Otkaži
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={
            type === "update"
              ? handleUpdateOpeningHour
              : handleCreateOpeningHour
          }
        >
          {type === "update" ? "Ažuriraj" : "Kreiraj"}
        </Button>
      </DialogActions>
      <LoadingBackdrop
        loading={
          loadingUpdateOpeningHourMutation || loadingCreateOpeningHourMutation
        }
      />
    </Dialog>
  );
};
