import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { FC } from "react";
import { IMedia } from "../../../../apollo/queries";
import { ALLOWED_IMAGE_TYPES, IDialogContext } from "../../../../utils";
import { useStyles } from "./MediaPreviewDialog.styles";
import { Close as CloseIcon } from "@mui/icons-material";

interface IProps {
  onClose: () => void;
  open: boolean;
  type?: IDialogContext["type"];
  item: undefined | IMedia;
}

export const MediaPreviewDialog: FC<IProps> = (props) => {
  const { onClose, open, item } = props;
  const { classes } = useStyles();

  const PaperProps = {
    style: {
      backgroundColor: "#222",
      boxShadow: "none",
    },
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen PaperProps={PaperProps}>
      <div className={classes.title}>
        <DialogTitle className={classes.previewTitle}>
          {item?.fileName}
        </DialogTitle>
        <IconButton
          className={classes.iconButton}
          color="secondary"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <>
          {item && (ALLOWED_IMAGE_TYPES as string[]).includes(item.mimeType) ? (
            <img
              src={`${process.env.REACT_APP_BUCKET_ENDPOINT}${item?.bucketPath}`}
              alt=""
              className={classes.preview}
              onClick={onClose}
            />
          ) : (
            <div className={classes.center}>
              <audio
                src={`${process.env.REACT_APP_BUCKET_ENDPOINT}${item?.bucketPath}`}
                controls
                className={classes.audio}
              />
            </div>
          )}
        </>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Zatvori
        </Button>
      </DialogActions>
    </Dialog>
  );
};
