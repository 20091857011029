import { FC, useContext, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Dialog,
  MenuItem,
  Typography,
} from "@mui/material";
// import { useStyles } from "./UpsertAdminDialog.styles";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import { initialInputData } from "./UpsertAdminDialog.inputs";
import { ContextProvider, IDialogContext, useForm } from "../../../../../utils";
import {
  ALL_ADMIN_ROLES,
  ALL_PERSON_INVITATIONS,
  IAdminData,
  IAdminRolesData,
  IAdminVars,
  IPersonInvitation,
  IPersonInvitationsData,
  ONE_ADMIN,
} from "../../../../../apollo/queries";
import {
  CREATE_PERSON_INVITATION,
  ICreatePersonInvitationData,
  ICreatePersonInvitationVars,
  IUpdateAdminData,
  IUpdateAdminVars,
  UPDATE_ADMIN,
} from "../../../../../apollo/mutations";
import {
  DataHandlerComponent,
  LoadingBackdrop,
} from "../../../../../components";

interface IProps {
  onClose: () => void;
  open: boolean;
  type: IDialogContext["type"];
  id: undefined | string;
}

export const UpsertAdminDialog: FC<IProps> = (props) => {
  const { onClose, open, type, id } = props;
  const { user } = useContext(ContextProvider);
  // const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    inputFields,
    resetFields,
    validateForm,
    didValuesChange,
    getFormValuesFromFetchedData,
    handleDataToVar,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [
    queryAllAdminRoles,
    {
      loading: loadingAdminRoles,
      data: dataAdminRoles,
      error: errorAdminRoles,
    },
  ] = useLazyQuery<IAdminRolesData>(ALL_ADMIN_ROLES);

  const [queryOneAdmin, { loading, data, error }] = useLazyQuery<
    IAdminData,
    IAdminVars
  >(ONE_ADMIN);

  const [updateAdminMutation, { loading: loadingUpdateAdminMutation }] =
    useMutation<IUpdateAdminData, IUpdateAdminVars>(UPDATE_ADMIN, {
      onCompleted: () => {
        enqueueSnackbar("Administrator ažuriran.", {
          variant: "success",
        });
        onClose();
      },
      onError: (err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
    });

  useEffect(() => {
    if (open) {
      if (id && type === "update") {
        queryOneAdmin({ variables: { id: +id } });
      }
      queryAllAdminRoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, id, type]);

  const clearFields = () => {
    resetFields();
  };

  const [
    createPersonInvitationMutation,
    { loading: loadingCreatePersonInvitationMutation },
  ] = useMutation<ICreatePersonInvitationData, ICreatePersonInvitationVars>(
    CREATE_PERSON_INVITATION,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Nova pozivnica poslana na ${data.inviteAdmin.email}. Pozivnica je validna 7 dana!`,
          {
            variant: "success",
          }
        );
        onClose();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update: (cache, { data }) => {
        const existingListData: IPersonInvitationsData | null = cache.readQuery(
          {
            query: ALL_PERSON_INVITATIONS,
          }
        );
        if (data?.inviteAdmin) {
          const newInvitationData: IPersonInvitation = {
            ...data.inviteAdmin,
          };
          cache.writeQuery({
            query: ALL_PERSON_INVITATIONS,
            data: {
              allPersonInvitations: existingListData?.allPersonInvitations
                ? [newInvitationData, ...existingListData.allPersonInvitations]
                : [newInvitationData],
            },
          });
        }
      },
    }
  );

  const handleUpdateAdmin = () => {
    if (!validateForm(["name", "role"])) {
      return;
    }
    if (id && type === "update") {
      updateAdminMutation({
        variables: {
          id: +id,
          data: {
            name: handleDataToVar("name", "string", false),
            roleId: handleDataToVar("role", "number", false),
          },
        },
      });
    }
  };

  const handleInviteAdmin = () => {
    if (!validateForm(["email", "role"])) {
      return;
    }
    if (type === "create") {
      createPersonInvitationMutation({
        variables: {
          data: {
            email: inputFields.email.inputProps.value,
            roleId: +inputFields.role.inputProps.value,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (data?.oneAdministrator && open && type === "update") {
      getFormValuesFromFetchedData(
        data.oneAdministrator,
        [
          {
            fromDataProperty: "role.id",
            toFormProperty: "role",
          },
          {
            fromDataProperty: "name",
            toFormProperty: "name",
          },
          //TODO: Email update?
        ],
        false
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, open]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited: clearFields }}
    >
      <DialogTitle>
        {type === "update"
          ? "Ažurirajte administratore"
          : "Pozovi novog administrator"}
      </DialogTitle>
      <DialogContent>
        <DataHandlerComponent
          loading={loading || loadingAdminRoles}
          error={Boolean(error || errorAdminRoles)}
          skeletonHeight={60}
          skeletonNum={2}
          hasData={Boolean(
            type === "update"
              ? data?.oneAdministrator && dataAdminRoles?.allAdminRoles?.length
              : dataAdminRoles?.allAdminRoles?.length
          )}
        >
          <>
            {type === "create" ? (
              <Typography color="textSecondary">
                Kada pozovete novog administratora, pozivni link će biti poslat
                na njihov email.
              </Typography>
            ) : null}

            {type === "create" ? (
              <TextField
                {...inputFields.email.inputProps}
                margin="normal"
                fullWidth
              />
            ) : (
              <TextField
                {...inputFields.name.inputProps}
                margin="normal"
                autoFocus
                fullWidth
              />
            )}
            <TextField
              disabled={user?.id === id}
              {...inputFields.role.inputProps}
              margin="normal"
              fullWidth
              select
            >
              {dataAdminRoles?.allAdminRoles?.length ? (
                dataAdminRoles.allAdminRoles.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.locale?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Učitavanje...</MenuItem>
              )}
            </TextField>

            {inputFields.role.inputProps.value &&
            dataAdminRoles?.allAdminRoles?.length ? (
              <Typography variant="caption">
                {
                  dataAdminRoles.allAdminRoles.find(
                    (x) => x.id === inputFields.role.inputProps.value
                  )?.locale?.description
                }
              </Typography>
            ) : null}
          </>
        </DataHandlerComponent>
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Otkaži
        </Button>
        <Button
          disabled={!didValuesChange(["name", "role"])}
          variant="contained"
          color="primary"
          onClick={type === "update" ? handleUpdateAdmin : handleInviteAdmin}
        >
          {type === "update" ? "Ažuriraj" : "Pozovi"}
        </Button>
      </DialogActions>
      <LoadingBackdrop
        loading={
          loadingUpdateAdminMutation || loadingCreatePersonInvitationMutation
        }
      />
    </Dialog>
  );
};
