import { createTypedDynamicFormInputs } from "../../../utils/formUtils";

export const initialInputData = createTypedDynamicFormInputs({
  title: {
    inputProps: {
      name: "ime",
      label: "Naslov",
      placeholder: "Privlačan naslov",
      required: true,
    },
    mainHelperText:
      "Ne uključujte numeraciju tačaka interesa. Dodaje se automatski.",
    localise: true,
  },
  description: {
    localise: true,
    mainHelperText:
      "Ovaj kratak opis opisuje opštu temu i šta posetioci mogu očekivati tokom interaktivne ture.",
    inputProps: {
      name: "description",
      label: "Opis",
      placeholder: "Kratak opis ove interaktivne ture",
      required: true,
    },
  },
  introduction: {
    localise: true,
    mainHelperText:
      "Uvod interaktivne ture služi kao kratak rezime cele njene naracije.",
    inputProps: {
      name: "interactiveTourIntroduction",
      label: "Uvod u interaktivnu turu.",
      placeholder: "",
      required: true,
    },
  },
  conclusion: {
    localise: true,
    mainHelperText:
      "Zaključak interaktivne ture služi kao kratak epilog cele priče.",
    inputProps: {
      name: "interactiveTourConclusion",
      label: "Zaključak interaktivne ture",
      placeholder: "",
      required: true,
    },
  },
  startingPoint: {
    localise: true,
    mainHelperText: "Gde je početna tačka prve tačke interesa?",
    inputProps: {
      name: "startingPoint",
      label: "Početna tačka",
      placeholder: "Recepcija",
      required: true,
    },
  },
  importantInfo: {
    localise: true,
    mainHelperText: "Dodajte ovde važne informacije.",
    inputProps: {
      name: "importantInformation",
      label: "Važne informacije",
      placeholder: "Igrano u i oko Palate Nauke",
    },
  },
  duration: {
    inputProps: {
      name: "duration",
      label: "Trajanje",
      placeholder: "30",
      required: true,
    },
    regexPattern: /^(?:[1-9][0-9]{0,2})?$/,
    mainHelperText:
      "Koliko minuta je potrebno da se završi interaktivna tura u proseku.",
  },
  difficulty: {
    inputProps: {
      name: "difficulty",
      label: "Težina",
      required: true,
    },
    mainHelperText: "Koliko je teško završiti turu?",
  },
  gallery: {
    localise: true,
    inputProps: {
      name: "gallery",
      label: "Galerija",
      required: true,
    },
    mainHelperText: `Odaberite medijske fajlove koji će biti prikazani za opisivanje interaktivne ture na izabranom jeziku. Ako nije specificirano, koristiće se iste slike kao u turi na srpskom.`,
    rule: "media",
    mainLocaleRequiredOnly: true,
  },
  ageAppropriate: {
    inputProps: {
      name: "ageAppropriate",
      label: "Prikladno za uzrast",
      required: true,
    },
    mainHelperText: "Za koju starosnu grupu je ovaj sadržaj prikladan?",
  },
});
