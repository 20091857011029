import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useStyles } from "./CheckpointPage.styles";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import {
  DataHandlerComponent,
  FlagList,
  LoadingBackdrop,
  PageLayout,
  ReadyToPublish,
} from "../../../components";
import { useGlobalStyles } from "../../../utils/theme";
import {
  ERouterPaths,
  IMediaSelectDialogContext,
  INPUT_CHECKPOINT_CHALLENGE_HINT_MAX_LENGTH,
  INPUT_CHECKPOINT_CHALLENGE_MAX_LENGTH,
  INPUT_CHECKPOINT_CLOSING_MAX_LENGTH,
  INPUT_CHECKPOINT_DIRECTIONS_HINT_MAX_LENGTH,
  INPUT_CHECKPOINT_DIRECTIONS_MAX_LENGTH,
  INPUT_CHECKPOINT_FACTS_MAX_LENGTH,
  INPUT_CHECKPOINT_OPENING_MAX_LENGTH,
  INPUT_CHECKPOINT_SOLUTION_MAX_LENGTH,
  INPUT_CHECKPOINT_TITLE_MAX_LENGTH,
  joinClassNames,
  MAIN_ISO_LANGUAGE_CODE,
  MSG_UNSAVED_CHANGES,
} from "../../../utils";
import { initialInputData } from "./CheckpointPage.inputs";
import {
  ArrowBackOutlined,
  ChevronRightOutlined as ChevronRightOutlinedIcon,
} from "@mui/icons-material";
import { ChevronLeftOutlined as ChevronLeftOutlinedIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import {
  Reference,
  StoreObject,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import {
  CREATE_CHECKPOINT,
  CREATE_CHECKPOINT_LOCALE,
  DELETE_CHECKPOINT_LOCALE,
  ICreateCheckpointData,
  ICreateCheckpointLocaleData,
  ICreateCheckpointLocaleVars,
  ICreateCheckpointVars,
  IDeleteCheckpointLocaleData,
  IDeleteCheckpointLocaleVars,
  IUpdateCheckpointLocaleData,
  IUpdateCheckpointLocaleVars,
  IUpdateCheckpointPublishedData,
  IUpdateCheckpointPublishedVars,
  UPDATE_CHECKPOINT_LOCALE,
  UPDATE_CHECKPOINT_PUBLISHED,
} from "../../../apollo/mutations";
import {
  useNavigate,
  // useNavigation,
  useParams,
} from "react-router";
import {
  ICheckpoint,
  ICheckpointDetailsData,
  ICheckpointDetailsVars,
  IInteractiveTourDetailsData,
  ONE_CHECKPOINT_DETAILS,
  ONE_INTERACTIVE_TOUR_DETAILS,
} from "../../../apollo/queries";
import { useForm } from "../../../utils/hooks/useForm";
import {
  MediaSelectButton,
  MediaSelectorDialog,
} from "../../../components/mediaSelectorContent/components";
import { useSearchParams } from "react-router-dom";
// import { useBeforeUnload } from "react-router-dom";

export const CheckpointPage: FC = () => {
  const { classes } = useStyles();
  const { cx, classes: globalClasses } = useGlobalStyles();
  const { id: interactiveTourId, checkpointId } = useParams();
  const navigate = useNavigate();
  // const navigation = useNavigation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const isDataSet = useRef(false);

  // console.log(matchPath(navigation.location?.pathname, navigation));

  // useEffect(() => {
  //   const unloadCallback = (event: any) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //     return "";
  //   };

  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => {
  //     // window.addEventListener("popstate", confirmation());
  //     window.removeEventListener("beforeunload", unloadCallback);
  //   };
  // }, []);

  const TOTAL_TABS = "6";
  const isEdit = checkpointId !== undefined;

  const [activeTab, setActiveTab] = useState("1");

  const {
    inputFields,
    // setInputFields,
    activeLocale,
    currentLanguageFlags,
    formError,
    getFormValuesFromFetchedData,
    handleDataToVar,
    inputFieldNames,
    setActiveLocale,
    validateForm,
    setCurrentLanguageFlags,
    didValuesChange,
    resetFields,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  //TODO: Chekc for performance improvements
  // unstable_usePrompt({
  //   when: didValuesChange(),
  //   message:
  //     "Are you sure you want to navigate away from this page? You have some unsaved changes!",
  // });

  const [mediaSelectDialogOpen, setMediaSelectDialogOpen] =
    useState<IMediaSelectDialogContext>({
      open: false,
      type: undefined,
      max: undefined,
      onChange: undefined,
      selectedMedia: [],
    });

  const handleCloseMediaSelectDialog = () => {
    setMediaSelectDialogOpen((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const [queryOneCheckpointDetails, { loading, data, error }] = useLazyQuery<
    ICheckpointDetailsData,
    ICheckpointDetailsVars
  >(ONE_CHECKPOINT_DETAILS);

  const getUpdateLocaleId = isEdit
    ? data?.oneCheckpointDetails.allLocales?.find(
        (x) => x.languageFlag?.isoLanguageCode === activeLocale
      )?.id
    : undefined;

  useEffect(() => {
    if (searchParams) {
      const getQueryTab = searchParams.get("activeTab");
      if (getQueryTab) {
        setActiveTab(getQueryTab);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    createCheckpointMutation,
    { loading: loadingCreateCheckpointMutation },
  ] = useMutation<ICreateCheckpointData, ICreateCheckpointVars>(
    CREATE_CHECKPOINT,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Kreirana je nova tačka interesa: ${
            data.createCheckpoint.locale.title || "Neimenovana tačka interesa"
          }`,
          {
            variant: "success",
          }
        );
        // resetFields()
        navigate(
          `/${ERouterPaths.INTERACTIVE_TOURS}/${interactiveTourId}?activeTab=3`,
          {}
        );
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update: (cache, { data }) => {
        if (!interactiveTourId || !data?.createCheckpoint) {
          enqueueSnackbar(
            "Nije uspelo pisanje podataka u keš. Osvežite stranicu da biste videli nedavne promene.",
            { variant: "error" }
          );
          return;
        }
        const existingData: IInteractiveTourDetailsData | null =
          cache.readQuery({
            query: ONE_INTERACTIVE_TOUR_DETAILS,
            variables: {
              id: +interactiveTourId,
            },
          });
        const newItem: ICheckpoint = {
          ...data.createCheckpoint,
        };
        // console.log("EXISTING: ", existingData);
        // console.log("NEW: ", newItem);
        cache.writeQuery({
          query: ONE_INTERACTIVE_TOUR_DETAILS,
          variables: {
            id: +interactiveTourId,
          },
          data: {
            oneInteractiveTourDetails: {
              ...existingData?.oneInteractiveTourDetails,
              allCheckpoints: existingData?.oneInteractiveTourDetails
                ?.allCheckpoints
                ? [
                    ...existingData.oneInteractiveTourDetails.allCheckpoints,
                    newItem,
                  ]
                : [newItem],
            },
          },
        });
        // console.log("CACHE: ", cache);
      },
    }
  );

  const [
    updateCheckpointLocaleMutation,
    { loading: loadingUpdateCheckpointLocaleMutation },
  ] = useMutation<IUpdateCheckpointLocaleData, IUpdateCheckpointLocaleVars>(
    UPDATE_CHECKPOINT_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Lokalizacija je ažurirana: ${
            data.updateCheckpointLocale.title || "Neimenovana tačka interesa"
          }`,
          {
            variant: "success",
          }
        );
        resetFields(inputFieldNames, true);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    }
  );

  // const [
  //   updateCheckpointOtherMutation,
  //   { loading: loadingUpdateCheckpointOtherMutation },
  // ] = useMutation<IUpdateCheckpointOtherData, IUpdateCheckpointOtherVars>(
  //   UPDATE_CHECKPOINT_OTHER,
  //   {
  //     onCompleted: (data) => {
  //       enqueueSnackbar("Ostale informacije su ažurirane", {
  //         variant: "success",
  //       });
  //     },
  //     onError: (error) => {
  //       enqueueSnackbar(error.message, {
  //         variant: "error",
  //       });
  //     },
  //   }
  // );

  const [
    createCheckpointLocaleMutation,
    { loading: loadingCreateCheckpointLocaleMutation },
  ] = useMutation<ICreateCheckpointLocaleData, ICreateCheckpointLocaleVars>(
    CREATE_CHECKPOINT_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Lokalizacija je kreirana: ${
            data.createCheckpointLocale.title || "Neimenovana tačka interesa"
          }`,
          {
            variant: "success",
          }
        );
        resetFields(inputFieldNames, true);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update: (cache, { data: dataCreate }) => {
        // console.log(cache);
        if (data?.oneCheckpointDetails) {
          cache.modify({
            id: cache.identify(
              data.oneCheckpointDetails as unknown as StoreObject
            ),
            fields: {
              allLocales(existingData: Array<Reference>, { readField }) {
                if (data) {
                  if (existingData && dataCreate) {
                    return [
                      ...existingData,
                      {
                        // @ts-ignore
                        __ref: `${dataCreate.createCheckpointLocale.__typename}:${dataCreate.createCheckpointLocale.id}`,
                      },
                    ];
                  }
                }
              },
            },
          });
        }
      },
    }
  );

  const [
    updateCheckpointPublishedMutation,
    { loading: loadingUpdateCheckpointPublishedMutation },
  ] = useMutation<
    IUpdateCheckpointPublishedData,
    IUpdateCheckpointPublishedVars
  >(UPDATE_CHECKPOINT_PUBLISHED, {
    onCompleted: (data) => {
      if (!data.updateCheckpointPublished) {
        enqueueSnackbar(`Checkpoint spremno za objavljivanje!`, {
          variant: "info",
        });
        // setCheckPassedPublishTest(true);
      } else {
        enqueueSnackbar(
          `Checkpoint is now ${
            data.updateCheckpointPublished.published
              ? "objavljena"
              : "neobjavljena"
          }!`,
          {
            variant: "success",
          }
        );
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (checkpointId) {
      queryOneCheckpointDetails({ variables: { id: +checkpointId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkpointId]);

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setActiveTab(newTab);
    setSearchParams({ activeTab: newTab }, { replace: true });
  };

  const handleNextTab = () => {
    const nextTab = (parseInt(activeTab) + 1).toString();
    setActiveTab(nextTab);
    setSearchParams({ activeTab: nextTab }, { replace: true });
  };

  const handlePrevTab = () => {
    const prevTab = (parseInt(activeTab) - 1).toString();
    setActiveTab(prevTab);
    setSearchParams({ activeTab: prevTab }, { replace: true });
  };

  const handleCreateCheckpoint = () => {
    if (validateForm(inputFieldNames, false) && interactiveTourId) {
      createCheckpointMutation({
        variables: {
          data: {
            interactiveTourId: +interactiveTourId,
            puzzleType: handleDataToVar("puzzleType", "string", false),
          },
          dataLocale: {
            title: handleDataToVar("title", "string", false),
            directions: handleDataToVar("directions", "string", false),
            directionsHint: handleDataToVar("directionsHint", "string", false),
            opening: handleDataToVar("opening", "string", false),
            challenge: handleDataToVar("challenge", "string", false),
            challengeHint: handleDataToVar("challengeHint", "string", false),
            solution: handleDataToVar("solution", "string", false),
            closing: handleDataToVar("closing", "string", false),
            facts: handleDataToVar("facts", "string", false),
            factsGallery: handleDataToVar("factsGallery", "stringArray", false),
            directionsHintGallery: handleDataToVar(
              "directionsHintGallery",
              "stringArray",
              false
            ),
            challengeHintGallery: handleDataToVar(
              "challengeHintGallery",
              "stringArray",
              false
            ),
            solutionHelperMediaId: handleDataToVar(
              "challengeHintGallery",
              "number",
              false
            ),
          },
        },
      });
    } else {
      enqueueSnackbar("Nešto je pošlo po zlu", { variant: "error" });
    }
  };

  useEffect(() => {
    // console.log("USEEFFECT!!!!!!===================");
    // if (fetchDataDone.current) {
    //   return;
    // }
    if (!data?.oneCheckpointDetails || isDataSet.current) {
      return;
    }
    getFormValuesFromFetchedData(
      data.oneCheckpointDetails,
      [
        {
          fromDataProperty: "puzzleType",
          toFormProperty: "puzzleType",
        },
        {
          fromDataProperty: "allLocales.title",
          toFormProperty: "title",
        },
        {
          fromDataProperty: "allLocales.directions",
          toFormProperty: "directions",
        },
        {
          fromDataProperty: "allLocales.directionsHint",
          toFormProperty: "directionsHint",
        },
        {
          fromDataProperty: "allLocales.directionsHintGallery.id",
          toFormProperty: "directionsHintGallery",
        },
        {
          fromDataProperty: "allLocales.opening",
          toFormProperty: "opening",
        },
        {
          fromDataProperty: "allLocales.challenge",
          toFormProperty: "challenge",
        },
        {
          fromDataProperty: "allLocales.challengeHint",
          toFormProperty: "challengeHint",
        },
        {
          fromDataProperty: "allLocales.challengeHintGallery.id",
          toFormProperty: "challengeHintGallery",
        },
        {
          fromDataProperty: "allLocales.solutionHelperMedia.id",
          toFormProperty: "solutionHelperMedia",
        },
        {
          fromDataProperty: "allLocales.solution",
          toFormProperty: "solution",
        },
        {
          fromDataProperty: "allLocales.closing",
          toFormProperty: "closing",
        },
        {
          fromDataProperty: "allLocales.facts",
          toFormProperty: "facts",
        },
        {
          fromDataProperty: "allLocales.factsGallery.id",
          toFormProperty: "factsGallery",
        },
      ],
      true,
      true
    );
    isDataSet.current = true;
    // fetchDataDone.current = true;

    // console.log("ONECHECKPOINTDETAILS CHANGED", data?.oneCheckpointDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.oneCheckpointDetails]);

  const handleUpdateLocale = () => {
    if (validateForm(inputFieldNames, false)) {
      if (getUpdateLocaleId) {
        updateCheckpointLocaleMutation({
          variables: {
            id: +getUpdateLocaleId,
            puzzleType: handleDataToVar("puzzleType", "string"),
            data: {
              title: handleDataToVar("title", "string"),
              directions: handleDataToVar("directions", "string"),
              directionsHint: handleDataToVar("directionsHint", "string"),
              opening: handleDataToVar("opening", "string"),
              challenge: handleDataToVar("challenge", "string"),
              challengeHint: handleDataToVar("challengeHint", "string"),
              solution: handleDataToVar("solution", "string"),
              closing: handleDataToVar("closing", "string"),
              facts: handleDataToVar("facts", "string"),
              factsGallery: handleDataToVar("factsGallery", "stringArray"),
              solutionHelperMediaId: handleDataToVar(
                "solutionHelperMedia",
                "number"
              ),
              directionsHintGallery: handleDataToVar(
                "directionsHintGallery",
                "number"
              ),
              challengeHintGallery: handleDataToVar(
                "challengeHintGallery",
                "stringArray"
              ),
            },
          },
        });
      } else if (checkpointId) {
        // console.log(
        //   handleDataToVar("challengeHintGallery", "stringArray", false)
        // );
        createCheckpointLocaleMutation({
          variables: {
            checkpointId: +checkpointId,
            puzzleType: handleDataToVar("puzzleType", "string", false),
            data: {
              title: handleDataToVar("title", "string", false),
              directions: handleDataToVar("directions", "string", false),
              directionsHint: handleDataToVar(
                "directionsHint",
                "string",
                false
              ),
              opening: handleDataToVar("opening", "string", false),
              challenge: handleDataToVar("challenge", "string", false),
              challengeHint: handleDataToVar("challengeHint", "string", false),
              solution: handleDataToVar("solution", "string", false),
              closing: handleDataToVar("closing", "string", false),
              facts: handleDataToVar("facts", "string", false),
              factsGallery: handleDataToVar(
                "factsGallery",
                "stringArray",
                false
              ),
              directionsHintGallery: handleDataToVar(
                "directionsHintGallery",
                "stringArray",
                false
              ),
              challengeHintGallery: handleDataToVar(
                "challengeHintGallery",
                "stringArray",
                false
              ),
              solutionHelperMediaId: handleDataToVar(
                "solutionHelperMedia",
                "number",
                false
              ),
              isoLanguageCode: activeLocale,
            },
          },
        });
      } else {
        enqueueSnackbar("Nešto je pošlo po zlu.", {
          variant: "error",
        });
      }
    }
  };

  // const handleUpdateOther = () => {
  //   const fields = ["gallery"];
  //   const result = validateForm(fields, inputFields, localeSelected);
  //   if (result.formValid) {
  //     if (isEdit && checkpointId) {
  //       // const preparedMedia: { id: number }[] =
  //       //   inputFields.gallery.inputProps.value.map((x: IMedia) => {
  //       //     return { id: +x.id };
  //       //   });
  //       updateCheckpointOtherMutation({
  //         variables: {
  //           id: +checkpointId,
  //           data: {
  //             // gallery: preparedMedia,
  //           },
  //         },
  //       });
  //     } else {
  //       enqueueSnackbar("ID nije pronađen!", {
  //         variant: "error",
  //       });
  //     }
  //   } else {
  //     enqueueSnackbar("Required fields are not set", {
  //       variant: "error",
  //     });
  //     setInputFields((prevState) => ({ ...prevState, ...result.outputData }));
  //   }
  // };

  const [
    deleteCheckpointLocaleMutation,
    { loading: loadingDeleteCheckpointLocaleMutation },
  ] = useMutation<IDeleteCheckpointLocaleData, IDeleteCheckpointLocaleVars>(
    DELETE_CHECKPOINT_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Jezik tačke interesa je obrisan", {
          variant: "success",
        });
        resetFields();
        setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
        const newCurrentFlags = currentLanguageFlags.filter(
          (x) => x.id !== data.deleteCheckpointLocale.localeId
        );
        setCurrentLanguageFlags(newCurrentFlags);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update(cache, { data: dataCreate }) {
        if (data?.oneCheckpointDetails) {
          cache.modify({
            id: cache.identify(
              data.oneCheckpointDetails as unknown as StoreObject
            ),
            fields: {
              allLocales(existingData: Array<Reference>, { readField }) {
                if (data) {
                  if (existingData && dataCreate) {
                    return existingData.filter(
                      (taskRef) =>
                        dataCreate.deleteCheckpointLocale.id !==
                        readField("id", taskRef)
                    );
                  }
                }
              },
            },
          });
        }
      },
    }
  );

  const handleDeleteCheckpointLocale = () => {
    const localeId = data?.oneCheckpointDetails?.allLocales?.find(
      (x) => x.languageFlag.isoLanguageCode === activeLocale
    )?.id;
    if (localeId) {
      deleteCheckpointLocaleMutation({
        variables: {
          id: +localeId,
        },
      });
    } else {
      const newCurrentFlags = currentLanguageFlags.filter(
        (x) => x.isoLanguageCode !== activeLocale
      );
      resetFields();
      setCurrentLanguageFlags(newCurrentFlags);
      setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
      enqueueSnackbar("Lokalizacija je uklonjena!", { variant: "success" });
    }
  };

  const handleUpdateCheckpointPublished = () => {
    if (data && isEdit && checkpointId) {
      if (
        didValuesChange(inputFieldNames, true) &&
        !data.oneCheckpointDetails.published
      ) {
        // console.log("xxx", inputFields);
        enqueueSnackbar(MSG_UNSAVED_CHANGES, { variant: "warning" });
        return;
      }
      if (validateForm(inputFieldNames, !data.oneCheckpointDetails.published)) {
        updateCheckpointPublishedMutation({
          variables: {
            id: +checkpointId,
            publish: data?.oneCheckpointDetails.published ? false : true,
            // checkOnly: !data?.oneCheckpointDetails.published && !checkPassedPublishTest,
            checkOnly: false,
          },
        });
      }
    } else {
      enqueueSnackbar("ID nije pronađen!", {
        variant: "error",
      });
    }
  };

  const handleNavigateBack = () => {
    if (!didValuesChange()) {
      navigate(`/${ERouterPaths.INTERACTIVE_TOURS}/${interactiveTourId}`);
    } else {
      enqueueSnackbar({ message: MSG_UNSAVED_CHANGES, variant: "warning" });
    }
  };

  return (
    <PageLayout displayFlex>
      <TabContext value={activeTab}>
        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
          <Tab label="Opšte" value="1" />
          <Tab label="Uputstva" value="2" />
          <Tab label="Otvaranje" value="3" />
          <Tab label="Izazov" value="4" />
          <Tab label="Zatvaranje" value="5" />
          <Tab label="Činjenice" value="6" />
        </TabList>
        <Paper className={globalClasses.paperRoot}>
          <div
            className={cx(
              globalClasses.paperTitle,
              globalClasses.justifySpaceBetween
            )}
          >
            <div
              className={joinClassNames([
                globalClasses.justifySpaceBetween,
                globalClasses.centerItems,
              ])}
            >
              <IconButton onClick={handleNavigateBack} color="primary">
                <ArrowBackOutlined color="primary" />
              </IconButton>
              <Typography variant="h6">
                {isEdit ? "Edit Checkpoint" : "Dodaj novu tačku interesa"}
              </Typography>
            </div>
            {isEdit && data?.oneCheckpointDetails ? (
              <ReadyToPublish
                published={data.oneCheckpointDetails.published}
                checkPassed
                handlePublish={handleUpdateCheckpointPublished}
                publishedThingText="Checkpoint"
              />
            ) : null}
          </div>
          <div className={globalClasses.paperContainer}>
            <DataHandlerComponent
              hasData={Boolean(
                !isEdit || (isEdit && data?.oneCheckpointDetails)
              )}
              error={Boolean(error || formError)}
              loading={loading}
            >
              <FlagList
                canSelectFlags={isEdit}
                deleteLocaleFlagMutation={handleDeleteCheckpointLocale}
                activeLocale={activeLocale}
                setActiveLocale={setActiveLocale}
                currentLanguageFlags={currentLanguageFlags}
                setCurrentLanguageFlags={setCurrentLanguageFlags}
                type="Točka interesa"
                tooltip='Novi prevodi biće vidljivi korisnicima samo ako ste ih već dodali u odeljku "O nama".'
              />
              <TabPanel value="1" className={classes.tabPanel}>
                {!isEdit ? (
                  <Typography color="textSecondary">
                    Dodajte prve detalje o tački interesa koju želite da
                    kreirate.
                  </Typography>
                ) : null}

                <TextField
                  margin="normal"
                  {...inputFields.title.inputProps}
                  autoFocus
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_CHECKPOINT_TITLE_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.title.inputProps.value.length}/${INPUT_CHECKPOINT_TITLE_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </TabPanel>
              <TabPanel value="2" className={classes.tabPanel}>
                <TextField
                  margin="normal"
                  {...inputFields.directions.inputProps}
                  multiline
                  minRows={6}
                  maxRows={10}
                  autoFocus
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_CHECKPOINT_DIRECTIONS_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.directions.inputProps.value.length}/${INPUT_CHECKPOINT_DIRECTIONS_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  {...inputFields.directionsHint.inputProps}
                  multiline
                  minRows={6}
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_CHECKPOINT_DIRECTIONS_HINT_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.directionsHint.inputProps.value.length}/${INPUT_CHECKPOINT_DIRECTIONS_HINT_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <MediaSelectButton
                  setMediaSelectDialogOpen={setMediaSelectDialogOpen}
                  max={4}
                  pickType="image"
                  {...inputFields.directionsHintGallery.inputProps}
                />
              </TabPanel>
              <TabPanel value="3" className={classes.tabPanel}>
                <TextField
                  margin="normal"
                  {...inputFields.opening.inputProps}
                  multiline
                  autoFocus
                  minRows={6}
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_CHECKPOINT_OPENING_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.opening.inputProps.value.length}/${INPUT_CHECKPOINT_OPENING_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </TabPanel>
              <TabPanel value="4" className={classes.tabPanel}>
                <TextField
                  margin="normal"
                  {...inputFields.challenge.inputProps}
                  multiline
                  minRows={6}
                  maxRows={10}
                  autoFocus
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_CHECKPOINT_CHALLENGE_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.challenge.inputProps.value.length}/${INPUT_CHECKPOINT_CHALLENGE_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  {...inputFields.challengeHint.inputProps}
                  multiline
                  minRows={6}
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_CHECKPOINT_CHALLENGE_HINT_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.challengeHint.inputProps.value.length}/${INPUT_CHECKPOINT_CHALLENGE_HINT_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <MediaSelectButton
                  setMediaSelectDialogOpen={setMediaSelectDialogOpen}
                  max={4}
                  pickType="image"
                  {...inputFields.challengeHintGallery.inputProps}
                />

                <TextField
                  margin="normal"
                  {...inputFields.solution.inputProps}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_CHECKPOINT_SOLUTION_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.solution.inputProps.value.length}/${INPUT_CHECKPOINT_SOLUTION_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <MediaSelectButton
                  setMediaSelectDialogOpen={setMediaSelectDialogOpen}
                  max={1}
                  pickType="image"
                  {...inputFields.solutionHelperMedia.inputProps}
                />

                <Divider />
                <TextField
                  {...inputFields.puzzleType.inputProps}
                  margin="normal"
                  fullWidth
                  select
                >
                  <MenuItem value={""}>-- Text input --</MenuItem>
                  <MenuItem value={"LOCK_NUMERIC_4"}>
                    Brojčana brava [4]
                  </MenuItem>
                  <MenuItem value={"LOCK_ALPHABETIC_4"}>
                    Abecedna brava [4]
                  </MenuItem>
                  <MenuItem value={"NUMERIC_PAD"}>Brojčana tastatura</MenuItem>
                  <MenuItem value={"BINARY_LAMPS"}>Binarne lampe</MenuItem>
                </TextField>
              </TabPanel>
              <TabPanel value="5" className={classes.tabPanel}>
                <TextField
                  margin="normal"
                  {...inputFields.closing.inputProps}
                  multiline
                  minRows={6}
                  autoFocus
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_CHECKPOINT_CLOSING_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.closing.inputProps.value.length}/${INPUT_CHECKPOINT_CLOSING_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </TabPanel>
              <TabPanel value="6" className={classes.tabPanel}>
                <TextField
                  margin="normal"
                  {...inputFields.facts.inputProps}
                  multiline
                  minRows={6}
                  autoFocus
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_CHECKPOINT_FACTS_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.facts.inputProps.value.length}/${INPUT_CHECKPOINT_FACTS_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <MediaSelectButton
                  setMediaSelectDialogOpen={setMediaSelectDialogOpen}
                  max={4}
                  pickType="image"
                  {...inputFields.factsGallery.inputProps}
                />
              </TabPanel>
            </DataHandlerComponent>
          </div>
          <div className={globalClasses.paperButtons}>
            <div className={globalClasses.paperButtonsLeft}>
              {activeTab !== "1" ? (
                <Button
                  onClick={handlePrevTab}
                  variant="outlined"
                  color="inherit"
                  startIcon={<ChevronLeftOutlinedIcon />}
                >
                  Prethodno
                </Button>
              ) : null}
            </div>
            <div className={globalClasses.paperButtonsRight}>
              {activeTab !== TOTAL_TABS ? (
                <Button
                  onClick={handleNextTab}
                  variant="outlined"
                  endIcon={<ChevronRightOutlinedIcon />}
                >
                  Sledeće
                </Button>
              ) : activeTab === TOTAL_TABS && !isEdit ? (
                <Button onClick={handleCreateCheckpoint} variant="contained">
                  Završite kreiranje tačke interesa
                </Button>
              ) : null}

              {isEdit ? (
                <Button
                  onClick={handleUpdateLocale}
                  variant="contained"
                  disabled={
                    !didValuesChange() &&
                    !!data?.oneCheckpointDetails.allLocales?.find(
                      (x) => x.languageFlag?.isoLanguageCode === activeLocale
                    )?.id
                  }
                >
                  {!data?.oneCheckpointDetails.allLocales?.find(
                    (x) => x.languageFlag?.isoLanguageCode === activeLocale
                  )?.id
                    ? "kreiraj "
                    : "ažuriraj "}
                  | {activeLocale} |
                </Button>
              ) : null}

              {/* {activeTab === "3" && isEdit ? (
                <Button onClick={handleUpdateSharing} variant="contained">
                  Ažuriraj deljenje
                </Button>
              ) : null} */}
            </div>
          </div>
        </Paper>
      </TabContext>
      <LoadingBackdrop
        loading={
          loadingCreateCheckpointMutation ||
          loadingUpdateCheckpointLocaleMutation ||
          loadingCreateCheckpointLocaleMutation ||
          // loadingUpdateCheckpointOtherMutation ||
          loadingUpdateCheckpointPublishedMutation ||
          // loadingUpdateCheckpointSharingMutation ||
          loadingDeleteCheckpointLocaleMutation
        }
      />
      <MediaSelectorDialog
        dialogContext={mediaSelectDialogOpen}
        onClose={handleCloseMediaSelectDialog}
      />
    </PageLayout>
  );
};
