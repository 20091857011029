import { gql } from "@apollo/client";

export interface ICheckAdminInviteData {
  checkAdminInvite: boolean;
}

export interface ICheckAdminInviteVars {
  token: string;
}

export const CHECK_ADMIN_INVITE = gql`
  mutation checkAdminInvite($token: NonEmptyString!) {
    checkAdminInvite(token: $token)
  }
`;

export interface IRevokePersonInvitation {
  id: string;
  expired: string | null;
}

export interface IRevokePersonInvitationData {
  revokePersonInvitation: IRevokePersonInvitation;
}

export interface IRevokePersonInvitationVars {
  id: number;
}

export const REVOKE_PERSON_ROLE_SUBJECT_INVITATION = gql`
  mutation revokePersonInvitation($id: ID!) {
    revokePersonInvitation(id: $id) {
      id
      expired
    }
  }
`;

export interface IResendPersonInvitation {
  id: string;
  email: string;
  locale: {
    id: string;
    name: string;
  } | null;
  created: string;
  expired: string | null;
}

export interface IResendPersonInvitationData {
  //*Created / Updated
  resendPersonInvitation: [IResendPersonInvitation, IResendPersonInvitation];
}

export interface IResendPersonInvitationVars {
  id: number;
}

export const RESEND_PERSON_ROLE_SUBJECT_INVITATION = gql`
  mutation resendPersonInvitation($id: ID!) {
    resendPersonInvitation(id: $id) {
      id
      email

      created
      expired

      role {
        id
        locale {
          id
          name
        }
      }
    }
  }
`;
