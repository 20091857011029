import {
  DataArrayOutlined as DataArrayOutlinedIcon,
  ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material";
import { Button, CircularProgress, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { TableSkeleton } from "../";
import { useStyles } from "./DataHandlerComponent.styles";

interface IProps {
  spinner?: boolean;
  error: boolean;
  hasData: boolean;
  loading: boolean;
  skeletonNum?: number;
  skeletonHeight?: number;
  children?: ReactNode;
  noDataMessage?: string;
  retryFunction?: () => void;
}

export const DataHandlerComponent: FC<IProps> = (props) => {
  const {
    error,
    hasData,
    loading,
    skeletonHeight,
    skeletonNum,
    spinner,
    children,
    noDataMessage,
    retryFunction,
  } = props;
  const { classes } = useStyles();

  if (loading) {
    if (spinner) {
      return (
        <div className={classes.circularProgress}>
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <TableSkeleton
          height={skeletonHeight || 72}
          repeatNum={skeletonNum || 4}
        />
      );
    }
  }

  if (error) {
    return (
      <div className={classes.root}>
        <ErrorOutlineIcon color="error" fontSize="large" />
        <Typography component="p" color="error" variant="h6">
          Došlo je do greške...
        </Typography>
        {retryFunction ? (
          <Button
            className={classes.retryButton}
            variant="outlined"
            color="secondary"
            onClick={retryFunction}
          >
            Pokušajte ponovo
          </Button>
        ) : null}
      </div>
    );
  }
  // console.log({ hasData });

  if (!hasData) {
    return (
      <div className={classes.root}>
        <DataArrayOutlinedIcon fontSize="large" />
        <Typography variant="h6">{noDataMessage || "No data found"}</Typography>
        {retryFunction ? (
          <Button
            className={classes.retryButton}
            variant="outlined"
            color="secondary"
            onClick={retryFunction}
          >
            Pokušajte ponovo
          </Button>
        ) : null}
      </div>
    );
  }

  if (children) {
    //TODO: fix fade
    // return (
    //   <Fade in>
    //     <div>{children}</div>
    //   </Fade>
    // );
    return <>{children}</>;
  } else return null;
};
