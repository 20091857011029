import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  startDateUsers: {
    inputProps: {
      label: "Datum početka",
      name: "startDateUsers",
      placeholder: "",
      required: true,
    },
  },
  endDateUsers: {
    inputProps: {
      label: "Datum završetka",
      name: "endDateUsers",
      placeholder: "",
      required: true,
    },
  },
  startDateInteractive: {
    inputProps: {
      label: "Datum početka",
      name: "startDateInteractive",
      placeholder: "",
      required: true,
    },
  },
  endDateInteractive: {
    inputProps: {
      label: "Datum završetka",
      name: "endDateInteractive",
      placeholder: "",
      required: true,
    },
  },
  tourIdInteractive: {
    inputProps: {
      label: "Interaktivna tura",
      name: "tourIdInteractive",
      placeholder: "",
      required: true,
    },
  },
  startDateClassic: {
    inputProps: {
      label: "Datum početka",
      name: "startDateClassic",
      placeholder: "",
      required: true,
    },
  },
  endDateClassic: {
    inputProps: {
      label: "Datum završetka",
      name: "endDateClassic",
      placeholder: "",
      required: true,
    },
  },
  tourIdClassic: {
    inputProps: {
      label: "Audio tura",
      name: "tourIdClassic",
      placeholder: "",
      required: true,
    },
  },
});
