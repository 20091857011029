import { useSnackbar } from "notistack";
import { useGlobalStyles } from "../../../utils/theme";
// import { useStyles } from "./ReceptionPage.styles";
import { useForm } from "../../../utils";
import { initialInputData } from "./ReceptionPage.inputs";
import {
  LoadingBackdrop,
  PageLayout,
  ReceptionConfirmationDialog,
} from "../../../components";
import { FC, useState } from "react";
import { Button, Paper, TextField, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { LockOpenOutlined as LockOpenOutlinedIcon } from "@mui/icons-material";
import {
  IRedeemRewardReceptionData,
  IRedeemRewardReceptionVars,
  REDEEM_REWARD_RECEPTION,
} from "../../../apollo/mutations";

export const ReceptionPage: FC = () => {
  // const { classes } = useStyles();
  const { cx, classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const {
    inputFields,
    inputFieldNames,
    validateForm,
    didValuesChange,
    resetFields,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const [unlockContentMutation, { loading: loadingUnlockContentMutation }] =
    useMutation<IRedeemRewardReceptionData, IRedeemRewardReceptionVars>(
      REDEEM_REWARD_RECEPTION,
      {
        onCompleted: (data) => {
          enqueueSnackbar(`Nagrada iskorišćena!`, {
            variant: "success",
          });
          handleCloseConfirmDialog();
          resetFields(inputFieldNames);
        },
        onError: (error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        },
      }
    );

  const handleUnlockContent = () => {
    if (validateForm(inputFieldNames, true)) {
      handleOpenConfirmDialog();
    }
  };

  const handleConfirmUnlock = () => {
    if (validateForm(inputFieldNames, true)) {
      unlockContentMutation({
        variables: {
          code: inputFields.promoCode.inputProps.value,
        },
      });
    }
  };

  return (
    <PageLayout displayFlex>
      <Paper className={globalClasses.paperRoot}>
        <div
          className={cx(
            globalClasses.paperTitle,
            globalClasses.justifySpaceBetween
          )}
        >
          <Typography variant="h6">Recepcija</Typography>
        </div>
        <div className={globalClasses.paperContainer}>
          <TextField
            margin="normal"
            {...inputFields.promoCode.inputProps}
            autoFocus
            fullWidth
          />
        </div>
        <div className={globalClasses.paperButtons}>
          <div className={globalClasses.paperButtonsLeft} />
          <div className={globalClasses.paperButtonsRight}>
            <Button
              startIcon={<LockOpenOutlinedIcon />}
              onClick={handleUnlockContent}
              variant="contained"
              disabled={!didValuesChange(inputFieldNames)}
            >
              Nastavi
            </Button>
          </div>
        </div>
      </Paper>
      <LoadingBackdrop loading={loadingUnlockContentMutation} />
      <ReceptionConfirmationDialog
        callMutation={handleConfirmUnlock}
        code={inputFields.promoCode.inputProps.value}
        fullWidth
        maxWidth="md"
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
      />
    </PageLayout>
  );
};
