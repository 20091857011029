import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  promoCode: {
    inputProps: {
      label: "Promo kod",
      name: "promoCode",
      required: true,
    },
    mainHelperText:
      "Molimo unesite promo kod nagrade koju korisnik želi da dobije. Promo kod je osetljiv na velika i mala slova!",
  },
});
