import { TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { IReview } from "../../apollo/queries";
import { format } from "date-fns";

interface IProps {
  item: IReview;
  index: number;
}

export const ReviewItem: FC<IProps> = (props) => {
  const { item, index } = props;

  return (
    <TableRow onClick={() => {}}>
      <TableCell>{index}</TableCell>
      <TableCell>
        <strong>{item.stars}</strong>
      </TableCell>
      <TableCell>
        <strong>{item.message}</strong>
      </TableCell>
      <TableCell>
        {format(new Date(item.created), "dd.MM.yyyy. HH:mm'h'")}
      </TableCell>
    </TableRow>
  );
};
