import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.background.paper,
    width: "fit-content",
    // height: 30,
    fontSize: 13,
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
  published: {
    backgroundColor: theme.palette.success.light,
  },
  draft: {
    backgroundColor: theme.palette.info.light,
  },
}));
