import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useStyles } from "./MapPinPage.styles";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import {
  DataHandlerComponent,
  FlagList,
  LoadingBackdrop,
  PageLayout,
  ReadyToPublish,
} from "../../../components";
import { theme, useGlobalStyles } from "../../../utils/theme";
import {
  ERouterPaths,
  IMediaSelectDialogContext,
  INPUT_MAP_PIN_NAME_MAX_LENGTH,
  MAIN_ISO_LANGUAGE_CODE,
  MSG_GENERIC_ERROR,
  MSG_UNSAVED_CHANGES,
  useForm,
} from "../../../utils";
import { initialInputData } from "./MapPinPage.inputs";
import {
  ArrowBackOutlined as ArrowBackOutlinedIcon,
  ChevronRightOutlined as ChevronRightOutlinedIcon,
} from "@mui/icons-material";
import { ChevronLeftOutlined as ChevronLeftOutlinedIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Reference, StoreObject, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_MAP_PIN,
  CREATE_MAP_PIN_LOCALE,
  DELETE_MAP_PIN_LOCALE,
  ICreateMapPinData,
  ICreateMapPinLocaleData,
  ICreateMapPinLocaleVars,
  ICreateMapPinVars,
  IDeleteMapPinLocaleData,
  IDeleteMapPinLocaleVars,
  IUpdateMapPinLocaleData,
  IUpdateMapPinLocaleVars,
  IUpdateMapPinOtherData,
  IUpdateMapPinOtherVars,
  IUpdateMapPinPublishedData,
  IUpdateMapPinPublishedVars,
  UPDATE_MAP_PIN_LOCALE,
  UPDATE_MAP_PIN_OTHER,
  UPDATE_MAP_PIN_PUBLISHED,
} from "../../../apollo/mutations";
import { useNavigate, useParams } from "react-router";
import {
  ALL_MAP_PINS,
  IMapPin,
  IMapPinDetailsData,
  IMapPinDetailsVars,
  IMapPinsData,
  ONE_MAP_PIN_DETAILS,
} from "../../../apollo/queries";
import { MediaSelectorDialog } from "../../../components/mediaSelectorContent/components";

export const MapPinPage: FC = () => {
  const { classes } = useStyles();
  const { cx, classes: globalClasses } = useGlobalStyles();
  const { mapPinId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isDataSet = useRef(false);

  const TOTAL_TABS = "2";
  const isEdit = mapPinId !== undefined;

  const [activeTab, setActiveTab] = useState("1");

  const [mediaSelectDialogOpen, setMediaSelectDialogOpen] =
    useState<IMediaSelectDialogContext>({
      open: false,
      type: undefined,
      max: undefined,
      onChange: undefined,
      selectedMedia: [],
    });

  const handleCloseMediaSelectDialog = () => {
    setMediaSelectDialogOpen((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const { loading, data, error } = useQuery<
    IMapPinDetailsData,
    IMapPinDetailsVars
  >(ONE_MAP_PIN_DETAILS, {
    skip: !isEdit,
    variables: {
      id: mapPinId ? +mapPinId : 0,
    },
    onError: (err) => {
      enqueueSnackbar(err.message, { variant: "error" });
    },
  });

  const {
    inputFields,
    validateForm,
    activeLocale,
    currentLanguageFlags,
    didValuesChange,
    getFormValuesFromFetchedData,
    inputFieldNames,
    handleDataToVar,
    setInputField,
    resetFields,
    setActiveLocale,
    setCurrentLanguageFlags,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [createMapPinMutation, { loading: loadingCreateMapPinMutation }] =
    useMutation<ICreateMapPinData, ICreateMapPinVars>(CREATE_MAP_PIN, {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Kreirana je nova oznaka na mapi: ${data.createMapPin.locale.name}`,
          {
            variant: "success",
          }
        );

        resetFields(inputFieldNames, true, true);
        navigate(`/${ERouterPaths.MAPS}/${data.createMapPin.id}`);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      //TODO: Check if no mapPins
      update: (cache, { data }) => {
        const existingListData: IMapPinsData | null = cache.readQuery({
          query: ALL_MAP_PINS,
        });
        if (data?.createMapPin) {
          const newData: IMapPin = {
            ...data.createMapPin,
          };
          cache.writeQuery({
            query: ALL_MAP_PINS,
            data: {
              allMapPins: existingListData?.allMapPins
                ? [newData, ...existingListData.allMapPins]
                : [newData],
            },
          });
        }
      },
    });

  const [
    updateMapPinLocaleMutation,
    { loading: loadingUpdateMapPinLocaleMutation },
  ] = useMutation<IUpdateMapPinLocaleData, IUpdateMapPinLocaleVars>(
    UPDATE_MAP_PIN_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Lokalizacija je ažurirana: ${data.updateMapPinLocale.name}`,
          {
            variant: "success",
          }
        );
        resetFields(["name"], true);
      },

      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      onQueryUpdated: () => undefined,
    }
  );

  const [
    updateMapPinOtherMutation,
    { loading: loadingUpdateMapPinOtherMutation },
  ] = useMutation<IUpdateMapPinOtherData, IUpdateMapPinOtherVars>(
    UPDATE_MAP_PIN_OTHER,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Ostale informacije su ažurirane", {
          variant: "success",
        });
        resetFields(["label", "mapId", "posX", "posY", "skipLegend"], true);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    }
  );

  const [
    createMapPinLocaleMutation,
    { loading: loadingCreateMapPinLocaleMutation },
  ] = useMutation<ICreateMapPinLocaleData, ICreateMapPinLocaleVars>(
    CREATE_MAP_PIN_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Lokalizacija je kreirana: ${data.createMapPinLocale.name}`,
          {
            variant: "success",
          }
        );
        resetFields(["name"], true);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update: (cache, { data: dataCreate }) => {
        // console.log(cache);
        if (data?.oneMapPinDetails) {
          cache.modify({
            id: cache.identify(data.oneMapPinDetails as unknown as StoreObject),
            fields: {
              allLocales(existingData: Array<Reference>, { readField }) {
                if (data) {
                  if (existingData && dataCreate) {
                    return [
                      ...existingData,
                      {
                        // @ts-ignore
                        __ref: `${dataCreate.createMapPinLocale.__typename}:${dataCreate.createMapPinLocale.id}`,
                      },
                    ];
                  }
                }
              },
            },
          });
        }
      },
    }
  );

  const [
    updateMapPinPublishedMutation,
    { loading: loadingUpdateMapPinPublishedMutation },
  ] = useMutation<IUpdateMapPinPublishedData, IUpdateMapPinPublishedVars>(
    UPDATE_MAP_PIN_PUBLISHED,
    {
      onCompleted: (data) => {
        if (!data.updateMapPinPublished) {
          enqueueSnackbar(`Oznaka na mapi je spremna za objavu!`, {
            variant: "info",
          });
          // setCheckPassedPublishTest(true);
        } else {
          enqueueSnackbar(
            `Oznaka na mapi je sada ${
              data.updateMapPinPublished.published
                ? "objavljena"
                : "neobjavljena"
            }!`,
            {
              variant: "success",
            }
          );
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    }
  );

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setActiveTab(newTab);
  };

  const handleNextTab = () => {
    const nextTab = (parseInt(activeTab) + 1).toString();
    setActiveTab(nextTab);
  };

  const handlePrevTab = () => {
    const prevTab = (parseInt(activeTab) - 1).toString();
    setActiveTab(prevTab);
  };

  const handleCreateMapPin = () => {
    if (mapPinId) {
      enqueueSnackbar(MSG_GENERIC_ERROR, { variant: "error" });
      return;
    }

    if (validateForm(inputFieldNames, false)) {
      createMapPinMutation({
        variables: {
          data: {
            mapId: handleDataToVar("mapId", "number", false),
            label: handleDataToVar("label", "string", false),
            posX: handleDataToVar("posX", "string", false),
            posY: handleDataToVar("posY", "string", false),
            skipLegend: handleDataToVar("skipLegend", "boolean", false),
          },
          dataLocale: {
            name: handleDataToVar("name", "string", false),
          },
        },
      });
    }
  };

  useEffect(() => {
    if (!data?.oneMapPinDetails || isDataSet.current) {
      return;
    }
    getFormValuesFromFetchedData(
      data.oneMapPinDetails,
      [
        {
          fromDataProperty: "allLocales.name",
          toFormProperty: "name",
        },
        {
          fromDataProperty: "mapId",
          toFormProperty: "mapId",
        },
        {
          fromDataProperty: "posX",
          toFormProperty: "posX",
        },
        {
          fromDataProperty: "posY",
          toFormProperty: "posY",
        },
        {
          fromDataProperty: "label",
          toFormProperty: "label",
        },
        {
          fromDataProperty: "skipLegend",
          toFormProperty: "skipLegend",
        },
      ],
      true,
      true
    );
    isDataSet.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.oneMapPinDetails]);

  const handleUpdateLocale = () => {
    if (!validateForm(["name"], Boolean(data?.oneMapPinDetails.published))) {
      return null;
    }

    const getUpdateLocaleId = isEdit
      ? data?.oneMapPinDetails.allLocales?.find(
          (x) => x.languageFlag?.isoLanguageCode === activeLocale
        )?.id
      : undefined;

    if (getUpdateLocaleId) {
      updateMapPinLocaleMutation({
        variables: {
          id: +getUpdateLocaleId,
          data: {
            name: handleDataToVar("name", "string"),
          },
        },
      });
    } else if (mapPinId) {
      createMapPinLocaleMutation({
        variables: {
          mapPinId: +mapPinId,
          data: {
            name: handleDataToVar("name", "string", false),
            isoLanguageCode: activeLocale,
          },
        },
      });
    }
  };

  const handleUpdateOther = () => {
    if (
      !validateForm(
        ["label", "mapId", "posX", "posY", "skipLegend"],
        Boolean(data?.oneMapPinDetails.published)
      )
    ) {
      return null;
    }
    if (isEdit) {
      updateMapPinOtherMutation({
        variables: {
          id: +mapPinId,
          data: {
            mapId: handleDataToVar("mapId", "number"),
            label: handleDataToVar("label", "string"),
            posX: handleDataToVar("posX", "string"),
            posY: handleDataToVar("posY", "string"),
            skipLegend: handleDataToVar("skipLegend", "boolean"),
          },
        },
      });
    }
  };

  const [
    deleteMapPinLocaleMutation,
    { loading: loadingDeleteMapPinLocaleMutation },
  ] = useMutation<IDeleteMapPinLocaleData, IDeleteMapPinLocaleVars>(
    DELETE_MAP_PIN_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Jezik oznake na mapi je obrisan", {
          variant: "success",
        });
        resetFields(["name"]);
        setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
        const newCurrentFlags = currentLanguageFlags.filter(
          (x) => x.id !== data.deleteMapPinLocale.localeId
        );
        setCurrentLanguageFlags(newCurrentFlags);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update(cache, { data: dataCreate }) {
        if (data?.oneMapPinDetails) {
          cache.modify({
            id: cache.identify(data.oneMapPinDetails as unknown as StoreObject),
            fields: {
              allLocales(existingData: Array<Reference>, { readField }) {
                if (data) {
                  if (existingData && dataCreate) {
                    return existingData.filter(
                      (taskRef) =>
                        dataCreate.deleteMapPinLocale.id !==
                        readField("id", taskRef)
                    );
                  }
                }
              },
            },
          });
        }
      },
    }
  );

  const handleDeleteMapPinLocale = () => {
    const localeId = data?.oneMapPinDetails?.allLocales?.find(
      (x) => x.languageFlag.isoLanguageCode === activeLocale
    )?.id;
    if (localeId) {
      deleteMapPinLocaleMutation({
        variables: {
          id: +localeId,
        },
      });
    } else {
      const newCurrentFlags = currentLanguageFlags.filter(
        (x) => x.isoLanguageCode !== activeLocale
      );
      resetFields(["name"]);
      setCurrentLanguageFlags(newCurrentFlags);
      setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
      enqueueSnackbar("Lokalizacija je uklonjena!", { variant: "success" });
    }
  };

  const handleUpdateMapPinPublished = () => {
    if (data && isEdit && mapPinId) {
      if (
        didValuesChange(inputFieldNames, true) &&
        !data.oneMapPinDetails.published
      ) {
        enqueueSnackbar(MSG_UNSAVED_CHANGES, { variant: "warning" });
        return;
      }
      if (
        validateForm(inputFieldNames, !data.oneMapPinDetails.published, true)
      ) {
        updateMapPinPublishedMutation({
          variables: {
            id: +mapPinId,
            publish: data?.oneMapPinDetails.published ? false : true,
            // checkOnly: !data?.oneMapPinDetails.published && !checkPassedPublishTest,
            // checkOnly: false,
          },
        });
      }
    } else {
      enqueueSnackbar("ID nije pronađen!", {
        variant: "error",
      });
    }
  };

  const handleNavigateBack = () => {
    if (!didValuesChange()) {
      navigate(`/${ERouterPaths.MAPS}`);
    } else {
      enqueueSnackbar({ message: MSG_UNSAVED_CHANGES, variant: "warning" });
    }
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputField("skipLegend", event.target.checked ? "true" : "false");
  };

  return (
    <PageLayout displayFlex>
      <TabContext value={activeTab}>
        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
          <Tab label="Opšte" value="1" />
          <Tab label="Ostalo" value="2" />
        </TabList>
        <Paper className={globalClasses.paperRoot}>
          <div
            className={cx(
              globalClasses.paperTitle,
              globalClasses.justifySpaceBetween
            )}
          >
            <div
              className={cx(
                globalClasses.justifySpaceBetween,
                globalClasses.centerItems
              )}
            >
              <IconButton onClick={handleNavigateBack} color="primary">
                <ArrowBackOutlinedIcon color="primary" />
              </IconButton>
              <Typography variant="h6">
                {isEdit ? "Uredi oznaku na mapi" : "Dodaj novu oznaku na mapi"}
              </Typography>
            </div>
            {isEdit && data?.oneMapPinDetails ? (
              <ReadyToPublish
                published={data.oneMapPinDetails.published}
                checkPassed
                handlePublish={handleUpdateMapPinPublished}
                publishedThingText="MapPin"
              />
            ) : null}
          </div>
          <div className={globalClasses.paperContainer}>
            <Typography color={theme.palette.grey[600]} variant="body2">
              Brzo možete videti promene na ekranu mape aplikacije Palata tako
              što ćete držati dugme "Legenda" nekoliko sekundi
            </Typography>

            <DataHandlerComponent
              hasData={Boolean(!isEdit || (isEdit && data?.oneMapPinDetails))}
              error={Boolean(error)}
              loading={loading}
            >
              <TabPanel value="1" className={classes.tabPanel}>
                <FlagList
                  canSelectFlags={isEdit}
                  deleteLocaleFlagMutation={handleDeleteMapPinLocale}
                  activeLocale={activeLocale}
                  setActiveLocale={setActiveLocale}
                  currentLanguageFlags={currentLanguageFlags}
                  setCurrentLanguageFlags={setCurrentLanguageFlags}
                  type="Oznaka na mapi"
                  tooltip='Novi prevodi biće vidljivi korisnicima samo ako ste ih već dodali u odeljku "O nama".'
                />
                {!isEdit ? (
                  <Typography color="textSecondary">
                    Dodajte prve detalje o oznaci na mapi koju želite da
                    kreirate.
                  </Typography>
                ) : null}
                <TextField
                  margin="normal"
                  {...inputFields.name.inputProps}
                  autoFocus
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_MAP_PIN_NAME_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.name.inputProps.value.length}/${INPUT_MAP_PIN_NAME_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </TabPanel>

              <TabPanel className={classes.tabPanel} value="2">
                <TextField
                  {...inputFields.mapId.inputProps}
                  margin="normal"
                  fullWidth
                  select
                >
                  <MenuItem value={"0"}>Podrum</MenuItem>
                  <MenuItem value={"1"}>Prizemlje</MenuItem>
                  <MenuItem value={"2"}>Prvi sprat</MenuItem>
                  <MenuItem value={"3"}>Drugi sprat</MenuItem>
                  <MenuItem value={"4"}>Treći sprat</MenuItem>
                  <MenuItem value={"5"}>Četvrti sprat</MenuItem>
                  <MenuItem value={"6"}>Peti sprat</MenuItem>
                </TextField>

                <TextField
                  {...inputFields.label.inputProps}
                  margin="normal"
                  fullWidth
                  select
                >
                  <MenuItem value={"1"}>1</MenuItem>
                  <MenuItem value={"2"}>2</MenuItem>
                  <MenuItem value={"3"}>3</MenuItem>
                  <MenuItem value={"4"}>4</MenuItem>
                  <MenuItem value={"5"}>5</MenuItem>
                  <MenuItem value={"6"}>6</MenuItem>
                  <MenuItem value={"7"}>7</MenuItem>
                  <MenuItem value={"8"}>8</MenuItem>
                  <MenuItem value={"9"}>9</MenuItem>
                  <MenuItem value={"10"}>10</MenuItem>
                  <MenuItem value={"11"}>11</MenuItem>
                  <MenuItem value={"12"}>12</MenuItem>

                  <MenuItem value={"WC"}>[WC]</MenuItem>
                  <MenuItem value={"LIFT"}>[LIFT]</MenuItem>
                  <MenuItem value={"WARDROBE"}>[GARDEROBA]</MenuItem>
                  <MenuItem value={"TICKET_OFFICE"}>[BLAGAJNA]</MenuItem>
                  <MenuItem value={"LIFT_ACCESSABILITY"}>
                    [PRISTUP ZA INVALIDE]
                  </MenuItem>
                  <MenuItem value={"EASTER_EGG"}>
                    [SKRIVENO IZNENAĐENJE]
                  </MenuItem>
                </TextField>

                <div className={globalClasses.justifySpaceBetween}>
                  <TextField
                    margin="normal"
                    {...inputFields.posX.inputProps}
                    autoFocus
                    inputProps={{
                      maxLength: 5,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="caption">{`${inputFields.name.inputProps.value.length}/${INPUT_MAP_PIN_NAME_MAX_LENGTH}`}</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    margin="normal"
                    {...inputFields.posY.inputProps}
                    autoFocus
                    inputProps={{
                      maxLength: 5,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="caption">{`${inputFields.name.inputProps.value.length}/${INPUT_MAP_PIN_NAME_MAX_LENGTH}`}</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          inputFields.skipLegend.inputProps.value === "true"
                        }
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={inputFields.skipLegend.inputProps.label}
                  />
                  <FormHelperText>
                    {inputFields.skipLegend.mainHelperText}
                  </FormHelperText>
                </FormGroup>
              </TabPanel>
            </DataHandlerComponent>
          </div>
          <div className={globalClasses.paperButtons}>
            <div className={globalClasses.paperButtonsLeft}>
              {activeTab !== "1" ? (
                <Button
                  onClick={handlePrevTab}
                  variant="outlined"
                  color="inherit"
                  startIcon={<ChevronLeftOutlinedIcon />}
                >
                  Prethodno
                </Button>
              ) : null}
            </div>
            <div className={globalClasses.paperButtonsRight}>
              {activeTab !== TOTAL_TABS ? (
                <Button
                  onClick={handleNextTab}
                  variant="outlined"
                  endIcon={<ChevronRightOutlinedIcon />}
                >
                  Sledeće
                </Button>
              ) : activeTab === TOTAL_TABS && !isEdit ? (
                <Button onClick={handleCreateMapPin} variant="contained">
                  Završite kreiranje oznake na mapi
                </Button>
              ) : null}

              {activeTab === "1" && isEdit ? (
                <Button
                  onClick={handleUpdateLocale}
                  variant="contained"
                  disabled={
                    !didValuesChange(["name"]) &&
                    !!data?.oneMapPinDetails.allLocales?.find(
                      (x) => x.languageFlag?.isoLanguageCode === activeLocale
                    )?.id
                  }
                >
                  {!data?.oneMapPinDetails.allLocales?.find(
                    (x) => x.languageFlag?.isoLanguageCode === activeLocale
                  )?.id
                    ? "kreiraj "
                    : "ažuriraj "}
                  | {activeLocale} |
                </Button>
              ) : null}
              {activeTab === "2" && isEdit ? (
                <Button
                  disabled={
                    !didValuesChange([
                      "label",
                      "mapId",
                      "posX",
                      "posY",
                      "skipLegend",
                    ])
                  }
                  onClick={handleUpdateOther}
                  variant="contained"
                >
                  Ažuriraj ostalo
                </Button>
              ) : null}
            </div>
          </div>
        </Paper>
      </TabContext>
      <LoadingBackdrop
        loading={
          loadingCreateMapPinMutation ||
          loadingUpdateMapPinLocaleMutation ||
          loadingCreateMapPinLocaleMutation ||
          loadingUpdateMapPinOtherMutation ||
          loadingUpdateMapPinPublishedMutation ||
          loadingDeleteMapPinLocaleMutation
        }
      />
      <MediaSelectorDialog
        dialogContext={mediaSelectDialogOpen}
        onClose={handleCloseMediaSelectDialog}
      />
    </PageLayout>
  );
};
