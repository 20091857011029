export const ITEMS_PER_PAGE = 25;
export const MAIN_ISO_LANGUAGE_CODE = "sr";

//INPUT

export const INPUT_CLASSIC_TOUR_TITLE_MAX_LENGTH = 80;
export const INPUT_CLASSIC_TOUR_DESCRIPTION_MAX_LENGTH = 500;

export const INPUT_STATION_TITLE_MAX_LENGTH = 80;
export const INPUT_STATION_DESCRIPTION_MAX_LENGTH = 750;
export const INPUT_STATION_AUDIO_GUIDE_TEXT_MAX_LENGTH = 10000;

export const INPUT_SUBJECT_ABOUT_MAX_LENGTH = 1000;
export const INPUT_SUBJECT_EXTRA_INFO_MAX_LENGTH = 1000;
export const INPUT_SUBJECT_OPENING_HOURS_INFO_MAX_LENGTH = 1000;

export const INPUT_SUBJECT_NAME_MAX_LENGTH = 80;
export const INPUT_STATION_CODE_MAX_LENGTH = 10;

export const INPUT_INTERACTIVE_TOUR_TITLE_MAX_LENGTH = 80;
export const INPUT_INTERACTIVE_TOUR_DESCRIPTION_MAX_LENGTH = 500;
export const INPUT_INTERACTIVE_TOUR_INTRODUCTION_MAX_LENGTH = 500;
export const INPUT_INTERACTIVE_TOUR_CONCLUSION_MAX_LENGTH = 500;
export const INPUT_INTERACTIVE_TOUR_STARTING_POINT_MAX_LENGTH = 120;
export const INPUT_INTERACTIVE_TOUR_IMPORTANT_INFO_MAX_LENGTH = 1500;

export const INPUT_CHECKPOINT_TITLE_MAX_LENGTH = 80;
export const INPUT_CHECKPOINT_DIRECTIONS_MAX_LENGTH = 350;
export const INPUT_CHECKPOINT_DIRECTIONS_HINT_MAX_LENGTH = 350;
export const INPUT_CHECKPOINT_OPENING_MAX_LENGTH = 500;
export const INPUT_CHECKPOINT_CHALLENGE_MAX_LENGTH = 500;
export const INPUT_CHECKPOINT_CHALLENGE_HINT_MAX_LENGTH = 350;
export const INPUT_CHECKPOINT_SOLUTION_MAX_LENGTH = 250;
export const INPUT_CHECKPOINT_CLOSING_MAX_LENGTH = 500;
export const INPUT_CHECKPOINT_FACTS_MAX_LENGTH = 500;

export const INPUT_NOTIFICATION_TITLE_MAX_LENGTH = 80;
export const INPUT_NOTIFICATION_MESSAGE_MAX_LENGTH = 4000;
export const INPUT_NOTIFICATION_LINK_MAX_LENGTH = 1000;

export const INPUT_NOTIFICATION_TITLE_PUSH_MAX_LENGTH = 50;
export const INPUT_NOTIFICATION_SUBTITLE_PUSH_MAX_LENGTH = 50;
export const INPUT_NOTIFICATION_MESSAGE_PUSH_MAX_LENGTH = 150;

export const INPUT_MAP_PIN_NAME_MAX_LENGTH = 80;

//ERRORS

export const ERROR_MAIN_LOCALE_NOT_FOUND =
  "Glavna lokalizacija nije pronađena!";
export const MSG_UNSAVED_CHANGES =
  "Imate nesnimljene promene. Ažurirajte ih ili ih poništite i pokušajte ponovo.";
export const MSG_GENERIC_ERROR =
  "Greška tokom operacije. Osvežite stranicu i pokušajte ponovo ili kontaktirajte podršku.";

//FILE UPLOAD
//600kb
export const MAX_IMAGE_UPLOAD_SIZE = 600000;
//50mb
export const MAX_AUDIO_UPLOAD_SIZE = 50000000;

export type TValidTypes = (
  | "image/jpeg"
  | "image/jpg"
  | "image/png"
  | "audio/mpeg"
)[];

export const ALLOWED_IMAGE_TYPES: TValidTypes = [
  "image/jpeg",
  "image/png",
  "image/jpg",
];
export const ALLOWED_AUDIO_TYPES: TValidTypes = ["audio/mpeg"];

export const ALLOWED_MEDIA_TYPES: TValidTypes = [
  ...ALLOWED_IMAGE_TYPES,
  ...ALLOWED_AUDIO_TYPES,
];

export const UNKNOWN_ERROR_STRING =
  "Došlo je do nepoznate greške! Molimo pokušajte ponovo ili kontaktirajte podršku!";

//ENUMS
export enum ERouterPaths {
  CLASSIC_TOURS = "classic-tours",
  INTERACTIVE_TOURS = "interactive-tours",
  MEDIA = "media",
  ABOUT_US = "about-us",
  ADMINISTRATORS = "administrators",
  REPORTS = "reports",
  LOGIN = "login",
  FORGOT_PASSWORD = "forgot-password",
  RESET_PASSWORD = "reset-password",
  VALIDATE_PERSON = "validate-person",

  NOTIFICATIONS = "notifications",
  MAPS = "maps",

  STATISTICS = "statistics",

  RECEPTION = "reception",

  //FAQ = "faq", //TODO: Later: Move site to cms, not external docs
  // FAQ = "https://docs.google.com/document/d/1krIp98xZrsN2wXxkY2w3b7I65CUf4wwCtUC2DcJ4_no",
  // GUIDELINES = "guidelines",

  JOIN = "join",
}

export enum EPuzzleType {
  LOCK_NUMERIC_4 = "LOCK_NUMERIC_4",
  LOCK_ALPHABETIC_4 = "LOCK_ALPHABETIC_4",
  BINARY_LAMPS = "BINARY_LAMPS",
  NUMERIC_PAD = "NUMERIC_PAD",
}
