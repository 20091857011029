import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  title: {
    inputProps: {
      label: "Naslov",
      name: "title",
      placeholder: "Naslov obaveštenja",
      required: true,
    },
    localise: true,
    mainHelperText:
      "Ovo je vidljivo unutar sekcije za obaveštenja u aplikaciji.",
  },
  body: {
    localise: true,
    inputProps: {
      label: "Poruka",
      name: "message",
      placeholder: "Poruka...",
      required: true,
    },
    mainHelperText:
      "Ovo je vidljivo unutar sekcije za obaveštenja u aplikaciji.",
  },
  links: {
    localise: true,
    inputProps: {
      label: "Linkovi",
      name: "links",
      placeholder: "Naslov linka|https://google.com",
    },
    mainHelperText:
      "Ovo je vidljivo unutar sekcije za obaveštenja u aplikaciji. Možete dodati više linkova razdvojenih zarezom.",
  },
  titlePush: {
    inputProps: {
      name: "titlePush",
      label: "Naslov push obaveštenja",
      placeholder: "...",
    },
    localise: true,
    mainHelperText:
      "Naslov push obaveštenja. Ne može se promeniti nakon što je poslato!",
  },
  subtitlePush: {
    localise: true,
    inputProps: {
      label: "Podnaslov push obaveštenja",
      name: "subtitlePush",
      placeholder: "",
    },
    mainHelperText:
      "Podnaslov push obaveštenja. Ne može se promeniti nakon što je poslato!",
  },
  bodyPush: {
    inputProps: {
      label: "Poruka push obaveštenja",
      name: "messagePush",
      placeholder: "Poruka...",
      required: true,
    },
    localise: true,
    mainHelperText:
      "Poruka push obaveštenja. Ne može se promeniti nakon što je poslata!",
  },

  gallery: {
    inputProps: {
      label: "Galerija",
      name: "gallery",
    },
    rule: "media",
    required: true,
    localise: true,
    mainLocaleRequiredOnly: true,
  },
});
