import {
  FC,
  KeyboardEvent,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useStyles } from "./JoinPage.styles";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import {
  LockOutlined as LockOutlinedIcon,
  PersonOutline as PersonOutlineIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from "@mui/icons-material";
import { ContextProvider, useForm } from "../../../utils";

import { initialInputData } from "./JoinPage.inputs";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CHECK_ADMIN_INVITE,
  CREATE_ADMIN,
  ICheckAdminInviteData,
  ICheckAdminInviteVars,
  ICreateAdminData,
  ICreateAdminVars,
  ILogoutCmsData,
  LOGOUT,
} from "../../../apollo/mutations";
import { ErrorComponent, LoadingBackdrop } from "../../../components";

export const JoinPage: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();
  const [searchParams] = useSearchParams();
  const [tokenError, setTokenError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useContext(ContextProvider);

  useLayoutEffect(() => {
    const token = searchParams.get("token");

    if (!token) {
      setTokenError(true);
    }
  }, [searchParams]);

  const { inputFields, validateForm, inputFieldNames } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const handleEnterKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleContinue();
    }
  };

  const [logoutMutation, { loading: loadingLogout, client }] = useMutation<
    ILogoutCmsData,
    null
  >(LOGOUT, {
    onCompleted: async () => {
      await client.clearStore();
    },
  });

  const [checkAdminInviteMutation, { loading, data }] = useMutation<
    ICheckAdminInviteData,
    ICheckAdminInviteVars
  >(CHECK_ADMIN_INVITE, {
    onCompleted: (data) => {
      if (data.checkAdminInvite === false) {
        enqueueSnackbar(
          "Nijedan nalog sa tim emailom nije pronađen. Potrebno je da unesete svoje podatke kako biste završili registraciju i prihvatili pozivnicu.",
          {
            variant: "info",
          }
        );
        return;
      }
      enqueueSnackbar(
        "Prihvatili ste pozivnicu. Kliknite na 'nastavi' da biste prešli na prijavni obrazac.",
        {
          variant: "success",
        }
      );
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const [createAdminMutation, { loading: loadingCreateAdmin }] = useMutation<
    ICreateAdminData,
    ICreateAdminVars
  >(CREATE_ADMIN, {
    onCompleted: (data) => {
      enqueueSnackbar("Vaš nalog je kreiran!", {
        variant: "success",
      });
      handleNavigateToLogin();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const handleContinue = () => {
    const token = searchParams.get("token");

    if (!token) {
      enqueueSnackbar("Token nedostaje.", {
        variant: "error",
      });
      return;
    }

    if (data?.checkAdminInvite === false) {
      if (validateForm(inputFieldNames)) {
        createAdminMutation({
          variables: {
            data: {
              token: token,
              password: inputFields.password.inputProps.value,
              name: inputFields.name.inputProps.value,
            },
          },
        });
      }
      return;
    }
    checkAdminInviteMutation({
      variables: {
        token: token,
      },
    });
  };

  const handleNavigateToLogin = async () => {
    if (user) {
      await logoutMutation();
      setUser(undefined);
    }
    navigate("/login");
  };

  if (tokenError) {
    return <ErrorComponent />;
  }

  return (
    <main className={cx(classes.root)}>
      <Paper className={cx(classes.paper)}>
        {data?.checkAdminInvite ? (
          <>
            <Typography variant="h4" component="h1" paragraph>
              Dobrodošli
            </Typography>
            <Typography marginBottom={2}>
              You have accepted the invitation. Press continue to proceed to
              login page.
            </Typography>
            <Button
              className={cx(classes.button)}
              variant="contained"
              fullWidth
              onClick={handleNavigateToLogin}
            >
              Nastavi
            </Button>
          </>
        ) : data?.checkAdminInvite === false ? (
          <>
            <Typography variant="h4" component="h1" paragraph>
              Registration
            </Typography>
            <Typography marginBottom={2}>
              Please enter your name and password to complete the registration
            </Typography>

            <TextField
              {...inputFields.name.inputProps}
              autoFocus
              margin="normal"
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              {...inputFields.password.inputProps}
              margin="normal"
              autoComplete="password"
              onKeyDown={handleEnterKey}
              fullWidth
              type={showPassword ? "text" : "password"}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              className={cx(classes.button)}
              variant="contained"
              fullWidth
              onClick={handleContinue}
              disabled={
                !inputFields.name.inputProps.value ||
                !inputFields.password.inputProps.value
              }
            >
              Register
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h4" component="h1" paragraph>
              Dobrodošli
            </Typography>
            <Typography
              marginBottom={2}
              //TODO: Add subject name
            >
              You have been invited to join Palata nauke CMS! Press continue to
              proceed with registration.
            </Typography>

            <Button
              className={cx(classes.button)}
              variant="contained"
              fullWidth
              onClick={handleContinue}
            >
              Nastavi
            </Button>
          </>
        )}
      </Paper>
      <LoadingBackdrop
        loading={loading || loadingCreateAdmin || loadingLogout}
      />
    </main>
  );
};
