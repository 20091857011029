import { gql } from "@apollo/client";

export interface IRewardReception {
  id: string;

  locale: {
    id: string;
    title: string;
    description: string;
    languageFlag: {
      id: string;
      isoLanguageCode: string;
    };
  };
}

export interface IRewardReceptionData {
  oneRewardReception?: IRewardReception;
}

export interface IRewardReceptionVars {
  code: string;
}

export const ONE_REWARD_RECEPTION = gql`
  query oneRewardReception($code: String!) {
    oneRewardReception(code: $code) {
      id
      locale(lang: "sr") {
        id
        title
        description
        languageFlag {
          id
          isoLanguageCode
        }
      }
    }
  }
`;
