import { gql } from "@apollo/client";
export interface IDeleteNotification {
  id: string;
}
export interface IDeleteNotificationData {
  deleteNotification: IDeleteNotification;
}
export interface IDeleteNotificationVars {
  id: number;
}
export const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($id: ID!) {
    deleteNotification(id: $id) {
      id
    }
  }
`;

//*-------Delete locale
export interface IDeleteNotificationLocale {
  id: string;
  localeId: string;
}

export interface IDeleteNotificationLocaleData {
  deleteNotificationLocale: IDeleteNotificationLocale;
}
export interface IDeleteNotificationLocaleVars {
  id: number;
}

export const DELETE_NOTIFICATION_LOCALE = gql`
  mutation deleteNotificationLocale($id: ID!) {
    deleteNotificationLocale(id: $id) {
      id
      localeId
    }
  }
`;
