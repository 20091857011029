import { gql } from "@apollo/client";

export interface IMedia {
  id: string;
  fileName: string;
  bucketPath: string;
  byteSize: number;
  mimeType: string;
  extension: string;
  // _count: {
  //   StationMedia: number;
  //   TourMedia: number;
  // };
}

export interface IMediaWithoutCount {
  id: string;
  fileName: string;
  bucketPath: string;
  byteSize: number;
  mimeType: string;
  extension: string;
}

export interface IMediasData {
  allMedias?: IMedia[];
}

export interface IMediasVars {
  type: "audio" | "image" | undefined;
}

export const ALL_MEDIAS = gql`
  query allMedias($type: String) {
    allMedias(type: $type) {
      id
      fileName
      bucketPath
      byteSize
      mimeType
      extension
      # _count {
      #   StationMedia
      #   TourMedia
      # }
    }
  }
`;
