import { gql } from "@apollo/client";

export interface ICreateMapPin {
  id: string;
  published: boolean;
  posX: string | null;
  posY: string | null;
  label: string | null;
  mapId: string | null;
  skipLegend: boolean | null;

  locale: {
    id: string;
    name: string | null;
  };
  created: string;
}

export interface ICreateMapPinData {
  createMapPin: ICreateMapPin;
}
export interface ICreateMapPinVars {
  data: {
    mapId: number | undefined;
    label: string | undefined;
    posX: string | undefined;
    posY: string | undefined;
    skipLegend: boolean | undefined;
  };
  dataLocale: {
    name: string | undefined;
  };
}

export const CREATE_MAP_PIN = gql`
  mutation createMapPin(
    $data: MapPinCreateInput!
    $dataLocale: MapPinLocaleCreateInitialInput!
  ) {
    createMapPin(data: $data, dataLocale: $dataLocale) {
      id
      published
      mapId
      posX
      posY
      label
      skipLegend
      locale {
        id
        name
      }
      created
    }
  }
`;
