import {
  Button,
  Divider,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
// import { useStyles } from "./ClassicNotificationPage.styles";
import {
  DataHandlerComponent,
  FlagList,
  LoadingBackdrop,
  PageLayout,
  ReadyToPublish,
} from "../../../components";
import { useGlobalStyles } from "../../../utils/theme";
import {
  ERouterPaths,
  IMediaSelectDialogContext,
  INPUT_NOTIFICATION_LINK_MAX_LENGTH,
  INPUT_NOTIFICATION_MESSAGE_MAX_LENGTH,
  INPUT_NOTIFICATION_MESSAGE_PUSH_MAX_LENGTH,
  INPUT_NOTIFICATION_SUBTITLE_PUSH_MAX_LENGTH,
  INPUT_NOTIFICATION_TITLE_MAX_LENGTH,
  INPUT_NOTIFICATION_TITLE_PUSH_MAX_LENGTH,
  MAIN_ISO_LANGUAGE_CODE,
  MSG_UNSAVED_CHANGES,
  useForm,
} from "../../../utils";
import { initialInputData } from "./NotificationPage.inputs";
import { useSnackbar } from "notistack";
import {
  Reference,
  StoreObject,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import {
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_LOCALE,
  DELETE_NOTIFICATION_LOCALE,
  ICreateNotificationData,
  ICreateNotificationLocaleData,
  ICreateNotificationLocaleVars,
  ICreateNotificationVars,
  IDeleteNotificationLocaleData,
  IDeleteNotificationLocaleVars,
  IUpdateNotificationLocaleData,
  IUpdateNotificationLocaleVars,
  IUpdateNotificationPublishedData,
  IUpdateNotificationPublishedVars,
  IUpdateNotificationSendData,
  IUpdateNotificationSendVars,
  UPDATE_NOTIFICATION_LOCALE,
  UPDATE_NOTIFICATION_PUBLISHED,
  UPDATE_NOTIFICATION_SEND,
} from "../../../apollo/mutations";
import { useNavigate, useParams } from "react-router-dom";
import {
  ALL_NOTIFICATIONS,
  INotification,
  INotificationDetailsData,
  INotificationDetailsVars,
  INotificationsData,
  ONE_NOTIFICATION_DETAILS,
} from "../../../apollo/queries";
import {
  MediaSelectButton,
  MediaSelectorDialog,
} from "../../../components/mediaSelectorContent/components";
import { useStyles } from "./NotificationPage.styles";
import { SendOutlined as SendOutlinedIcon } from "@mui/icons-material";

export const NotificationPage: FC = () => {
  const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();
  const { notificationId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isDataSet = useRef(false);

  const isEdit = notificationId !== undefined;

  const [mediaSelectDialogOpen, setMediaSelectDialogOpen] =
    useState<IMediaSelectDialogContext>({
      open: false,
      type: undefined,
      max: undefined,
      onChange: undefined,
      selectedMedia: [],
    });

  const handleCloseMediaSelectDialog = () => {
    setMediaSelectDialogOpen((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const [queryOneNotificationDetails, { loading, data, error }] = useLazyQuery<
    INotificationDetailsData,
    INotificationDetailsVars
  >(ONE_NOTIFICATION_DETAILS);

  //*---------------MUTATIONS
  const {
    inputFields,
    activeLocale,
    setActiveLocale,
    currentLanguageFlags,
    setCurrentLanguageFlags,
    didValuesChange,
    handleDataToVar,
    getFormValuesFromFetchedData,
    inputFieldNames,
    validateForm,
    resetFields,
    // formError,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [
    createNotificationMutation,
    { loading: loadingCreateNotificationMutation },
  ] = useMutation<ICreateNotificationData, ICreateNotificationVars>(
    CREATE_NOTIFICATION,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Kreirano je novo Obaveštenje: ${
            data.createNotification.locale.title ||
            "Nepoznat naslov obaveštenja"
          }`,
          {
            variant: "success",
          }
        );
        resetFields(inputFieldNames, true, true);
        navigate(
          `/${ERouterPaths.NOTIFICATIONS}/${data.createNotification.id}`
        );
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      //TODO: Check if no classicTours
      update: (cache, { data }) => {
        const existingListData: INotificationsData | null = cache.readQuery({
          query: ALL_NOTIFICATIONS,
        });
        if (data?.createNotification) {
          const newNotificationData: INotification = {
            ...data.createNotification,
          };
          cache.writeQuery({
            query: ALL_NOTIFICATIONS,
            data: {
              allNotifications: existingListData?.allNotifications
                ? [newNotificationData, ...existingListData.allNotifications]
                : [newNotificationData],
            },
          });
        }
      },
    }
  );

  const [
    updateNotificationLocaleMutation,
    { loading: loadingUpdateNotificationLocaleMutation },
  ] = useMutation<IUpdateNotificationLocaleData, IUpdateNotificationLocaleVars>(
    UPDATE_NOTIFICATION_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Lokalizacija je ažurirana: ${
            data.updateNotificationLocale.title || "Bezimenno obaveštenje"
          }`,
          {
            variant: "success",
          }
        );
        resetFields(inputFieldNames, true);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      //TODO: Ažuriraj!
      //? Not needed?
      // update: (cache, { data }) => {
      //   const existingListData: INotificationsData | null = cache.readQuery({
      //     query: ALL_NOTIFICATIONS,
      //   });
      //   if (data?.createNotification) {
      //     const newNotificationData: INotification = {
      //       ...data.createNotification,
      //     };
      //     cache.writeQuery({
      //       query: ALL_NOTIFICATIONS,
      //       data: {
      //         allNotifications: existingListData?.allNotifications
      //           ? [newNotificationData, ...existingListData.allNotifications]
      //           : [newNotificationData],
      //       },
      //     });
      //   }
      // },
    }
  );

  const [
    deleteNotificationLocaleMutation,
    { loading: loadingDeleteNotificationLocaleMutation },
  ] = useMutation<IDeleteNotificationLocaleData, IDeleteNotificationLocaleVars>(
    DELETE_NOTIFICATION_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Jezik obaveštenja je obrisan!", {
          variant: "success",
        });
        resetFields(inputFieldNames, true);
        setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
        const newCurrentFlags = currentLanguageFlags.filter(
          (x) => x.id !== data.deleteNotificationLocale.localeId
        );
        setCurrentLanguageFlags(newCurrentFlags);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update(cache, { data: dataCreate }) {
        if (data?.oneNotificationDetails) {
          cache.modify({
            id: cache.identify(
              data.oneNotificationDetails as unknown as StoreObject
            ),
            fields: {
              allLocales(existingData: Array<Reference>, { readField }) {
                if (data) {
                  if (existingData && dataCreate) {
                    return existingData.filter(
                      (taskRef) =>
                        dataCreate.deleteNotificationLocale.id !==
                        readField("id", taskRef)
                    );
                  }
                }
              },
            },
          });
        }
      },
    }
  );

  const handleDeleteNotificationLocale = () => {
    const localeId = data?.oneNotificationDetails?.allLocales?.find(
      (x) => x.languageFlag.isoLanguageCode === activeLocale
    )?.id;
    if (localeId) {
      deleteNotificationLocaleMutation({
        variables: {
          id: +localeId,
        },
      });
    } else {
      const newCurrentFlags = currentLanguageFlags.filter(
        (x) => x.isoLanguageCode !== activeLocale
      );
      resetFields(inputFieldNames, true);
      setCurrentLanguageFlags(newCurrentFlags);
      setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
      enqueueSnackbar("Lokalizacija je uklonjena!", { variant: "success" });
    }
  };

  const [
    updateNotificationPublishedMutation,
    { loading: loadingUpdateNotificationPublishedMutation },
  ] = useMutation<
    IUpdateNotificationPublishedData,
    IUpdateNotificationPublishedVars
  >(UPDATE_NOTIFICATION_PUBLISHED, {
    onCompleted: (data) => {
      enqueueSnackbar(
        `Notification is now ${
          data.updateNotificationPublished?.published
            ? "objavljena"
            : "neobjavljena"
        }!`,
        {
          variant: "success",
        }
      );
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const [
    updateNotificationSendMutation,
    { loading: loadingUpdateNotificationSendMutation },
  ] = useMutation<IUpdateNotificationSendData, IUpdateNotificationSendVars>(
    UPDATE_NOTIFICATION_SEND,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Obaveštenje je poslato.", {
          variant: "success",
        });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    }
  );

  const handleUpdateNotificationSend = () => {
    if (data && isEdit && notificationId) {
      if (didValuesChange(inputFieldNames, true)) {
        enqueueSnackbar(MSG_UNSAVED_CHANGES, { variant: "warning" });
        return;
      }
      if (validateForm(inputFieldNames, true, true)) {
        updateNotificationSendMutation({
          variables: {
            id: +notificationId,
          },
        });
      }
    } else {
      enqueueSnackbar("ID nije pronađen!", {
        variant: "error",
      });
    }
  };

  const [
    createNotificationLocaleMutation,
    { loading: loadingCreateNotificationLocaleMutation },
  ] = useMutation<ICreateNotificationLocaleData, ICreateNotificationLocaleVars>(
    CREATE_NOTIFICATION_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Lokalizacija je kreirana: ${
            data.createNotificationLocale.title || "Nepoznato obaveštenje"
          }`,
          {
            variant: "success",
          }
        );
        resetFields(inputFieldNames, true);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update: (cache, { data: dataCreate }) => {
        // console.log(cache);
        if (data?.oneNotificationDetails) {
          cache.modify({
            id: cache.identify(
              data.oneNotificationDetails as unknown as StoreObject
            ),
            fields: {
              allLocales(existingData: Array<Reference>, { readField }) {
                if (data) {
                  if (existingData && dataCreate) {
                    return [
                      ...existingData,
                      {
                        // @ts-ignore
                        __ref: `${dataCreate.createNotificationLocale.__typename}:${dataCreate.createNotificationLocale.id}`,
                      },
                    ];
                  }
                }
              },
            },
          });
        }
      },
    }
  );
  //*-----------------------
  useEffect(() => {
    if (notificationId) {
      queryOneNotificationDetails({ variables: { id: +notificationId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationId]);

  const handleCreateNotification = () => {
    if (!validateForm(inputFieldNames, false)) {
      return;
    }

    createNotificationMutation({
      variables: {
        dataLocale: {
          title: handleDataToVar("title", "string", false),
          body: handleDataToVar("body", "string", false),
          links: handleDataToVar("links", "string", false),
          titlePush: handleDataToVar("titlePush", "string", false),
          subtitlePush: handleDataToVar("subtitlePush", "string", false),
          bodyPush: handleDataToVar("bodyPush", "string", false),
          gallery: handleDataToVar("gallery", "numberArray", false),
        },
      },
    });
  };

  useEffect(() => {
    if (!data?.oneNotificationDetails || isDataSet.current) {
      return;
    }
    getFormValuesFromFetchedData(
      data.oneNotificationDetails,
      [
        {
          fromDataProperty: "allLocales.title",
          toFormProperty: "title",
        },
        {
          fromDataProperty: "allLocales.body",
          toFormProperty: "body",
        },
        {
          fromDataProperty: "allLocales.links",
          toFormProperty: "links",
        },
        {
          fromDataProperty: "allLocales.titlePush",
          toFormProperty: "titlePush",
        },
        {
          fromDataProperty: "allLocales.subtitlePush",
          toFormProperty: "subtitlePush",
        },
        {
          fromDataProperty: "allLocales.bodyPush",
          toFormProperty: "bodyPush",
        },
        {
          fromDataProperty: "allLocales.gallery.id",
          toFormProperty: "gallery",
        },
      ],
      true,
      true
    );

    isDataSet.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.oneNotificationDetails]);

  const handleUpdateLocale = () => {
    if (
      !validateForm(
        inputFieldNames,
        Boolean(data?.oneNotificationDetails.published)
      )
    ) {
      return;
    }

    const getUpdateLocaleId = isEdit
      ? data?.oneNotificationDetails.allLocales?.find(
          (x) => x.languageFlag?.isoLanguageCode === activeLocale
        )?.id
      : undefined;

    if (getUpdateLocaleId) {
      updateNotificationLocaleMutation({
        variables: {
          id: +getUpdateLocaleId,
          data: {
            title: handleDataToVar("title", "string"),
            body: handleDataToVar("body", "string"),
            links: handleDataToVar("links", "string"),
            titlePush: handleDataToVar("titlePush", "string"),
            subtitlePush: handleDataToVar("subtitlePush", "string"),
            bodyPush: handleDataToVar("bodyPush", "string"),
            gallery: handleDataToVar("gallery", "numberArray"),
          },
        },
      });
    } else if (notificationId) {
      createNotificationLocaleMutation({
        variables: {
          notificationId: +notificationId,
          data: {
            title: handleDataToVar("title", "string", false),
            body: handleDataToVar("body", "string", false),
            links: handleDataToVar("links", "string", false),
            titlePush: handleDataToVar("titlePush", "string", false),
            subtitlePush: handleDataToVar("subtitlePush", "string", false),
            bodyPush: handleDataToVar("bodyPush", "string", false),
            gallery: handleDataToVar("gallery", "numberArray", false),
            isoLanguageCode: activeLocale,
          },
        },
      });
    }
  };

  const handleUpdateNotificationPublished = () => {
    if (data && isEdit && notificationId) {
      if (
        didValuesChange(inputFieldNames, true) &&
        !data.oneNotificationDetails.published
      ) {
        enqueueSnackbar(MSG_UNSAVED_CHANGES, { variant: "warning" });
        return;
      }
      if (
        validateForm(
          inputFieldNames,
          !data.oneNotificationDetails.published,
          true
        )
      ) {
        updateNotificationPublishedMutation({
          variables: {
            id: +notificationId,
            publish: data?.oneNotificationDetails.published ? false : true,
          },
        });
      }
    } else {
      enqueueSnackbar("ID nije pronađen!", {
        variant: "error",
      });
    }
  };

  return (
    <PageLayout displayFlex>
      <Paper className={globalClasses.paperRoot}>
        <div className={globalClasses.paperTitle}>
          <div className={globalClasses.justifySpaceBetween}>
            <Typography variant="h6">
              {isEdit ? "Uredi obaveštenje" : "Dodaj novo obaveštenje"}
            </Typography>
            {isEdit && data?.oneNotificationDetails ? (
              <ReadyToPublish
                skipCheck={true}
                handlePublish={handleUpdateNotificationPublished}
                checkPassed={true}
                published={data.oneNotificationDetails.published}
                publishedThingText="Obaveštenje"
              />
            ) : null}
          </div>
        </div>
        <DataHandlerComponent
          hasData={Boolean(!isEdit || (isEdit && data?.oneNotificationDetails))}
          error={Boolean(error)}
          loading={loading}
        >
          <div className={globalClasses.paperContainer}>
            <FlagList
              deleteLocaleFlagMutation={handleDeleteNotificationLocale}
              canSelectFlags={isEdit}
              activeLocale={activeLocale}
              setActiveLocale={setActiveLocale}
              currentLanguageFlags={currentLanguageFlags}
              setCurrentLanguageFlags={setCurrentLanguageFlags}
              type="Obaveštenje"
              tooltip='Novi prevodi biće vidljivi korisnicima samo ako ste ih već dodali u odeljku "O nama".'
            />
            {!isEdit ? (
              <Typography color="textSecondary">
                Dodajte prve detalje o obaveštenju koje želite da kreirate.
              </Typography>
            ) : null}

            <TextField
              margin="normal"
              {...inputFields.title.inputProps}
              autoFocus
              fullWidth
              inputProps={{
                maxLength: INPUT_NOTIFICATION_TITLE_MAX_LENGTH,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="caption">{`${inputFields.title.inputProps.value.length}/${INPUT_NOTIFICATION_TITLE_MAX_LENGTH}`}</Typography>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              margin="normal"
              {...inputFields.body.inputProps}
              multiline
              minRows={6}
              maxRows={10}
              fullWidth
              inputProps={{
                maxLength: INPUT_NOTIFICATION_MESSAGE_MAX_LENGTH,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="caption">{`${inputFields.body.inputProps.value.length}/${INPUT_NOTIFICATION_MESSAGE_MAX_LENGTH}`}</Typography>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              margin="normal"
              {...inputFields.links.inputProps}
              fullWidth
              inputProps={{
                maxLength: INPUT_NOTIFICATION_LINK_MAX_LENGTH,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="caption">{`${inputFields.links.inputProps.value.length}/${INPUT_NOTIFICATION_LINK_MAX_LENGTH}`}</Typography>
                  </InputAdornment>
                ),
              }}
            />

            <MediaSelectButton
              setMediaSelectDialogOpen={setMediaSelectDialogOpen}
              max={8}
              pickType="image"
              {...inputFields.gallery.inputProps}
            />

            <Divider className={classes.margin} />

            {!isEdit ? (
              <Typography variant="h6" color="textSecondary">
                Dodajte detalje za push obaveštenje
              </Typography>
            ) : null}

            <TextField
              margin="normal"
              {...inputFields.titlePush.inputProps}
              fullWidth
              inputProps={{
                maxLength: INPUT_NOTIFICATION_TITLE_PUSH_MAX_LENGTH,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="caption">{`${inputFields.titlePush.inputProps.value.length}/${INPUT_NOTIFICATION_TITLE_PUSH_MAX_LENGTH}`}</Typography>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              margin="normal"
              {...inputFields.subtitlePush.inputProps}
              fullWidth
              inputProps={{
                maxLength: INPUT_NOTIFICATION_SUBTITLE_PUSH_MAX_LENGTH,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="caption">{`${inputFields.subtitlePush.inputProps.value.length}/${INPUT_NOTIFICATION_SUBTITLE_PUSH_MAX_LENGTH}`}</Typography>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              margin="normal"
              {...inputFields.bodyPush.inputProps}
              multiline
              minRows={6}
              maxRows={10}
              fullWidth
              inputProps={{
                maxLength: INPUT_NOTIFICATION_MESSAGE_PUSH_MAX_LENGTH,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="caption">{`${inputFields.bodyPush.inputProps.value.length}/${INPUT_NOTIFICATION_MESSAGE_PUSH_MAX_LENGTH}`}</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={globalClasses.paperButtons}>
            <div className={globalClasses.paperButtonsLeft}>
              {isEdit ? (
                <Tooltip title="Možete poslati push obaveštenje samo jednom. Prvo dodajte sve željene lokalizacije, a zatim kliknite na ovo dugme.">
                  <Button
                    onClick={handleUpdateNotificationSend}
                    variant={
                      data?.oneNotificationDetails.sent
                        ? "contained"
                        : "outlined"
                    }
                    disabled={data?.oneNotificationDetails.sent}
                    startIcon={<SendOutlinedIcon />}
                  >
                    {data?.oneNotificationDetails.sent
                      ? "Push obaveštenje poslato"
                      : `Pošaljite push obaveštenje: [${data?.oneNotificationDetails.allLocales
                          ?.map((item) => item.languageFlag.isoLanguageCode)
                          .join(", ")}]`}
                  </Button>
                </Tooltip>
              ) : (
                <Typography>
                  Obaveštenje još neće biti poslato, možete ga poslati nakon što
                  kreirate prvu lokalizaciju.
                </Typography>
              )}
            </div>
            <div className={globalClasses.paperButtonsRight}>
              {isEdit ? (
                <Button
                  onClick={handleUpdateLocale}
                  variant="contained"
                  disabled={
                    !didValuesChange(inputFieldNames) &&
                    !!data?.oneNotificationDetails.allLocales?.find(
                      (x) => x.languageFlag?.isoLanguageCode === activeLocale
                    )?.id
                  }
                >
                  {!data?.oneNotificationDetails.allLocales?.find(
                    (x) => x.languageFlag?.isoLanguageCode === activeLocale
                  )?.id
                    ? "kreiraj "
                    : "ažuriraj "}
                  | {activeLocale} |
                </Button>
              ) : (
                <Button onClick={handleCreateNotification} variant="contained">
                  Završite kreiranje obaveštenja
                </Button>
              )}
            </div>
          </div>
        </DataHandlerComponent>
      </Paper>
      <LoadingBackdrop
        loading={
          loadingCreateNotificationMutation ||
          loadingUpdateNotificationLocaleMutation ||
          loadingCreateNotificationLocaleMutation ||
          loadingUpdateNotificationPublishedMutation ||
          loadingDeleteNotificationLocaleMutation ||
          loadingUpdateNotificationSendMutation
        }
      />
      <MediaSelectorDialog
        dialogContext={mediaSelectDialogOpen}
        onClose={handleCloseMediaSelectDialog}
      />
    </PageLayout>
  );
};
