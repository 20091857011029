import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useStyles } from "./StationPage.styles";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import {
  DataHandlerComponent,
  FlagList,
  LoadingBackdrop,
  PageLayout,
  ReadyToPublish,
} from "../../../components";
import { useGlobalStyles } from "../../../utils/theme";
import {
  ERouterPaths,
  IMediaSelectDialogContext,
  INPUT_STATION_AUDIO_GUIDE_TEXT_MAX_LENGTH,
  INPUT_STATION_CODE_MAX_LENGTH,
  INPUT_STATION_DESCRIPTION_MAX_LENGTH,
  INPUT_STATION_TITLE_MAX_LENGTH,
  MAIN_ISO_LANGUAGE_CODE,
  MSG_GENERIC_ERROR,
  MSG_UNSAVED_CHANGES,
  useForm,
} from "../../../utils";
import { initialInputData } from "./StationPage.inputs";
import {
  ArrowBackOutlined as ArrowBackOutlinedIcon,
  ChevronRightOutlined as ChevronRightOutlinedIcon,
} from "@mui/icons-material";
import { ChevronLeftOutlined as ChevronLeftOutlinedIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Reference, StoreObject, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_STATION,
  CREATE_STATION_LOCALE,
  DELETE_STATION_LOCALE,
  ICreateStationData,
  ICreateStationLocaleData,
  ICreateStationLocaleVars,
  ICreateStationVars,
  IDeleteStationLocaleData,
  IDeleteStationLocaleVars,
  IUpdateStationLocaleData,
  IUpdateStationLocaleVars,
  IUpdateStationPublishedData,
  IUpdateStationPublishedVars,
  IUpdateStationSharingData,
  IUpdateStationSharingVars,
  UPDATE_STATION_LOCALE,
  UPDATE_STATION_PUBLISHED,
  UPDATE_STATION_SHARING,
} from "../../../apollo/mutations";
import { useNavigate, useParams } from "react-router";
import {
  IClassicTourDetails,
  IClassicTourDetailsData,
  IClassicTourDetailsVars,
  IStationDetailsData,
  IStationDetailsVars,
  ONE_CLASSIC_TOUR_DETAILS,
  ONE_STATION_DETAILS,
} from "../../../apollo/queries";
import {
  MediaSelectButton,
  MediaSelectorDialog,
} from "../../../components/mediaSelectorContent/components";

export const StationPage: FC = () => {
  const { classes } = useStyles();
  const { cx, classes: globalClasses } = useGlobalStyles();
  const { stationId, classicTourId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isDataSet = useRef(false);

  const TOTAL_TABS = "2";
  const isEdit = stationId !== undefined;

  const [activeTab, setActiveTab] = useState("1");

  const [mediaSelectDialogOpen, setMediaSelectDialogOpen] =
    useState<IMediaSelectDialogContext>({
      open: false,
      type: undefined,
      max: undefined,
      onChange: undefined,
      selectedMedia: [],
    });

  const handleCloseMediaSelectDialog = () => {
    setMediaSelectDialogOpen((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const { loading, data, error } = useQuery<
    IStationDetailsData,
    IStationDetailsVars
  >(ONE_STATION_DETAILS, {
    skip: !isEdit,
    variables: {
      id: stationId ? +stationId : 0,
    },
    onError: (err) => {
      enqueueSnackbar(err.message, { variant: "error" });
    },
  });

  const {
    inputFields,
    validateForm,
    activeLocale,
    currentLanguageFlags,
    didValuesChange,
    // formError,
    getFormValuesFromFetchedData,
    inputFieldNames,
    handleDataToVar,
    resetFields,
    setActiveLocale,
    setCurrentLanguageFlags,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [createStationMutation, { loading: loadingCreateStationMutation }] =
    useMutation<ICreateStationData, ICreateStationVars>(CREATE_STATION, {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Nova stanica je kreirana: ${data.createStation.locale.title}`,
          {
            variant: "success",
          }
        );
        if (classicTourId) {
          navigate(
            `/${ERouterPaths.CLASSIC_TOURS}/${classicTourId}?activeTab=3`
          );
        } else {
          navigate(`/${ERouterPaths.CLASSIC_TOURS}`);
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      //TODO: Check if no stations
      update: (cache, { data }) => {
        if (!classicTourId) {
          enqueueSnackbar(MSG_GENERIC_ERROR, { variant: "error" });
          return;
        }

        const existingData = cache.readQuery<
          IClassicTourDetailsData,
          IClassicTourDetailsVars
        >({
          query: ONE_CLASSIC_TOUR_DETAILS,
          variables: {
            id: +classicTourId,
          },
        });

        if (!data?.createStation || !existingData?.oneClassicTourDetails) {
          enqueueSnackbar(MSG_GENERIC_ERROR, { variant: "error" });
          return;
        }
        const updatedClassicTourDetails: IClassicTourDetails = {
          ...existingData.oneClassicTourDetails,
          allStations: [
            ...existingData.oneClassicTourDetails.allStations,
            data.createStation,
          ],
        };
        cache.writeQuery({
          query: ONE_CLASSIC_TOUR_DETAILS,

          variables: {
            id: +classicTourId,
          },
          data: {
            oneClassicTourDetails: updatedClassicTourDetails,
          },
        });
      },
    });

  const [
    updateStationLocaleMutation,
    { loading: loadingUpdateStationLocaleMutation },
  ] = useMutation<IUpdateStationLocaleData, IUpdateStationLocaleVars>(
    UPDATE_STATION_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Lokalizacija je ažurirana: ${data.updateStationLocale.title}`,
          {
            variant: "success",
          }
        );
        resetFields(
          ["audioGuide", "audioGuideText", "description", "gallery", "title"],
          true
        );
      },

      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      onQueryUpdated: () => undefined,
    }
  );

  // const [
  //   updateStationOtherMutation,
  //   { loading: loadingUpdateStationOtherMutation },
  // ] = useMutation<IUpdateStationOtherData, IUpdateStationOtherVars>(
  //   UPDATE_STATION_OTHER,
  //   {
  //     onCompleted: (data) => {
  //       enqueueSnackbar("Ostale informacije su ažurirane", {
  //         variant: "success",
  //       });
  //     },
  //     onError: (error) => {
  //       enqueueSnackbar(error.message, {
  //         variant: "error",
  //       });
  //     },
  //   }
  // );

  const [
    updateStationSharingMutation,
    { loading: loadingUpdateStationSharingMutation },
  ] = useMutation<IUpdateStationSharingData, IUpdateStationSharingVars>(
    UPDATE_STATION_SHARING,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Informacije za deljenje su ažurirane", {
          variant: "success",
        });
        resetFields(["textCode"], true);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    }
  );

  const [
    createStationLocaleMutation,
    { loading: loadingCreateStationLocaleMutation },
  ] = useMutation<ICreateStationLocaleData, ICreateStationLocaleVars>(
    CREATE_STATION_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar(
          `Lokalizacija je kreirana: ${data.createStationLocale.title}`,
          {
            variant: "success",
          }
        );
        resetFields(
          ["audioGuide", "audioGuideText", "description", "gallery", "title"],
          true
        );
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update: (cache, { data: dataCreate }) => {
        // console.log(cache);
        if (data?.oneStationDetails) {
          cache.modify({
            id: cache.identify(
              data.oneStationDetails as unknown as StoreObject
            ),
            fields: {
              allLocales(existingData: Array<Reference>, { readField }) {
                if (data) {
                  if (existingData && dataCreate) {
                    return [
                      ...existingData,
                      {
                        // @ts-ignore
                        __ref: `${dataCreate.createStationLocale.__typename}:${dataCreate.createStationLocale.id}`,
                      },
                    ];
                  }
                }
              },
            },
          });
        }
      },
    }
  );

  const [
    updateStationPublishedMutation,
    { loading: loadingUpdateStationPublishedMutation },
  ] = useMutation<IUpdateStationPublishedData, IUpdateStationPublishedVars>(
    UPDATE_STATION_PUBLISHED,
    {
      onCompleted: (data) => {
        if (!data.updateStationPublished) {
          enqueueSnackbar(`Station spremno za objavljivanje!`, {
            variant: "info",
          });
          // setCheckPassedPublishTest(true);
        } else {
          enqueueSnackbar(
            `Stanica je sada ${
              data.updateStationPublished.published
                ? "objavljena"
                : "neobjavljena"
            }!`,
            {
              variant: "success",
            }
          );
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    }
  );

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setActiveTab(newTab);
  };

  const handleNextTab = () => {
    const nextTab = (parseInt(activeTab) + 1).toString();
    setActiveTab(nextTab);
  };

  const handlePrevTab = () => {
    const prevTab = (parseInt(activeTab) - 1).toString();
    setActiveTab(prevTab);
  };

  const handleCreateStation = () => {
    if (stationId || !classicTourId) {
      enqueueSnackbar(MSG_GENERIC_ERROR, { variant: "error" });
      return;
    }

    if (validateForm(inputFieldNames, false)) {
      createStationMutation({
        variables: {
          data: {
            textCode: handleDataToVar("textCode", "string", false),
            classicTourId: +classicTourId,
          },
          dataLocale: {
            gallery: handleDataToVar("gallery", "numberArray", false),
            title: handleDataToVar("title", "string", false),
            description: handleDataToVar("description", "string", false),
            audioGuideText: handleDataToVar("audioGuideText", "string", false),
            audioGuideId: handleDataToVar("audioGuide", "number", false),
          },
        },
      });
    }
  };

  useEffect(() => {
    if (!data?.oneStationDetails || isDataSet.current) {
      return;
    }
    getFormValuesFromFetchedData(
      data.oneStationDetails,
      [
        {
          fromDataProperty: "allLocales.title",
          toFormProperty: "title",
        },
        {
          fromDataProperty: "allLocales.description",
          toFormProperty: "description",
        },
        {
          fromDataProperty: "allLocales.audioGuide.id",
          toFormProperty: "audioGuide",
        },
        {
          fromDataProperty: "allLocales.audioGuideText",
          toFormProperty: "audioGuideText",
        },
        {
          fromDataProperty: "allLocales.gallery.id",
          toFormProperty: "gallery",
        },
        {
          fromDataProperty: "textCode",
          toFormProperty: "textCode",
        },
      ],
      true,
      true
    );
    isDataSet.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.oneStationDetails]);

  const handleUpdateLocale = () => {
    if (
      !validateForm(
        ["title", "description", "audioGuide", "audioGuideText"],
        Boolean(data?.oneStationDetails.published)
      )
    ) {
      return null;
    }

    const getUpdateLocaleId = isEdit
      ? data?.oneStationDetails.allLocales?.find(
          (x) => x.languageFlag?.isoLanguageCode === activeLocale
        )?.id
      : undefined;

    if (getUpdateLocaleId) {
      updateStationLocaleMutation({
        variables: {
          id: +getUpdateLocaleId,
          data: {
            title: handleDataToVar("title", "string"),
            description: handleDataToVar("description", "string"),
            audioGuideText: handleDataToVar("audioGuideText", "string"),
            audioGuideId: handleDataToVar("audioGuide", "number"),
            gallery: handleDataToVar("gallery", "numberArray"),
          },
        },
      });
    } else if (stationId) {
      createStationLocaleMutation({
        variables: {
          stationId: +stationId,
          data: {
            title: handleDataToVar("title", "string", false),
            description: handleDataToVar("description", "string", false),
            audioGuideText: handleDataToVar("audioGuideText", "string", false),
            audioGuideId: handleDataToVar("audioGuide", "number", false),
            gallery: handleDataToVar("gallery", "numberArray", false),
            isoLanguageCode: activeLocale,
          },
        },
      });
    }
  };

  // const handleUpdateOther = () => {
  //   const fields = ["gallery"];
  //   const result = validateForm(fields, inputFields, localeSelected);
  //   if (result.formValid) {
  //     if (isEdit && id) {
  //       const preparedMedia: { id: number }[] =
  //         inputFields.gallery.inputProps.value.map((x: IMedia) => {
  //           return { id: +x.id };
  //         });
  //       updateStationOtherMutation({
  //         variables: {
  //           id: +id,
  //           data: {
  //             gallery: preparedMedia,
  //           },
  //         },
  //       });
  //     } else {
  //       enqueueSnackbar("ID nije pronađen!", {
  //         variant: "error",
  //       });
  //     }
  //   } else {
  //     enqueueSnackbar("Required fields are not set", {
  //       variant: "error",
  //     });
  //     setInputFields((prevState) => ({ ...prevState, ...result.outputData }));
  //   }
  // };

  const [
    deleteStationLocaleMutation,
    { loading: loadingDeleteStationLocaleMutation },
  ] = useMutation<IDeleteStationLocaleData, IDeleteStationLocaleVars>(
    DELETE_STATION_LOCALE,
    {
      onCompleted: (data) => {
        enqueueSnackbar("Jezik stanice je obrisan", {
          variant: "success",
        });
        resetFields([
          "audioGuide",
          "audioGuideText",
          "description",
          "gallery",
          "title",
        ]);
        setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
        const newCurrentFlags = currentLanguageFlags.filter(
          (x) => x.id !== data.deleteStationLocale.localeId
        );
        setCurrentLanguageFlags(newCurrentFlags);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update(cache, { data: dataCreate }) {
        if (data?.oneStationDetails) {
          cache.modify({
            id: cache.identify(
              data.oneStationDetails as unknown as StoreObject
            ),
            fields: {
              allLocales(existingData: Array<Reference>, { readField }) {
                if (data) {
                  if (existingData && dataCreate) {
                    return existingData.filter(
                      (taskRef) =>
                        dataCreate.deleteStationLocale.id !==
                        readField("id", taskRef)
                    );
                  }
                }
              },
            },
          });
        }
      },
    }
  );

  const handleDeleteStationLocale = () => {
    const localeId = data?.oneStationDetails?.allLocales?.find(
      (x) => x.languageFlag.isoLanguageCode === activeLocale
    )?.id;
    if (localeId) {
      deleteStationLocaleMutation({
        variables: {
          id: +localeId,
        },
      });
    } else {
      const newCurrentFlags = currentLanguageFlags.filter(
        (x) => x.isoLanguageCode !== activeLocale
      );
      resetFields([
        "audioGuide",
        "audioGuideText",
        "description",
        "gallery",
        "title",
      ]);
      setCurrentLanguageFlags(newCurrentFlags);
      setActiveLocale(MAIN_ISO_LANGUAGE_CODE);
      enqueueSnackbar("Lokalizacija je uklonjena!", { variant: "success" });
    }
  };

  const handleUpdateSharing = () => {
    if (
      !validateForm(["textCode"], Boolean(data?.oneStationDetails.published))
    ) {
      return;
    }

    if (isEdit && stationId) {
      updateStationSharingMutation({
        variables: {
          id: +stationId,
          data: {
            textCode: handleDataToVar("textCode", "string"),
          },
        },
      });
    }
  };

  const handleUpdateStationPublished = () => {
    if (data && isEdit && stationId) {
      if (
        didValuesChange(inputFieldNames, true) &&
        !data.oneStationDetails.published
      ) {
        enqueueSnackbar(MSG_UNSAVED_CHANGES, { variant: "warning" });
        return;
      }
      if (
        validateForm(inputFieldNames, !data.oneStationDetails.published, true)
      ) {
        updateStationPublishedMutation({
          variables: {
            id: +stationId,
            publish: data?.oneStationDetails.published ? false : true,
            // checkOnly: !data?.oneStationDetails.published && !checkPassedPublishTest,
            checkOnly: false,
          },
        });
      }
    } else {
      enqueueSnackbar("ID nije pronađen!", {
        variant: "error",
      });
    }
  };

  // const generateQR = () => {
  //   const downloadLink = document.createElement("a");
  //   const qrCode = document.getElementById(
  //     "react-qrcode-logo"
  //   ) as HTMLCanvasElement;
  //   downloadLink.href = qrCode.toDataURL();
  //   downloadLink.download = `qrCode-${stationId!}.png`;
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  //   document.body.removeChild(downloadLink);
  // };

  // const handleCopyToClipboard = () => {
  //   navigator.clipboard.writeText(QrCodeNfcTagUrl);
  //   enqueueSnackbar("Copied to clipboard!", { variant: "info" });
  // };

  const handleNavigateBack = () => {
    if (!didValuesChange()) {
      navigate(`/${ERouterPaths.CLASSIC_TOURS}/${classicTourId}?activeTab=3`);
    } else {
      enqueueSnackbar({ message: MSG_UNSAVED_CHANGES, variant: "warning" });
    }
  };

  return (
    <PageLayout displayFlex>
      <TabContext value={activeTab}>
        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
          <Tab label="Opšte" value="1" />
          {/* <Tab label="Other" value="2" /> */}
          <Tab label="Deljenje" value="2" />
        </TabList>
        <Paper className={globalClasses.paperRoot}>
          <div
            className={cx(
              globalClasses.paperTitle,
              globalClasses.justifySpaceBetween
            )}
          >
            <div
              className={cx(
                globalClasses.justifySpaceBetween,
                globalClasses.centerItems
              )}
            >
              <IconButton onClick={handleNavigateBack} color="primary">
                <ArrowBackOutlinedIcon color="primary" />
              </IconButton>
              <Typography variant="h6">
                {isEdit ? "Izmeni stanicu" : "Dodaj novu stanicu"}
              </Typography>
            </div>
            {isEdit && data?.oneStationDetails ? (
              <ReadyToPublish
                published={data.oneStationDetails.published}
                checkPassed
                handlePublish={handleUpdateStationPublished}
                publishedThingText="Stanica"
              />
            ) : null}
          </div>
          <div className={globalClasses.paperContainer}>
            <DataHandlerComponent
              hasData={Boolean(!isEdit || (isEdit && data?.oneStationDetails))}
              error={Boolean(error)}
              loading={loading}
            >
              <TabPanel value="1" className={classes.tabPanel}>
                <FlagList
                  canSelectFlags={isEdit}
                  deleteLocaleFlagMutation={handleDeleteStationLocale}
                  activeLocale={activeLocale}
                  setActiveLocale={setActiveLocale}
                  currentLanguageFlags={currentLanguageFlags}
                  setCurrentLanguageFlags={setCurrentLanguageFlags}
                  type="Stanica"
                  tooltip='Novi prevodi biće vidljivi korisnicima samo ako ste ih već dodali u odeljku "O nama".'
                />
                {!isEdit ? (
                  <Typography color="textSecondary">
                    Dodajte prve detalje o interaktivnoj turi koju želite da
                    kreirate..
                  </Typography>
                ) : null}

                <TextField
                  margin="normal"
                  {...inputFields.title.inputProps}
                  autoFocus
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_STATION_TITLE_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.title.inputProps.value.length}/${INPUT_STATION_TITLE_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  margin="normal"
                  {...inputFields.description.inputProps}
                  multiline
                  minRows={6}
                  maxRows={10}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_STATION_DESCRIPTION_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.description.inputProps.value.length}/${INPUT_STATION_DESCRIPTION_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <MediaSelectButton
                  setMediaSelectDialogOpen={setMediaSelectDialogOpen}
                  max={8}
                  pickType="image"
                  {...inputFields.gallery.inputProps}
                />
                <MediaSelectButton
                  setMediaSelectDialogOpen={setMediaSelectDialogOpen}
                  max={1}
                  pickType="audio"
                  {...inputFields.audioGuide.inputProps}
                />
                <TextField
                  margin="normal"
                  {...inputFields.audioGuideText.inputProps}
                  multiline
                  minRows={6}
                  maxRows={12}
                  fullWidth
                  inputProps={{
                    maxLength: INPUT_STATION_AUDIO_GUIDE_TEXT_MAX_LENGTH,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">{`${inputFields.audioGuideText.inputProps.value.length}/${INPUT_STATION_AUDIO_GUIDE_TEXT_MAX_LENGTH}`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </TabPanel>
              {/* <TabPanel className={classes.tabPanel} value="2">
                {!isEdit ? (
                  <Typography color="textSecondary">
                    Add additional content to your Station.
                  </Typography>
                ) : null}

              </TabPanel> */}
              <TabPanel className={classes.tabPanel} value="2">
                {!isEdit ? (
                  <Typography color="textSecondary">
                    QR kod i NFC informacije će biti dostupne nakon što kreirate
                    stanicu.
                  </Typography>
                ) : null}
                <Typography variant="subtitle1">Numerički kod</Typography>
                <div className={globalClasses.justifySpaceBetween}>
                  <Typography color="textSecondary" className={classes.text}>
                    Povežite postojeće QR kodove sa ovom stanicom. Unesite sId
                    sa QR koda ovde. sId se nalazi na kraju QR koda.
                  </Typography>
                  <TextField
                    margin="normal"
                    {...inputFields.textCode.inputProps}
                    inputProps={{
                      maxLength: INPUT_STATION_CODE_MAX_LENGTH,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="caption">{`${inputFields.textCode.inputProps.value.length}/${INPUT_STATION_CODE_MAX_LENGTH}`}</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {/* {isEdit && stationId ? (
                  <> */}
                {/* <Divider className={classes.divider} /> */}
                {/* <div className={globalClasses.justifySpaceBetween}>
                      <Typography variant="subtitle1">QR code</Typography>
                      <Button variant="outlined" onClick={generateQR}>
                        Download QR code
                      </Button>
                    </div>
                    <QRCode
                      value={QrCodeNfcTagUrl}
                      // logoImage={require("../../../assets/logo192.png")}
                      // logoPadding={4}
                    /> */}

                {/* <div className={globalClasses.justifySpaceBetween}>
                      <Typography
                        flex={1}
                        marginRight={2}
                        color="textSecondary"
                      >
                        If you need to generate more QR codes, use this link.
                        replace [cId] with unique tour ID and [sId] with unique
                        station ID.
                      </Typography>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="QR code"
                        className={classes.inputWidth}
                        value={QrCodeNfcTagUrl}
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleCopyToClipboard}
                                edge="end"
                              >
                                <CopyAllIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <Divider className={classes.divider} />
                    <Typography variant="subtitle1">NFC</Typography>
                    <div className={globalClasses.justifySpaceBetween}>
                      <Typography
                        flex={1}
                        marginRight={2}
                        color="textSecondary"
                      >
                        Put this in your NFC tag and users can access your
                        station by tapping their phone on it. Required NFC
                        Technology: Ndef - URL Record. Use an app like "NFC
                        Tools" to setup tags. Don't forget to set a password on
                        NFC tag to lock it!
                      </Typography>
                      <TextField
                        margin="normal"
                        className={classes.inputWidth}
                        label="NFC code"
                        value={QrCodeNfcTagUrl}
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleCopyToClipboard}
                                edge="end"
                              >
                                <CopyAllIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div> */}
                {/* </>
                ) : null} */}
              </TabPanel>
            </DataHandlerComponent>
          </div>
          <div className={globalClasses.paperButtons}>
            <div className={globalClasses.paperButtonsLeft}>
              {activeTab !== "1" ? (
                <Button
                  onClick={handlePrevTab}
                  variant="outlined"
                  color="inherit"
                  startIcon={<ChevronLeftOutlinedIcon />}
                >
                  Prethodno
                </Button>
              ) : null}
            </div>
            <div className={globalClasses.paperButtonsRight}>
              {activeTab !== TOTAL_TABS ? (
                <Button
                  onClick={handleNextTab}
                  variant="outlined"
                  endIcon={<ChevronRightOutlinedIcon />}
                >
                  Sledeće
                </Button>
              ) : activeTab === TOTAL_TABS && !isEdit ? (
                <Button onClick={handleCreateStation} variant="contained">
                  Završite kreiranje stanice
                </Button>
              ) : null}

              {activeTab === "1" && isEdit ? (
                <Button
                  onClick={handleUpdateLocale}
                  variant="contained"
                  disabled={
                    !didValuesChange([
                      "title",
                      "description",
                      "gallery",
                      "audioGuide",
                      "audioGuideText",
                    ]) &&
                    !!data?.oneStationDetails.allLocales?.find(
                      (x) => x.languageFlag?.isoLanguageCode === activeLocale
                    )?.id
                  }
                >
                  {!data?.oneStationDetails.allLocales?.find(
                    (x) => x.languageFlag?.isoLanguageCode === activeLocale
                  )?.id
                    ? "kreiraj "
                    : "ažuriraj "}
                  | {activeLocale} |
                </Button>
              ) : null}
              {/* {activeTab === "2" && isEdit ? (
                <Button onClick={handleUpdateOther} variant="contained">
                  Update Other
                </Button>
              ) : null} */}
              {activeTab === "2" && isEdit ? (
                <Button
                  onClick={handleUpdateSharing}
                  variant="contained"
                  disabled={!didValuesChange(["textCode"])}
                >
                  Ažuriraj deljenje
                </Button>
              ) : null}
            </div>
          </div>
        </Paper>
      </TabContext>
      <LoadingBackdrop
        loading={
          loadingCreateStationMutation ||
          loadingUpdateStationLocaleMutation ||
          loadingCreateStationLocaleMutation ||
          // loadingUpdateStationOtherMutation ||
          loadingUpdateStationPublishedMutation ||
          loadingUpdateStationSharingMutation ||
          loadingDeleteStationLocaleMutation
        }
      />
      <MediaSelectorDialog
        dialogContext={mediaSelectDialogOpen}
        onClose={handleCloseMediaSelectDialog}
      />
    </PageLayout>
  );
};
